import {
  ActionMenu,
  Button,
  Card,
  Icon,
  Stack,
  Title,
  useActions
} from "@introist/react-foundation/v2";
import { compact } from "lodash";
import { Trigger } from "../hooks/useTriggers";
import { Skeletons } from "../../../components/molecules";
import { useState } from "react";
import { TriggerHeading } from "./TriggerHeading";
import { TriggerSettingsModal } from "./TriggerSettingsModal";
import { useTriggerActions } from "../hooks/useTrigger";
import { useNavigate } from "react-router";

export const TriggerHeader = ({ trigger }: { trigger?: Trigger }) => {
  const navigate = useNavigate();
  const { onConfirmAction } = useActions();

  const { update, remove: removeOp } = useTriggerActions();

  const [editSettings, setEditSettings] = useState(false);

  const remove = onConfirmAction(
    async () => {
      await removeOp(trigger!.id);
      navigate("/triggers");
    },
    {
      title: "Delete trigger",
      description: "Are you sure you want to delete this trigger?",
      confirmTitle: "Delete"
    }
  );

  if (!trigger) return <Skeletons.Block height={100} />;

  return (
    <Card style={{ padding: "var(--spacing-xLarge)" }}>
      <Stack vertical gap="xLarge">
        <Stack>
          <TriggerHeading trigger={trigger} />
          <Stack style={{ marginLeft: "auto" }}>
            {trigger.active && (
              <Stack>
                <Icon name="cycle" />
                <Title>This trigger is active.</Title>
              </Stack>
            )}
            {!trigger.active && (
              <Stack>
                <Title>This trigger is inactive.</Title>
                <Button
                  startIcon="cycle"
                  onClickWithLoading={() => update(trigger.id, { active: true })}
                >
                  Activate
                </Button>
              </Stack>
            )}
            <ActionMenu
              options={compact([
                trigger &&
                  trigger.active && {
                    key: "inactivate",
                    title: "Inactivate",
                    startAdornmentIcon: "pause",
                    onClick: () => update(trigger.id, { active: false })
                  },
                {
                  key: "delete",
                  title: "Delete",
                  startAdornmentIcon: "trash",
                  onClick: () => remove()
                }
              ])}
            />
          </Stack>
        </Stack>
      </Stack>
      <TriggerSettingsModal
        trigger={trigger}
        open={editSettings}
        onClose={() => setEditSettings(false)}
      />
    </Card>
  );
};
