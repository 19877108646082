import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Title } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";

export const WorkflowTriggerStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <Block blended compact>
      <StepEditorAccordion title="Workflow details">
        <StepEditorAccordion.Row label="Workflow">
          <Title variant="bold">{resolvedData.resolvedWorkflowTitle ?? "Unknown workflow"}</Title>
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Employee">
          <Title variant="bold">{resolvedData.resolvedEmployee.name}</Title>
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
