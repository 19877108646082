import { Skeletons } from "../../../../components/molecules";
import { Empty, Loading } from "../../../../foundation";
import { Table, TableCard } from "@introist/react-foundation/v2";
import { DatasetEntry } from "../../hooks/useDataset";
import { useDatasetFields } from "../../hooks/useDatasetFields";
import { useDatasetDef } from "../../hooks/useDatasetDefs";

export const DatasetTable = ({
  datasetId,
  entries,
  onClick
}: {
  datasetId: string;
  entries: DatasetEntry[];
  onClick?: (entryId: string) => unknown;
}) => {
  const { def } = useDatasetDef({ id: datasetId });
  const { fields } = useDatasetFields(datasetId);

  return (
    <Loading
      data={entries && fields && def}
      loading={<Skeletons.List />}
      empty={<Empty title="No entries" icon="list" />}
    >
      <TableCard>
        <Table
          onClick={e => onClick && onClick(e.id)}
          rowHeightRem={4}
          columns={(fields?.slice(0, 3) ?? []).map((f, i) => ({
            key: f.key,
            title: f.title,
            sortable: true,
            bold: i === 0
          }))}
          data={entries.map(e => ({ id: e.id, ...e.data }))}
        />
      </TableCard>
    </Loading>
  );
};
