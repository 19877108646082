import { Stack } from "@introist/react-foundation/v2";
import { ReactNode, useState } from "react";
import { RuleEditorModal } from "../../../components/organisms/RuleEditorModal/RuleEditorModal";
import { OrderableList } from "../../blocks";
import { ShortUuid } from "../../../utils/Utils";
import { ConditionEditor } from "./ConditionEditor";
import { Conditional, ConditionalGroupJson } from "@introist/introist-commons";
import { SetupCard } from "../../../modules/triggers/event-triggers/editors/SetupCard";

export type ConditionalGroupValueEditorProps = {
  value: Conditional;
  onChange: (value: Conditional) => unknown;
};

export type ConditionalGroupEditorProps = {
  value: ConditionalGroupJson;
  onChange: (value: ConditionalGroupJson) => unknown;
  onRemove: () => unknown;
  renderValueEditor: (
    condition: Conditional,
    onChange: (newValue: Conditional) => unknown
  ) => ReactNode;
  addLabel?: string;
  defaultOpen?: boolean;
};

export const ConditionalGroupEditor = ({
  value,
  onChange,
  renderValueEditor,
  addLabel,
  defaultOpen
}: ConditionalGroupEditorProps) => {
  const [newCondition, setNewCondition] = useState<Conditional | undefined>(undefined);

  return (
    <Stack vertical>
      <OrderableList
        data={value.items}
        onReorder={items => onChange({ ...value, items })}
        renderRow={c => (
          <ConditionEditor
            value={c}
            onChange={val => {
              const items = value.items.map((v: any) => (v.id === c.id ? val : v));
              return onChange({ ...value, items });
            }}
            renderValueEditor={renderValueEditor}
            onRemove={() => {
              const items = value.items.filter((v: any) => v.id !== c.id);
              return onChange({ ...value, items });
            }}
            defaultOpen={defaultOpen}
          />
        )}
      />

      <SetupCard
        icon="plusSmall"
        title={addLabel ?? "Add conditional value"}
        onClick={() => {
          setNewCondition({
            id: ShortUuid.generate(),
            title: `Option ${value.items.length + 1}`,
            condition: { and: [] }
          });
        }}
      />
      <ConditionEditor
        value={{
          id: "default",
          title: "Default",
          condition: { and: [] },
          value: value.defaultValue
        }}
        onChange={c => onChange({ ...value, defaultValue: c.value })}
        onRemove={() => onChange({ ...value, defaultValue: null })}
        renderValueEditor={renderValueEditor}
        defaultOpen
      />

      {newCondition && (
        <RuleEditorModal
          title={newCondition.title}
          open={!!newCondition}
          setOpen={open => {
            if (!open) setNewCondition(undefined);
          }}
          expression={newCondition.condition}
          onSave={(condition, title) => {
            const items = [...value.items, { ...newCondition, title, condition }];
            return onChange({ ...value, items });
          }}
        />
      )}
    </Stack>
  );
};
