import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback, useMemo } from "react";
import { useToast } from "@introist/react-foundation/v2";
import { FieldTypeIcon } from "../../employees/components/FieldTypeIcon";
import { useEmployeeFields } from "../../employees/hooks/useEmployeeFields";

export type WorkflowField = RouterOutput["workflows"]["v4"]["fields"]["find"];

export const useWorkflowFields = (workflowId: string, options?: any) => {
  const { fieldTypes, tagMode, ...otherOptions } = options ?? {};

  const { data: fields } = api.workflows.v4.fields.list.useQuery({ workflowId }, otherOptions);

  const filteredFields = useMemo(() => {
    if (!fieldTypes || !fields) return fields;
    return fields.filter(f => fieldTypes.includes(f.type));
  }, [fields, fieldTypes]);

  const finalFields = filteredFields
    ? filteredFields.map(f => {
        const initialTag = `automation.${f.key}`;

        return { ...f, tag: tagMode ? `{{${initialTag}}}` : initialTag };
      })
    : filteredFields;

  return {
    fields: finalFields,
    options: (finalFields ?? []).map(f => ({
      key: f.tag,
      title: f.title,
      startAdornment: <FieldTypeIcon type={f.type} />
    }))
  };
};

export const useWorkflowTags = (workflowId: string) => {
  const { options } = useWorkflowFields(workflowId);
  const { fieldOptions } = useEmployeeFields();

  return {
    options: [
      ...options.map(o => ({ ...o, category: "Workflow" })),
      ...fieldOptions.map(o => ({ ...o, category: "Employee" }))
    ]
  };
};

export type WorkflowFieldCreate = RouterInput["workflows"]["v4"]["fields"]["create"];
export type WorkflowFieldUpdate = RouterInput["workflows"]["v4"]["fields"]["update"]["update"];

export const useWorkflowFieldActions = (workflowId: string) => {
  const toast = useToast();
  const createOp = api.workflows.v4.fields.create.useMutation();
  const updateOp = api.workflows.v4.fields.update.useMutation();
  const deleteOp = api.workflows.v4.fields.delete.useMutation();
  const orderOp = api.workflows.v4.fields.order.useMutation();

  const apiUtils = api.useContext();

  const listOp = apiUtils.workflows.v4.fields.list;

  const create = useCallback(
    async (req: WorkflowFieldCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async () => {
          await listOp.refetch({ workflowId });
          toast.success("Workflow field created");
        })
        .catch(e => {
          toast.error("Create failed");
          throw e;
        });
    },
    [workflowId, listOp, toast, createOp]
  );

  const update = useCallback(
    async (id: string, update: WorkflowFieldUpdate) => {
      return updateOp
        .mutateAsync({ id, update })
        .then(async () => {
          await listOp.refetch({ workflowId });
          toast.success("Workflow field updated");
        })
        .catch(e => {
          toast.error("Update failed");
          throw e;
        });
    },
    [workflowId, updateOp, listOp, toast]
  );

  const order = useCallback(
    async (ids: string[]) => {
      return orderOp
        .mutateAsync({ workflowId, ordering: ids })
        .then(async () => {
          await listOp.refetch({ workflowId });
        })
        .catch(e => {
          toast.error("Failed to save order");
          throw e;
        });
    },
    [orderOp, listOp, toast, workflowId]
  );

  const remove = useCallback(
    async (id: string) => {
      return deleteOp.mutateAsync({ id }).then(async () => {
        await listOp.refetch({ workflowId });
        toast.success("Workflow field deleted");
      });
    },
    [deleteOp, listOp, toast, workflowId]
  );

  return { create, update, remove, order };
};
