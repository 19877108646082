import { useTriggerActions } from "../hooks/useTrigger";
import { useState } from "react";
import { Trigger } from "../hooks/useTriggers";
import { H3, Icon, IconButton, Stack } from "@introist/react-foundation/v2";
import { IconPickerIcon } from "../../../components/organisms";
import { EditableHeading } from "../../../components/molecules";

export const TriggerHeading = ({ trigger }: { trigger: Trigger }) => {
  const { update } = useTriggerActions();

  const [showNameEdit, setShowNameEdit] = useState(false);
  const [editName, setEditName] = useState(false);

  return (
    <Stack onMouseEnter={() => setShowNameEdit(true)} onMouseLeave={() => setShowNameEdit(false)}>
      <IconPickerIcon icon="trigger" fallbackIcon="trigger" />
      <EditableHeading
        initialValue={trigger.name}
        editable={false}
        dimmed
        onSave={async name => {
          await update(trigger.id, { name });
          setEditName(false);
        }}
        onCancel={() => setEditName(false)}
        forceEditing={editName}
        inputStyles={{ width: "400px" }}
      >
        <H3>{trigger.name}</H3>
      </EditableHeading>
      {!editName && (
        <IconButton
          size="small"
          style={{ display: showNameEdit ? "block" : "none" }}
          icon={<Icon name="pencil" dimmed />}
          onClick={() => setEditName(true)}
        />
      )}
    </Stack>
  );
};
