import { StepEditorProps } from "../useStepEditor";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Button, IconButton, Select, Stack } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { useEmployeeGroups } from "../../../employees/hooks/useEmployeeGroups";
import { prefixForm } from "../../../../utils/FormUtils";
import { SingleTagSelect } from "../../../tags/SingleTagSelect";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { useWorkflowFieldsTagList } from "../../fields/WorkflowFieldTagList";
import { RuleViewer } from "../../../../components/organisms/RecursiveExpressionEditor/components/RuleViewer/RuleViewer";
import { ExpressionFieldType } from "../../../../components/organisms";
import { RuleEditorModal } from "../../../../components/organisms/RuleEditorModal/RuleEditorModal";
import { expressionToConditionGroup } from "../../../../components/organisms/RecursiveExpressionEditor";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { TimePeriodSelector } from "../../../../foundation/blocks/TimePeriodSelector";

export const EmployeeAssignStepEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const { groups } = useEmployeeGroups();

  const { fields } = useEmployeeFields();

  const workflowFieldTagList = useWorkflowFieldsTagList(workflowId);

  const prefixedForm = prefixForm(form, stepDataPath);
  const [ruleEditorOpen, setRuleEditorOpen] = useState(false);

  const sourceOptions = useMemo(() => {
    return (groups ?? []).map(group => ({ key: group.id, title: group.title }));
  }, [groups]);

  const conditionGroup = useMemo(() => {
    const condition = prefixedForm.get("condition");
    if (prefixedForm.get("condition") === "") return null;
    return expressionToConditionGroup(condition);
  }, [prefixedForm]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Assignment" defaultOpen={true}>
          <StepEditorAccordion.Row label="Source">
            <Select
              searchable
              element="button"
              variant="blended"
              placeholder="Select source employee group"
              options={sourceOptions}
              value={prefixedForm.get("sourceGroupId")}
              onSelect={opt => prefixedForm.set("sourceGroupId")(opt.key)}
              attachToRef={false}
              startAdornment={<SelectorIcon icon="persons" />}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Target">
            <SingleTagSelect
              onChange={prefixedForm.set("target")}
              value={prefixedForm.get("target")}
              fieldTypes={["employee"]}
              formsEnabled={false}
              companyDataEnabled={false}
              customCategories={[workflowFieldTagList]}
              employeeSelectMode
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Selection details" defaultOpen>
          <StepEditorAccordion.Row
            label="Cooldown"
            info="Set a time during which a selected employee can not be reselected."
          >
            <TimePeriodSelector
              onChange={prefixedForm.set("cooldown")}
              value={prefixedForm.get("cooldown")}
              placeholder="No cooldown"
              onClear={() => prefixedForm.set("cooldown")(null)}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="Rules">
            {conditionGroup && (
              <Stack
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "flex-start"
                }}
              >
                <RuleViewer
                  conditionGroup={conditionGroup}
                  fields={(fields ?? []).map(f => ({
                    variable: f.key,
                    name: f.title,
                    type: f.type as ExpressionFieldType
                  }))}
                  collapsedCount={3}
                />
                <Stack vertical>
                  <IconButton icon="pencil" onClick={() => setRuleEditorOpen(true)} />
                  <IconButton
                    icon="trash"
                    onClick={() => prefixedForm.set("condition")(undefined)}
                  />
                </Stack>
              </Stack>
            )}
            {!conditionGroup && (
              <Button
                size="small"
                variant="blended"
                onClick={() => {
                  setRuleEditorOpen(true);
                }}
                startIcon="plusSmall"
              >
                Add matching rules
              </Button>
            )}

            <RuleEditorModal
              expression={prefixedForm.get("condition")}
              open={ruleEditorOpen}
              setOpen={setRuleEditorOpen}
              onSave={prefixedForm.set("condition")}
            />
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
