import { Field, Input, Select, Stack, TextArea, useToast } from "@introist/react-foundation/v2";
import { TargetDateSelect } from "../components/TargetDateSelect";
import { api } from "../../../services/rpc/RpcProvider";
import { useCallback, useState } from "react";
import { TargetDateChangeMode, Workflow } from "../index";
import { HeaderDrawer } from "../../../components/organisms";
import { ContentCard } from "../../../foundation";
import { UnsavedChanges } from "../../../components/molecules";
import { listTimezones } from "../../../utils/dates";
import { TimezoneOrder } from "./TimezoneOrder";
import { WorkflowFieldsEditor } from "../fields/WorkflowFieldsEditor";

type Props = {
  value: Workflow;
  onChange: (change: Partial<Workflow>) => void;
};

export const WorkflowSettingsForm = ({ value, onChange }: Props) => {
  return (
    <Stack vertical>
      <ContentCard title="General">
        <Field title="Name">
          <Input
            value={value.title || ""}
            placeholder="Name"
            onChange={title => onChange({ title })}
          />
        </Field>
        <Field title="Description">
          <TextArea
            value={value.description || ""}
            placeholder="Description"
            onChange={description => onChange({ description })}
          />
        </Field>
        <Field title="Target date">
          <TargetDateSelect
            style={{ width: "100%" }}
            value={value.targetDateAttribute || ""}
            onSelect={opt => onChange({ targetDateAttribute: opt.key })}
            workflowId={value.id}
          />
        </Field>
        <Field title="When target date value changes">
          <Select
            attachToRef={false}
            style={{ width: "100%" }}
            size="small"
            options={[
              { key: "donothing", title: "Do nothing" },
              { key: "notify", title: "Add to notifications" },
              { key: "reschedule", title: "Reschedule automation" },
              { key: "cancel", title: "Cancel automation" }
            ]}
            value={value.targetDateChangeMode}
            onSelect={opt => onChange({ targetDateChangeMode: opt.key as TargetDateChangeMode })}
          />
        </Field>
        <Field title="Duplicate mode">
          <Select
            attachToRef={false}
            style={{ width: "100%" }}
            size="small"
            options={[
              {
                key: "always_allow",
                title: "Allow many at the same time"
              },
              { key: "deny_active", title: "One active at a time" }
            ]}
            value={value.duplicateMode}
            onSelect={opt => onChange({ duplicateMode: opt.key })}
          />
        </Field>
      </ContentCard>
      <ContentCard title="Timezone">
        <Field title="Workflow timezone">
          <Select
            size="small"
            style={{ width: "100%" }}
            searchable
            onClear={() => onChange({ workflowTimezone: null })}
            placeholder="No workflow timezone"
            options={listTimezones().map(tz => ({ key: tz }))}
            value={value.workflowTimezone ?? undefined}
            onSelect={opt => onChange({ workflowTimezone: opt.key })}
          />
        </Field>
        <Field title="Timezone resolve order">
          <TimezoneOrder
            value={value.timezoneOrder}
            onChange={timezoneOrder => onChange({ timezoneOrder })}
          />
        </Field>
      </ContentCard>
      <WorkflowFieldsEditor workflowId={value.id} />
    </Stack>
  );
};

type WorkflowSettingsProps = {
  workflowId?: string;
  open?: boolean;
  onClose: () => void;
};

export const WorkflowSettingsDrawer = ({ workflowId, open, onClose }: WorkflowSettingsProps) => {
  const toast = useToast();

  const {
    data: workflow,
    isLoading,
    refetch
  } = api.workflows.v4.find.useQuery(
    { id: workflowId! },
    {
      enabled: !!workflowId
    }
  );
  const update = api.workflows.v4.update.useMutation();

  const [changes, setChanges] = useState<Partial<Workflow>>({});

  const save = useCallback(async () => {
    await update
      .mutateAsync({ id: workflowId!, updates: changes })
      .then(async () => {
        await refetch();
        setChanges({});
        toast.success("Workflow settings saved");
      })
      .catch(() => {
        toast.error("Failed to save settings");
      });
  }, [changes, toast, update, workflowId, refetch]);

  return (
    <HeaderDrawer loading={isLoading} title="Workflow settings" open={open} onClose={onClose}>
      {workflow && (
        <WorkflowSettingsForm
          value={{ ...workflow, ...changes }}
          onChange={change => setChanges({ ...changes, ...change })}
        />
      )}
      <UnsavedChanges
        onSave={save}
        show={Object.keys(changes).length > 0}
        style={{ position: "sticky", bottom: "0", width: "250px", left: "calc(50% - 125px)" }}
      />
    </HeaderDrawer>
  );
};
