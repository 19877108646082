import { api, RouterOutput } from "../../../services/rpc/RpcProvider";
import { IconName } from "@introist/react-foundation/v2";

export type Tag = RouterOutput["system"]["tags"]["list"][0] & {
  icon: IconName;
};

export const tagTypeIcon = (type: string): IconName => {
  if (type === "text") return "description";
  if (type === "email") return "email";
  if (type === "number") return "description";
  if (type === "date") return "calendar";
  if (type === "resource:employee") return "person";
  if (type === "file") return "file";
  return "attribute";
};

export const Tags = {
  wrap: (tag: string) => `{{${tag}}}`,
  unwrap: (tagWithHandle: string) => tagWithHandle.replace(/{{|}}/g, ""),
  isTagHandle: (tag?: string) => !!tag && tag.startsWith("{{") && tag.endsWith("}}")
};

export const useTags = (path: string | string[], workflowId?: string, options?: any) => {
  const { data: tags, isLoading } = api.system.tags.list.useQuery({ path, workflowId }, options);

  return { tags: tags?.map(t => ({ ...t, icon: tagTypeIcon(t.type) })), loading: isLoading };
};

export const useTag = (path: string, workflowId?: string, options?: any) => {
  const { data: tag, isLoading } = api.system.tags.find.useQuery(
    { path: Tags.unwrap(path), workflowId },
    options
  );
  return { tag: tag ? { ...tag, icon: tagTypeIcon(tag.type) } : tag, loading: isLoading };
};
