import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { IconButton, Select, Stack, Toggle, useTheme } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";

import { useWorkflows } from "../../hooks/useWorkflows";
import { useMemo } from "react";
import { DataMappingEditor } from "../../../system/data-mapping/DataMappingEditor";
import { useWorkflowFields } from "../../hooks/useWorkflowFields";
import { FieldTypeIcon } from "../../../employees/components/FieldTypeIcon";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { SingleTagSelect } from "../../../tags/SingleTagSelect";
import { useWorkflowFieldsTagList } from "../../fields/WorkflowFieldTagList";

export const WorkflowTriggerStepEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, stepDataPath);

  const { workflows } = useWorkflows({});

  const { fields: employeeFields } = useEmployeeFields();
  const { fields: sourceWorkflowFields } = useWorkflowFields(workflowId);

  const { fields: targetWorkflowFields } = useWorkflowFields(prefixedForm.get("workflowId"), {
    enabled: !!prefixedForm.get("workflowId")
  });

  const workflowFieldsTagList = useWorkflowFieldsTagList(workflowId, ["employee"]);

  const workflow = useMemo(() => {
    return workflows?.find(_ => _.id === prefixedForm.get("workflowId"));
  }, [workflows, prefixedForm]);

  const sourceOptions = useMemo(() => {
    const employeeFieldOptions = (employeeFields ?? []).map(f => ({
      key: f.key,
      title: f.title,
      startAdornment: <FieldTypeIcon type={f.type} />,
      category: "Employee fields"
    }));
    const workflowFieldOptions = (sourceWorkflowFields ?? []).map(f => ({
      key: f.tag,
      title: f.title,
      startAdornment: <FieldTypeIcon type={f.type} />,
      category: "Workflow fields"
    }));

    return [...workflowFieldOptions, ...employeeFieldOptions];
  }, [employeeFields, sourceWorkflowFields]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Workflow details">
          <StepEditorAccordion.Row label="Workflow">
            <Stack justifyContent="flex-start">
              <Select
                attachToRef={false}
                element="button"
                variant="blended"
                searchable
                searchPlaceholder="Find workflow"
                placeholder="Select workflow to trigger"
                value={prefixedForm.get("workflowId")}
                options={workflows?.map(wf => ({ key: wf.id, title: wf.title })) || []}
                onSelect={option => prefixedForm.set("workflowId")(option.key)}
                error={prefixedForm.isError("workflowId")}
              />
              {workflow && (
                <IconButton
                  size="large"
                  icon="moveTo"
                  dimmed
                  style={{ marginLeft: "auto" }}
                  onClick={() => window.open(`/flows/edit/${workflow.id}`, "_blank")}
                />
              )}
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Employee">
            <SingleTagSelect
              onChange={prefixedForm.set("targetEmployee")}
              value={prefixedForm.get("targetEmployee")}
              fieldTypes={["employee"]}
              employeeSelectMode
              placeholder="Same as this workflow"
              onClear={() => prefixedForm.set("targetEmployee")(null)}
              customCategories={[workflowFieldsTagList]}
            />
          </StepEditorAccordion.Row>

          <StepEditorAccordion.Row label="Start as draft">
            <Toggle
              style={{ marginLeft: theme.spacing.medium }}
              checked={prefixedForm.get("startAsDraft")}
              onChange={prefixedForm.set("startAsDraft")}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Wait for completion">
            <Toggle
              style={{ marginLeft: theme.spacing.medium }}
              checked={prefixedForm.get("waitForCompletion")}
              onChange={prefixedForm.set("waitForCompletion")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Automation data">
          <DataMappingEditor
            blended
            placeholder="No data will be passed to the workflow"
            value={prefixedForm.get("automationDataMapping")}
            onChange={prefixedForm.set("automationDataMapping")}
            sourceFields={sourceOptions}
            targetFields={(targetWorkflowFields ?? []).map(f => ({
              key: f.key,
              title: f.title,
              startAdornment: <FieldTypeIcon type={f.type} />
            }))}
          />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
