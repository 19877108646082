import { TopBarRoute } from "../../../components/templates";
import { Icon, Stack } from "@introist/react-foundation/v2";
import { useNavigate, useParams } from "react-router";
import { useTrigger } from "../hooks/useTrigger";
import { TriggerHeader } from "./TriggerHeader";
import { EventEditor } from "./editors/EventEditor";
import { EventTriggerHistory } from "./History";
import { WorkflowEditor } from "./editors/WorkflowEditor";
import { FilterEditor } from "./editors/FilterEditor";

export const EventTriggerRoute = () => {
  const navigate = useNavigate();
  const { triggerId } = useParams();

  const { trigger } = useTrigger({ id: triggerId! }, { enabled: !!triggerId });

  return (
    <TopBarRoute
      medium
      paths={[
        { key: "triggers", title: "Triggers", onClick: () => navigate("/triggers") },
        { key: "trigger", title: trigger?.name || "" }
      ]}
    >
      <Stack vertical>
        <TriggerHeader trigger={trigger} />
        <Stack
          style={{
            margin: "30px auto",
            display: "grid",
            gridTemplateColumns: "1fr 30px 1fr 30px 1fr",
            justifyItems: "center"
          }}
        >
          <EventEditor trigger={trigger} />
          <Icon name="arrowRight" />
          <FilterEditor trigger={trigger} />
          <Icon name="arrowRight" />
          <WorkflowEditor trigger={trigger} />
        </Stack>
        <EventTriggerHistory trigger={trigger} />
      </Stack>
    </TopBarRoute>
  );
};
