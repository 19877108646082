import { useCallback, useEffect, useRef, useState } from "react";
import { Field, InputHandle } from "@introist/react-foundation/v2";

import { TextInput, TextInputProps } from "../../../components/atoms";
import { EmployeeFieldSelectorPopover, SelectorIcon } from "./EmployeeFieldSelector";
import { useWorkflowFieldsTagList } from "../../flows/fields/WorkflowFieldTagList";
import { compact } from "lodash";

type FieldAwareInputProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string) => void;
  addSpaceAfterField?: boolean;
  workflowId?: string;
};

export const FieldAwareInput = ({
  onChange,
  value,
  title,
  readOnly,
  placeholder,
  addSpaceAfterField = true,
  workflowId,
  ...rest
}: FieldAwareInputProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputHandle>(null);
  const [attributesOpen, setAttributesOpen] = useState(false);

  const workflowFieldTagList = useWorkflowFieldsTagList(workflowId ?? "", undefined, false, {
    enabled: !!workflowId
  });

  // This function will be triggered when the "Escape" key is pressed
  const escFunction = useRef<(event: KeyboardEvent) => void>(event => {
    if (event.key === "Escape") {
      setAttributesOpen(false);
    }
  });

  const addField = useCallback(
    (path: string) => {
      if (!inputRef.current) return;

      const definedVariable = `{{${path}}}`;
      const newValue = inputRef.current.input?.value.slice(0, -1); // trim the last character

      onChange(newValue || "");

      inputRef.current.insertTextToCursorPosition(definedVariable);
      if (addSpaceAfterField) inputRef.current.insertTextToCursorPosition(" ");
    },
    [inputRef, onChange, addSpaceAfterField]
  );

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (escFunction.current) escFunction.current(event);
    };

    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, []);

  return (
    <>
      <EmployeeFieldSelectorPopover
        setOpen={setAttributesOpen}
        open={attributesOpen}
        popoverRef={ref.current}
        onSelect={addField}
        customCategories={compact([
          workflowFieldTagList.options.length > 0 ? workflowFieldTagList : undefined
        ])}
      />

      <Field title={title}>
        <div ref={ref}>
          <TextInput
            {...rest}
            inputHandleRef={inputRef}
            placeholder={placeholder}
            value={value}
            endAdornment={
              <SelectorIcon icon="attribute" onClick={() => setAttributesOpen(!attributesOpen)} />
            }
            onChange={value => {
              onChange(value);
            }}
            readOnly={readOnly}
            readonly={readOnly}
          />
        </div>
      </Field>
    </>
  );
};
