import {
  Button,
  Icon,
  IconButton,
  Modal,
  Select,
  Stack,
  Title
} from "@introist/react-foundation/v2";
import { v4 as uuid } from "uuid";
import { useMemo } from "react";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { api } from "../../../../services/rpc/RpcProvider";

export type CreateMappingField = {
  id: string;
  introistField: string;
  integrationField: string;
};

export type CreateAccountMappingProps = {
  dataSourceId: string;
  value: CreateMappingField[];
  onChange: (value: CreateMappingField[]) => void;
};

export const CreateAccountMapping = ({
  dataSourceId,
  value,
  onChange
}: CreateAccountMappingProps) => {
  const { fields } = useEmployeeFields();
  const { data: dataSourceFields } = api.employees.dataSources.fields.list.useQuery({
    sourceId: dataSourceId
  });

  const employeeFieldOptions = useMemo(() => {
    return (fields || [])
      .filter(f => f.type !== "group")
      .map(field => ({
        key: field.key,
        title: field.title
      }));
  }, [fields]);

  const dataSourceFieldOptions = useMemo(() => {
    return (dataSourceFields || []).map(field => ({
      key: field.jsonPath,
      title: field.name
    }));
  }, [dataSourceFields]);

  return (
    <Stack vertical>
      <Stack
        style={{
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "1fr 20px 1fr 20px"
        }}
      >
        <Title>Introist field</Title>
        <Icon name="arrowRight" />
        <Title style={{ padding: "18px" }}>Integration field</Title>
        <div />
      </Stack>
      {value.map((field: CreateMappingField) => (
        <Stack
          key={field.id}
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 20px 1fr 20px"
          }}
        >
          <Title
            variant="bold"
            style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {(fields ?? []).find((f: any) => f.variable === field.introistField)?.title ??
              field.introistField}
          </Title>
          <Icon name="arrowRight" />
          <Select
            searchable
            attachToRef={false}
            element="button"
            variant="blended"
            placeholder="Select field"
            options={dataSourceFieldOptions}
            value={field.integrationField}
            onEnter={search => {
              const opt = dataSourceFieldOptions.find(opt => opt.title === search);
              const newFields = value.map((f: any) => {
                if (f.id === field.id) {
                  return { ...f, integrationField: opt?.key ?? search };
                }
                return f;
              });
              onChange(newFields);
            }}
            onSelect={opt => {
              const newFields = value.map((f: any) => {
                if (f.id === field.id) {
                  return { ...f, integrationField: opt.key };
                }
                return f;
              });
              onChange(newFields);
            }}
          />
          <IconButton
            icon="crossSmall"
            size="small"
            onClick={() => onChange(value.filter((f: any) => f.id !== field.id))}
          />
        </Stack>
      ))}
      <Select
        searchable
        attachToRef={false}
        element="button"
        variant="blended"
        placeholder="Add field"
        options={employeeFieldOptions}
        startAdornment={<Icon name="plus" />}
        onSelect={opt => {
          onChange([...value, { id: uuid(), introistField: opt.key, integrationField: "" }]);
        }}
      />
    </Stack>
  );
};

export const CreateAccountMappingModal = ({
  open,
  onClose,
  ...rest
}: { open: boolean; onClose: () => void } & CreateAccountMappingProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Map fields"
      maxWidth="700px"
      style={{ width: "700px" }}
    >
      {open && <CreateAccountMapping {...rest} />}
      <Stack justifyContent="flex-end">
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Modal>
  );
};
