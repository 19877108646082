import { H4, Stack, IconButton, Title, Card, useTheme } from "@introist/react-foundation/v2";

import { StepTypeCategories, useStepTypes } from "../stepTypes";
import { Info, useSearch } from "../../../foundation";
import { StepTypeIcon } from "./StepTypeIcon";
import { SearchInput } from "../../../components/molecules";
export type StepTypeSelectorProps = {
  onSelect: (stepType: string) => void;
  onClose: () => unknown;
  title?: string;
};

export const StepTypeSelector = ({
  onSelect,
  onClose,
  title = "Create new step"
}: StepTypeSelectorProps) => {
  const { theme } = useTheme();
  const stepTypes = useStepTypes();

  const { filtered, search, setSearch } = useSearch(stepTypes, { fields: ["title"] });

  return (
    <Stack vertical style={{ margin: theme.spacing.xLarge }}>
      <Stack justifyContent="space-between">
        <H4>{title}</H4>
        <IconButton icon="crossSmall" onClick={onClose} />
      </Stack>
      <SearchInput value={search} onChange={setSearch} />
      <Stack vertical gap="large">
        {StepTypeCategories.map(category => {
          const steps = (filtered ?? []).filter(
            stepType => stepType.category === category && !stepType.hidden
          );

          if (steps.length === 0) return null;

          return (
            <Stack vertical gap="medium" key={category}>
              <Title small>{category.toUpperCase()}</Title>
              <Stack vertical gap="small">
                {steps.map(stepType => (
                  <Card key={stepType.stepType} onClick={() => onSelect(stepType.stepType)}>
                    <Stack>
                      {stepType.icon ?? <StepTypeIcon stepType={stepType.stepType} />}
                      <Title variant="bold">{stepType.title}</Title>
                      <Info message={stepType.description} />
                    </Stack>
                  </Card>
                ))}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
