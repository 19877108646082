import { useWorkflows } from "../../flows/hooks/useWorkflows";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Field,
  Icon,
  LabelledCheckbox,
  Modal,
  Select,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { FormFooter } from "../../datasources/components/FormFooter";
import { createJourney } from "../../../services/api/journeys/JourneyApi";
import { useWorkflowFields } from "../../flows/hooks/useWorkflowFields";
import { WorkflowFieldValueInput } from "../../flows/fields/WorkflowFieldValueInput";
import { useEmployees } from "../../employees/hooks/useEmployees";
import { FittedTitle } from "../../../components/atoms";

type StartAutomationProps = {
  employeeId?: string;
  module?: string;
  onStart: (employeeId: string, journeyId: string) => Promise<void>;
  onClose: () => void;
};

export const StartAutomation = ({ module, employeeId, onStart, onClose }: StartAutomationProps) => {
  const toast = useToast();
  const { workflows } = useWorkflows({ module });
  const { data: employees } = api.employees.v4.list.useQuery({});

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(employeeId);
  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const [draft, setDraft] = useState(false);
  const [automationData, setAutomationData] = useState<{ key: string; value: string | null }[]>([]);

  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setAutomationData([]);
  }, [workflowId]);

  const workflowOptions = useMemo(() => {
    if (!workflows) return [];
    return workflows.map(workflow => ({
      key: workflow.id,
      title: workflow.title
    }));
  }, [workflows]);

  const employeeOptions = useMemo(() => {
    if (!employees) return [];
    return employees.map(employee => ({
      key: employee.id!,
      title: employee.name!
    }));
  }, [employees]);

  const start = useCallback(async () => {
    const req = {
      employeeId: selectedEmployeeId!,
      workflowId: workflowId!,
      draft,
      automationData: Object.fromEntries(automationData.map(({ key, value }) => [key, value]))
    };

    await createJourney(req)
      .then(async res => {
        await onStart(selectedEmployeeId!, res.id);
        toast.success("Automation started");
        onClose();
      })
      .catch((err: any) => {
        setError(err.errors);
        toast.error("Failed to start automation");
      });
  }, [workflowId, draft, onStart, onClose, selectedEmployeeId, toast, automationData]);

  return (
    <Stack vertical>
      <Field title="Employee">
        <Select
          searchable
          style={{ width: "100%" }}
          placeholder="Select employee"
          options={employeeOptions}
          value={selectedEmployeeId}
          readOnly={!!employeeId}
          disabled={!!employeeId}
          onSelect={opt => setSelectedEmployeeId(opt.key)}
        />
      </Field>
      <Field title="Workflow">
        <Select
          searchable
          style={{ width: "100%" }}
          placeholder="Select workflow"
          options={workflowOptions}
          value={workflowId}
          onSelect={opt => setWorkflowId(opt.key)}
        />
      </Field>
      <LabelledCheckbox
        label="Start as draft"
        checked={draft}
        onChange={setDraft}
        style={{ marginTop: "12px" }}
      />
      {workflowId && (
        <WorkflowFieldsEditor
          workflowId={workflowId}
          value={automationData}
          onChange={setAutomationData}
        />
      )}
      {error && <Title style={{ color: "var(--palette-danger-default)" }}>{error}</Title>}
      <FormFooter
        onCancel={onClose}
        onSubmit={start}
        submit="Start"
        submitDisabled={!selectedEmployeeId || !workflowId}
      />
    </Stack>
  );
};

export const StartAutomationModal = ({
  open,
  onClose,
  ...props
}: StartAutomationProps & { open: boolean; onClose: () => void }) => (
  <Modal
    open={open}
    onClose={onClose}
    title="Start automation"
    style={{ width: "500px" }}
    maxContentHeight="80vh"
  >
    {open && <StartAutomation {...props} onClose={onClose} />}
  </Modal>
);

const WorkflowFieldsEditor = ({
  workflowId,
  value,
  onChange
}: {
  workflowId: string;
  value: { key: string; value: string | null }[];
  onChange: (value: { key: string; value: string | null }[]) => void;
}) => {
  const { fields } = useWorkflowFields(workflowId);
  const { employees } = useEmployees({});

  if (!fields || fields.length === 0) return null;

  return (
    <Stack
      vertical
      style={{
        borderTop: "1px solid var(--palette-border-subdued)",
        paddingTop: "var(--spacing-large)"
      }}
    >
      {value.map(val => {
        const wfField = (fields ?? []).find(f => f.key === val.key);

        if (!wfField) return null;

        return (
          <Stack>
            <FittedTitle maxLength={20} variant="bold" style={{ width: "150px" }} showTooltip>
              {wfField.title ?? val.key}
            </FittedTitle>
            <WorkflowFieldValueInput
              field={wfField}
              value={val.value}
              onChange={newValue => {
                onChange(value.map(v => (v.key === val.key ? { ...val, value: newValue } : v)));
              }}
              employees={employees}
              onClear={() => onChange(value.filter(v => v.key !== val.key))}
            />
          </Stack>
        );
      })}

      <Select
        element="button"
        variant="blended"
        options={fields ?? []}
        placeholder="Add workflow field"
        startAdornment={<Icon name="plusSmall" />}
        onSelect={opt => onChange([...value, { key: opt.key, value: null }])}
      />
    </Stack>
  );
};
