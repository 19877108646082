import { CircularLoader, DateInput, Drawer, Stack } from "@introist/react-foundation/v2";
import { useState } from "react";
import { useCompanyDataActions, useCompanyDataEntry } from "./useCompanyData";
import { DrawerHeader } from "../../foundation/templates/Drawer/DrawerHeader";
import { ConditionalGroupEditor } from "../../foundation/templates/ConditionalGroupEditor";
import { InputWithSave } from "../../foundation/templates/InputWithSave";
import { EmployeeSelect } from "../employees/components/EmployeeSelect";

export const CompanyDataEditorDrawer = ({ id, onClose }: { id?: string; onClose: () => void }) => {
  return (
    <Drawer width="540px" open={!!id} onClose={onClose}>
      {id && <CompanyDataEditor id={id} onClose={onClose} />}
    </Drawer>
  );
};

export const CompanyDataEditor = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { entry } = useCompanyDataEntry(id);

  const { update } = useCompanyDataActions();

  if (!entry) return <CircularLoader fillParent />;

  return (
    <Stack vertical style={{ padding: "var(--spacing-xLarge)" }}>
      <DrawerHeader title={entry.name} icon={entry.icon} onClose={onClose} />

      <ConditionalGroupEditor
        value={entry.value_group ?? []}
        onChange={value_group => update(entry.id, { value_group })}
        onRemove={() => {}}
        renderValueEditor={(condition, onChange) => (
          <ValueEditor
            value={condition.value as string}
            onChange={value => onChange({ ...condition, value })}
            type={entry.type}
          />
        )}
        defaultOpen
      />
    </Stack>
  );
};

const ValueEditor = ({
  value,
  onChange,
  type
}: {
  value?: string | null;
  onChange: (value: string) => unknown;
  type?: string;
}) => {
  const [internalValue, setInternalValue] = useState(value ?? "");

  const renderInput = () => {
    if (type === "employee") {
      return (
        <EmployeeSelect
          element="button"
          variant="blended"
          style={{ width: "100%" }}
          value={internalValue ?? undefined}
          disabled={!onChange}
          onSelect={id => {
            setInternalValue(id);
            return onChange(id);
          }}
          placeholder="No employee selected"
        />
      );
    }

    if (type === "date") {
      return (
        <DateInput
          size="small"
          variant="blended"
          style={{ width: "100%" }}
          value={internalValue}
          displayFormat={"DD.MM.yyyy"}
          onChange={val => {
            setInternalValue(val ?? "");
            return onChange(val ?? "");
          }}
        />
      );
    }

    return (
      <InputWithSave
        size="small"
        variant="blended"
        style={{ width: "100%" }}
        placeholder="Enter value"
        value={internalValue}
        onChange={setInternalValue}
        onSave={() => onChange(internalValue)}
      />
    );
  };

  return <Stack style={{ padding: "var(--spacing-medium)" }}>{renderInput()}</Stack>;
};
