import {
  Card,
  Icon,
  IconButton,
  Stack,
  Title,
  Tooltip,
  useTheme
} from "@introist/react-foundation/v2";

import { CollapseList, ListRow } from "../../../components/organisms";
import { useTriggerHistory } from "../hooks/useTrigger";
import { formatDateTime } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";
import { FittedTitle, StatusButton } from "../../../components/atoms";
import { SearchInput } from "../../../components/molecules";
import { useSearch } from "../../../foundation";
import { useCallback, useState } from "react";
import { openTab } from "../../../utils/WindowUtils";
import { SetupCard } from "./editors/SetupCard";
import { Trigger } from "../hooks/useTriggers";

export const EventTriggerHistory = ({ trigger }: { trigger: Trigger }) => {
  const { theme } = useTheme();

  const [statuses, setStatuses] = useState(["started", "filtered", "failed"]);

  const { history } = useTriggerHistory({ id: trigger.id });

  const { filtered, search, setSearch } = useSearch(
    (history ?? []).filter(e => statuses.includes(e.status)),
    {
      fields: ["eventId", "employeeName", "timestamp", "message"]
    }
  );

  const toggleStatus = useCallback(
    (status: string) => {
      if (statuses.includes(status)) {
        setStatuses(statuses.filter(s => s !== status));
      } else {
        setStatuses([...statuses, status]);
      }
    },
    [statuses]
  );

  if (!history || history.length === 0) {
    if (!trigger.active) return null;

    return (
      <div style={{ margin: "auto" }}>
        <SetupCard title="No events yet. You will see history as soon as there is something to show." />
      </div>
    );
  }

  return (
    <CollapseList
      open={true}
      header={
        <Stack gap="small" style={{ width: "100%" }}>
          <Icon name="history" dimmed />
          <Title>History</Title>
          <Title small>last 30 days</Title>
          <Stack style={{ marginLeft: "auto" }}>
            <Stack gap="small">
              <StatusButton
                status="Started"
                count={(history ?? []).filter(s => s.status === "started").length}
                active={statuses.includes("started")}
                onClick={() => toggleStatus("started")}
                startAdornment={<Icon name="playCircle" color={theme.palette.success.default} />}
              />
              <StatusButton
                status="Failed"
                count={(history ?? []).filter(s => s.status === "failed").length}
                active={statuses.includes("failed")}
                startAdornment={<Icon name="crossCircle" color={theme.palette.danger.default} />}
                onClick={() => toggleStatus("failed")}
              />
              <StatusButton
                status="Filtered"
                count={(history ?? []).filter(s => s.status === "filtered").length}
                active={statuses.includes("filtered")}
                onClick={() => toggleStatus("filtered")}
                startAdornment={<Icon name="progress" color={theme.palette.border.default} />}
              />
            </Stack>
            <SearchInput value={search} onChange={setSearch} />
          </Stack>
        </Stack>
      }
    >
      {(filtered ?? []).map(e => {
        const resolveIcon = () => {
          if (e.status === "failed") return "crossCircle";
          if (e.status === "started") return "playCircle";
          if (e.status === "filtered") return "progress";
          return "questionMark";
        };

        return (
          <ListRow key={e.id} gridTemplateColumns="25px 120px auto auto">
            <Tooltip tooltip={e.eventId}>
              <Icon
                name={resolveIcon()}
                color={e.success ? theme.palette.success.default : theme.palette.danger.default}
              />
            </Tooltip>
            <Title>{formatDateTime(e.timestamp, DateFormats.datetime.long)}</Title>
            <div
              onClick={() => {
                openTab(`/employees/${e.employeeId}`);
              }}
            >
              <Title variant="bold" style={{ cursor: "pointer" }}>
                {e.employeeName}
              </Title>
            </div>
            <Stack style={{ marginLeft: "auto" }}>
              <FittedTitle showTooltip maxLength={200}>
                {e.message}
              </FittedTitle>
              {e.automationId && (
                <IconButton
                  dimmed
                  icon="moveTo"
                  onClick={() => openTab(`/automations/${e.automationId}`)}
                />
              )}
            </Stack>
          </ListRow>
        );
      })}
      {history && history.length === 0 && (
        <Stack style={{ padding: "var(--spacing-large)" }}>
          <Card variant="blended" style={{ flex: 1 }}>
            <Title style={{ textAlign: "center" }}>This trigger has not been used yet</Title>
          </Card>
        </Stack>
      )}
    </CollapseList>
  );
};
