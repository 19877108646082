import { Select, SelectProps, Stack } from "@introist/react-foundation/v2";
import { useResources } from "./useResources";
import { Tags, useTag } from "../tags/useTags";
import { TagSelectContext, TagSelector } from "../tags/TagSelector";
import { useMemo } from "react";

export const ResourceSelector = ({
  resourceType,
  onSelect,
  tagContext,
  value,
  ...props
}: Omit<SelectProps, "onSelect" | "options" | "value"> & {
  resourceType: string;
  value?: string;
  onSelect: (identifier: string) => unknown;
  tagContext?: TagSelectContext;
}) => {
  const { resources } = useResources(resourceType);

  const { tag } = useTag(value ?? "", tagContext?.workflowId, {
    enabled: !!tagContext && !!value && Tags.isTagHandle(value)
  });

  const isTagValue = useMemo(() => !!tag && Tags.isTagHandle(value), [value, tag]);

  return (
    <Stack gap="small">
      <Select
        element="button"
        variant="blended"
        size="small"
        options={(resources ?? []).map(r => ({
          key: `resource:${r.type}:${r.id}`,
          title: r.title,
          startAdornment: props.startAdornment
        }))}
        onSelect={opt => onSelect(opt.key)}
        searchable
        {...props}
        placeholder={
          isTagValue ? tag?.fullTitle ?? tag?.title ?? "Unknown field" : props.placeholder
        }
        value={isTagValue ? undefined : value}
        attachToRef={false}
      />
      {tagContext && <TagSelector onSelect={t => onSelect(Tags.wrap(t.tag))} ctx={tagContext} />}
    </Stack>
  );
};
