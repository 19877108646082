import { useNavigate, useParams } from "react-router";
import { useCompanyData, useCompanyDataActions } from "./useCompanyData";
import { ListView, UpsertModal, useSearch } from "../../foundation";
import { useState } from "react";
import { TopBarRoute } from "../../components/templates";
import { Stack, Title } from "@introist/react-foundation/v2";
import { IconPickerIcon, ListRow } from "../../components/organisms";
import { CompanyDataEditorDrawer } from "./CompanyDataEditorDrawer";
import { CompanyDataForm } from "./CompanyDataForm";

export const CompanyDataRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { entries } = useCompanyData();
  const { create } = useCompanyDataActions();

  const { filtered, search, setSearch } = useSearch(entries, { fields: ["name"] });

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <TopBarRoute paths={[{ key: "companydata", title: "Company Data" }]}>
      <Stack vertical gap="xLarge">
        <ListView.Header
          title="Company Data"
          count={filtered?.length}
          search={{ search, setSearch }}
          onCreate={() => setCreateOpen(true)}
        />
        <ListView.List
          data={filtered}
          renderRow={entry => (
            <ListRow
              gridTemplateColumns="30px 1fr"
              onClick={() => navigate(`/companydata/${entry.id}`)}
            >
              <IconPickerIcon icon={entry.icon} fallbackIcon={entry.icon ?? "attribute"} />
              <Title variant="bold">{entry.name}</Title>
            </ListRow>
          )}
        />
        <CompanyDataEditorDrawer onClose={() => navigate("/companydata")} id={id} />

        <UpsertModal
          open={createOpen}
          onClose={() => setCreateOpen(false)}
          onCreate={async entry => {
            await create(entry).then(created => navigate(`/companydata/${created!.id}`));
          }}
          onUpdate={() => {
            throw new Error("Not implemented");
          }}
          template={{
            type: "employee"
          }}
          Form={CompanyDataForm}
          entryName="company data"
        />
      </Stack>
    </TopBarRoute>
  );
};
