import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { DataMappingEditor } from "../../../system/data-mapping/DataMappingEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { useDatasetDefs } from "../../../datasets/hooks/useDatasetDefs";
import { Select, Toggle } from "@introist/react-foundation/v2";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { useMemo } from "react";
import { useDatasetFields } from "../../../datasets/hooks/useDatasetFields";
import { TagSelector } from "../../../system/tags/TagSelector";

export const DatasetEntryCreateEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { datasets } = useDatasetDefs();
  const { fields } = useDatasetFields(prefixedForm.get("datasetId"), {
    enabled: !!prefixedForm.get("datasetId")
  });

  const targetOptions = useMemo(() => {
    return (fields ?? []).map(f => ({
      key: f.key,
      title: f.title
    }));
  }, [fields]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Target">
          <StepEditorAccordion.Row label="Dataset">
            <Select
              element="button"
              variant="outlined"
              size="small"
              startAdornment={<SelectorIcon icon="files" />}
              options={(datasets ?? []).map(ds => ({
                key: ds.id,
                title: ds.title,
                startAdornment: <SelectorIcon icon="files" />
              }))}
              value={prefixedForm.get("datasetId")}
              placeholder="Select data set"
              attachToRef={false}
              searchable
              onSelect={opt => prefixedForm.set("datasetId")(opt.key)}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row
            label="Merge"
            info="If another entry with same values for all fields marked as identifier already exists, do not create a new entry but complete the step."
          >
            <Toggle
              checked={prefixedForm.get("mergeIfExists")}
              onChange={prefixedForm.set("mergeIfExists")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Data">
          <DataMappingEditor
            blended
            placeholder="Add data for the created entry"
            value={prefixedForm.get("dataMapping")}
            onChange={prefixedForm.set("dataMapping")}
            sourceSelector={(onSelect, value) => (
              <TagSelector
                variant="blended"
                title="Source field"
                onSelect={tag => onSelect(tag.tag)}
                value={value}
                ctx={{ workflowId }}
              />
            )}
            targetFields={targetOptions}
          />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
