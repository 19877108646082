import { DataForm } from "@introist/react-foundation/v2";

import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { AttendanceBlock } from "./components/AttendanceBlock";

type Props = {
  form: DataForm<JourneyStepV2>;
  formPrefix: string;
  readOnly?: boolean;
};

export const AttendToEventJourneyEditor = ({ form, formPrefix, readOnly }: Props) => {
  const executionData = form.get("executionData");

  return (
    <AttendanceBlock attendeeId={executionData.attendeeId} journeyId={form.get("journeyId")} />
  );
};
