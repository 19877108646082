import { DatasetDefsRoute } from "./routes/DatasetDefsRoute";
import { DatasetDefRoute } from "./routes/DatasetDefRoute";
import { RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { DatasetRoute } from "./routes/dataset/DatasetRoute";

export const DATASET_ROUTES = {
  datasetDefs: {
    key: "dataset-defs",
    path: "/datasets/def",
    title: "Datasets",
    element: <DatasetDefsRoute />
  },
  datasetDef: {
    key: "dataset-def",
    path: "/datasets/def/:id",
    title: "Dataset",
    element: <DatasetDefRoute />
  },
  dataset: {
    key: "dataset",
    path: "/datasets/:id",
    title: "Dataset",
    element: <DatasetRoute />
  },
  datasetEntry: {
    key: "dataset",
    path: "/datasets/:id/:entryId",
    title: "Dataset",
    element: <DatasetRoute />
  }
};

export type DatasetField = RouterOutput["datasets"]["fields"]["find"];
export type DatasetFieldCreate = RouterInput["datasets"]["fields"]["create"];
export type DatasetFieldUpdate = RouterInput["datasets"]["fields"]["update"];
