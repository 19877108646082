import styled from "styled-components";
import { IconButton, IconName, Stack } from "@introist/react-foundation/v2";
import { ComponentPropsWithoutRef } from "react";
import { SelectorIcon } from "../../modules/employees/fields/EmployeeFieldSelector";

const Container = styled(Stack)`
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 36px;
  box-sizing: border-box;
  padding: var(--spacing-xSmall) var(--spacing-medium);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);

    button {
      opacity: 1;
    }
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

export const ListItem = ({
  children,
  onRemove,
  icon,
  ...props
}: ComponentPropsWithoutRef<"div"> & { onRemove?: () => unknown; icon?: IconName }) => (
  <Container gap="small" {...props}>
    {icon && <SelectorIcon icon={icon} />}
    {children}
    {onRemove && (
      <RemoveButton icon="crossSmall" onClick={onRemove} style={{ marginLeft: "auto" }} />
    )}
  </Container>
);
