import { IconName } from "@introist/react-foundation/v2";
import { SelectorIcon } from "../fields/EmployeeFieldSelector";

export const FieldTypeIcon = ({ type }: { type: string }) => {
  const resolveIcon = (): IconName => {
    if (type === "email") return "email";
    if (type === "employee") return "person";
    if (type === "text") return "description";
    if (type === "date") return "calendar";
    return "attribute";
  };

  return <SelectorIcon icon={resolveIcon()} />;
};
