import { Layout, Field, Select } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
import { useMemo } from "react";

export const TeamTailorConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const creds = useMemo(() => {
    return (
      (values?.credentials as { apiKey: string; domain?: string }) ?? {
        apiKey: "",
        domain: "api.teamtailor.com"
      }
    );
  }, [values?.credentials]);

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <Field title="Api key">
        <TextInput
          placeholder="Api key"
          value={creds.apiKey}
          type="password"
          onChange={apiKey =>
            onChange({
              credentials: { ...creds, apiKey }
            })
          }
        />
      </Field>
      <Field title="Region">
        <Select
          placeholder="Select region"
          value={creds.domain}
          onSelect={selected => onChange({ credentials: { ...creds, domain: selected.key } })}
          options={[
            { title: "EU", key: "api.teamtailor.com" },
            { title: "US West", key: "api.na.teamtailor.com" }
          ]}
        />
      </Field>
    </Layout.Group>
  );
};
