import { ComponentProps, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Title, useForm, useToast } from "@introist/react-foundation/v2";

import { WorkflowEditorBlock } from "modules/workflows/blocks";
import { StepConfirmationModal } from "./components/StepConfirmationDialog";

import { useSaveWorkflowStep } from "./hooks/useSaveWorkflowStep";
import { api } from "services/rpc/RpcProvider";
import { AdvancedSettings } from "./AdvancedSettings";
import { TabBarBlock } from "modules/workflows/components/steps";
import { ScheduleBlock } from "./ScheduleBlock";
import { UnsavedChanges } from "../../../../../components/molecules";
import { Block, TextInput } from "../../../../../components/atoms";
import { useStepEditor } from "../../../../flows/step-editors/useStepEditor";
import { NakedButton } from "../../../../../foundation/blocks/NakedButton";
import { ValidationError } from "validate";
import { WorkflowStep } from "../../../../flows/hooks/useWorkflowSteps";
import { StepEditorMode } from "../../../../flows/containers/WorkflowStepEditor/WorkflowStepEditorDrawer";
import { useEmployeeFields } from "../../../../employees/hooks/useEmployeeFields";

type WorkflowStepEditorProps = Omit<ComponentProps<"div">, "ref"> & {
  workflowId: string;
  workflowStep: WorkflowStep;
  onEditStateChanged?: (editing: boolean) => unknown;
  onClose: (force?: boolean) => unknown;
  mode: StepEditorMode;
};

const StyledWorkflowStepEditor = styled.div`
  background: var(--palette-background-default);
`;

const StyledContent = styled.div`
  padding-bottom: var(--spacing-xxLarge);
`;

export const WorkflowStepEditor = ({
  workflowId,
  onEditStateChanged,
  onClose,
  className,
  workflowStep,
  mode,
  ...rest
}: WorkflowStepEditorProps) => {
  const workflowStepForm = useForm<WorkflowStep>(undefined, workflowStep);
  const { setValue: setWorkflowStepFormData } = workflowStepForm;
  const toast = useToast();

  const [activeTab, setActiveTab] = useState("General");

  const { data: workflow } = api.workflows.find.useQuery({ workflowId });

  const { fields } = useEmployeeFields();
  const [confirmExit, setConfirmExit] = useState(false);

  const saveWorkflowStep = useSaveWorkflowStep();

  const isCreate = useMemo(() => {
    return !workflowStep.id;
  }, [workflowStep]);

  const saveStep = (active?: boolean) => {
    return saveWorkflowStep(workflowId, {
      ...workflowStepForm.data,
      active: active ?? workflowStepForm.data.active
    })
      .then(async updated => {
        setWorkflowStepFormData(updated as unknown as WorkflowStep, false);
      })
      .catch(errors => {
        if (errors && Array.isArray(errors)) {
          workflowStepForm.setErrors(
            errors.map((e: any) => new ValidationError(e.message, e.path))
          );
        }

        toast.error("Failed to save step, check the fields");
      });
  };

  const targetDateTerm = useMemo(() => {
    if (!workflow?.targetDateAttribute) return "target date";
    if (workflow.targetDateAttribute === "immediately") return "enroll date";
    return (
      (fields ?? []).find(attr => attr.key === workflow.targetDateAttribute)?.title.toLowerCase() ??
      "target date"
    );
  }, [workflow, fields]);

  const editor = useStepEditor({
    form: workflowStepForm,
    stepDataPath: "stepData.",
    targetDateTerm,
    workflowId
  });

  useEffect(() => {
    setWorkflowStepFormData(workflowStep, false);
  }, [workflowStep, setWorkflowStepFormData]);

  useEffect(() => {
    onEditStateChanged && onEditStateChanged(workflowStepForm.edited);
  }, [workflowStepForm.edited, onEditStateChanged]);

  const hasFormData = Object.keys(workflowStepForm.data).length > 0;

  return (
    <StyledWorkflowStepEditor {...rest}>
      {hasFormData && (
        <WorkflowEditorBlock.Header
          step={workflowStepForm.data}
          hasChanges={workflowStepForm.edited}
          onClose={() => {
            if (workflowStepForm.edited) setConfirmExit(true);
            else onClose();
          }}
          active={!!workflowStepForm.data.id ? workflowStepForm.get("active") : undefined}
          mode={mode}
        />
      )}

      {mode === "workflow" && <TabBarBlock onChange={setActiveTab} />}

      {activeTab === "Advanced" && (
        <AdvancedSettings form={workflowStepForm} workflowId={workflowId} />
      )}

      {activeTab === "General" && (
        <StyledContent className={className}>
          <Block blended compact>
            <TextInput
              autoFocus={!workflowStepForm.data.id}
              label="Step title"
              variant="blended"
              value={workflowStepForm.get("stepTitle")}
              onChange={workflowStepForm.set("stepTitle")}
              error={workflowStepForm.isError("stepTitle")}
            />
          </Block>

          {mode === "workflow" && (
            <ScheduleBlock
              form={workflowStepForm}
              targetDateTerm={targetDateTerm}
              immediatelyOnly={workflow?.targetDateAttribute === "immediately"}
            />
          )}

          {editor}
        </StyledContent>
      )}
      <StepConfirmationModal
        show={confirmExit}
        onClose={() => setConfirmExit(false)}
        onCancel={() => {
          setConfirmExit(false);
          onClose(true);
        }}
        onConfirm={() => saveStep().then(() => onClose())}
      />
      <div style={{ height: "20px" }} />
      <UnsavedChanges
        show={workflowStepForm.edited}
        onSave={() => saveStep()}
        style={{
          position: "sticky",
          bottom: "1rem",
          width: isCreate ? "350px" : "250px",
          left: isCreate ? "calc(50% - 175px)" : "calc(50% - 125px)"
        }}
        secondary={
          mode === "workflow" && isCreate ? (
            <NakedButton onClickWithLoading={() => saveStep(false)}>
              <Title color="white">Save draft</Title>
            </NakedButton>
          ) : undefined
        }
      />
    </StyledWorkflowStepEditor>
  );
};
