import { UpsertFormProps } from "../../../foundation";
import { Button, Field, IconButton, Input, Select, Stack } from "@introist/react-foundation/v2";
import { KeyInput, simplifyKeyValue } from "../../datasets/components/KeyInput";
import { WorkflowField } from "../hooks/useWorkflowFields";
import { EmployeeSelect } from "../../employees/components/EmployeeSelect";

export const WorkflowFieldForm = ({ value, onChange, isEdit }: UpsertFormProps<WorkflowField>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input
          value={value.title}
          onChange={title => onChange({ ...value, title })}
          onBlur={() => {
            onChange({ ...value, key: simplifyKeyValue(value.title) });
          }}
          autoFocus
          placeholder="Field title"
        />
      </Field>
      <Field title="Key">
        <KeyInput
          size="small"
          value={value.key}
          onChange={key => onChange({ ...value, key })}
          disabled={isEdit}
          placeholder="Field key"
        />
      </Field>
      <Field title="Type">
        <Select
          style={{ width: "100%" }}
          size="small"
          value={value.type}
          onSelect={opt => onChange({ ...value, type: opt.key as WorkflowField["type"] })}
          options={[
            { key: "text", title: "Text" },
            { key: "email", title: "Email" },
            { key: "date", title: "Date" },
            { key: "employee", title: "Employee" }
          ]}
          disabled={isEdit}
        />
      </Field>
      <OptionField value={value} onChange={onChange} isEdit={isEdit} type={value.type} />
    </Stack>
  );
};

const OptionField = ({
  value,
  onChange,
  type
}: UpsertFormProps<WorkflowField> & { type: string }) => {
  const buildInput = (value: string, onChange: (value: string) => unknown) => {
    if (type === "employee") {
      return <EmployeeSelect size="small" onSelect={onChange} value={value} />;
    }

    return (
      <Input
        size="small"
        value={value}
        onChange={onChange}
        style={{ flex: 1 }}
        placeholder="Option value"
      />
    );
  };

  return (
    <Field title="Options">
      {(value.options ?? []).map((option, index) => (
        <Stack>
          {buildInput(option, newVal => {
            const newOptions = value.options!.map((o, i) => (index === i ? newVal : o));
            onChange({ ...value, options: newOptions });
          })}
          <IconButton
            icon="crossSmall"
            onClick={() => {
              if (value.options!.length === 1) onChange({ ...value, options: null });
              else onChange({ ...value, options: value.options!.filter((_, i) => i !== index) });
            }}
          />
        </Stack>
      ))}
      <Button
        variant="blended"
        startIcon="plus"
        onClick={() => onChange({ ...value, options: [...(value.options ?? []), ""] })}
      >
        Add option
      </Button>
    </Field>
  );
};
