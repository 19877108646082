import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Title } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";
import { useMemo } from "react";

export const CreateAccountStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  const resolvedFields = useMemo(() => {
    if (step?.resolvedData) return (step.resolvedData! as any).resolvedFields as object;
    return {};
  }, [step]);

  if (!step) return <div />;

  return (
    <Block blended compact>
      <StepEditorAccordion title="Values">
        {Object.keys(resolvedFields).length === 0 && <Title>No values found</Title>}
        {Object.entries(resolvedFields).map(([targetKey, value]) => (
          <StepEditorAccordion.Row label={targetKey}>
            <Title variant="bold">{value as any}</Title>
          </StepEditorAccordion.Row>
        ))}
      </StepEditorAccordion>
    </Block>
  );
};
