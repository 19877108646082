import { TopBarRoute } from "../../../components/templates";
import { ActionMenu, Field, Input, Stack, Title } from "@introist/react-foundation/v2";
import { IconPickerIcon, ListRow } from "../../../components/organisms";

import { DatasetDef } from "../../../services/rpc/RpcProvider";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useDatasetDefs } from "../hooks/useDatasetDefs";
import { pick } from "lodash";
import { KeyInput, simplifyKeyValue } from "../components/KeyInput";
import { useSearch, ListView, UpsertModal, UpsertFormProps } from "foundation";
import { ArchivedDatasetsModal } from "../containers/ArchivedDatasetsModal";

export const DatasetDefsRoute = () => {
  const navigate = useNavigate();

  const { datasets, create, update, archive } = useDatasetDefs();

  const { search, setSearch, filtered } = useSearch(datasets, {
    fields: ["title", "plural", "key"]
  });

  const [editOpen, setEditOpen] = useState(false);
  const [editEntry, setEditEntry] = useState<DatasetDef | undefined>();

  const [archivedOpen, setArchivedOpen] = useState(false);

  return (
    <TopBarRoute paths={[{ key: "datasets", title: "Datasets" }]}>
      <Stack vertical gap="xLarge">
        <ListView.Header
          title="Datasets"
          count={filtered?.length}
          onCreate={() => {
            setEditOpen(true);
            setEditEntry(undefined);
          }}
          actions={[
            { key: "archived", title: "Archived datasets", onClick: () => setArchivedOpen(true) }
          ]}
          search={{ search, setSearch }}
        />
        <ListView.List
          data={filtered}
          renderRow={ds => (
            <ListRow
              gridTemplateColumns="1fr auto"
              clickable
              onClick={() => navigate(`/datasets/def/${ds.id}`)}
            >
              <Stack>
                <IconPickerIcon icon="files" fallbackIcon="files" />
                <Title variant="bold">{ds.plural}</Title>
                <Title>{ds.title}</Title>
              </Stack>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ActionMenu
                  options={[
                    {
                      key: "edit",
                      title: "Edit",
                      startAdornmentIcon: "pencil",
                      onClick: () => {
                        setEditOpen(true);
                        setEditEntry(ds);
                      }
                    },
                    {
                      key: "archive",
                      title: "Archive",
                      startAdornmentIcon: "trash",
                      onClick: () => archive(ds.id)
                    }
                  ]}
                />
              </div>
            </ListRow>
          )}
        />
      </Stack>
      <UpsertModal
        open={editOpen}
        entry={editEntry}
        onCreate={async entry => {
          const created = await create(entry);
          navigate(`/datasets/${created.id}`);
        }}
        onUpdate={entry => {
          return update({ id: entry.id, updates: pick(entry, ["title", "plural"]) });
        }}
        onClose={() => {
          setEditOpen(false);
          setEditEntry(undefined);
        }}
        Form={DatasetForm}
        entryName="dataset"
      />
      <ArchivedDatasetsModal open={archivedOpen} onClose={() => setArchivedOpen(false)} />
    </TopBarRoute>
  );
};

const DatasetForm = ({ value, onChange, isEdit }: UpsertFormProps<DatasetDef>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title (singular)">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} />
      </Field>
      <Field title="Title (plural)">
        <Input
          value={value.plural}
          onChange={plural => onChange({ ...value, plural })}
          onBlur={() => {
            onChange({ ...value, key: simplifyKeyValue(value.plural) });
          }}
        />
      </Field>
      <Field title="Key">
        <KeyInput
          size="small"
          variant="blended"
          value={value.key}
          onChange={key => onChange({ ...value, key })}
          disabled={isEdit}
        />
      </Field>
    </Stack>
  );
};
