import { useMemo, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CircularLoader,
  H4,
  Icon,
  Input,
  Layout,
  Title,
  useToast
} from "@introist/react-foundation/v2";

import { RadioCard } from "components/molecules";
import { TextInput } from "components/atoms";
import { CalendarEvent, CalendarEventList, api } from "services/rpc/RpcProvider";
import { debounce, isEmpty } from "lodash";
import { HostCalendarIcon } from "components/atoms/HostCalendarIcon";
import useMeasure from "react-use-measure";

type AvailableEventListProps = {
  selectedEventId?: string;
  hasConnectedCalendars: boolean;
  events: CalendarEventList["events"];
  selectedCalendars?: CalendarEventList["calendars"];
  onSelectEvent: (event: CalendarEvent) => void;
  onReportButtonClick: VoidFunction;
  onCancelReport: VoidFunction;
  reportingOpen?: boolean;
};

const EventList = styled(Layout.Group)``;

const Content = styled(Layout.Group)`
  padding: var(--spacing-large) 0;
  flex: 1;
`;

const Host = styled(Layout.Group)`
  padding-right: var(--spacing-xxLarge);
`;

const StyledCard = styled(Card)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    max-width: 24rem;
    background-color: var(--palette-surface-subdued);
    border-color: transparent;
    flex: 1;
  }

  .buttons {
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-small);
  }
`;

const useSearchEvents = (events: CalendarEvent[]) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSetQuery = debounce(setQuery, 300);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedSetQuery(value);
  };

  const filteredEvents = useMemo(() => {
    if (!query) return events;

    return events.filter(e => {
      return e.title.toLowerCase().includes(query.toLowerCase());
    });
  }, [query, events]);

  return { inputValue, handleInputChange, filteredEvents };
};

export const AvailableEventList = ({
  events,
  selectedEventId,
  hasConnectedCalendars,
  selectedCalendars,
  reportingOpen,
  onReportButtonClick,
  onCancelReport,
  onSelectEvent
}: AvailableEventListProps) => {
  const toast = useToast();
  const { isLoading } = api.companyEvents.calendars.listEvents.useQuery({});
  const [measureRef, { height }] = useMeasure();

  // filter events by selected calendars
  const calendarFilteredEvents = useMemo(() => {
    if (!selectedCalendars?.length) return events;
    return events.filter(event => {
      return selectedCalendars.some(calendar => {
        return (
          calendar.name === event.calendar.name && calendar.provider === event.calendar.provider
        );
      });
    });
  }, [selectedCalendars, events]);

  const { inputValue, handleInputChange, filteredEvents } = useSearchEvents(calendarFilteredEvents);

  const showNoEventsFound =
    !isLoading && !filteredEvents?.length && inputValue.length > 0 && !reportingOpen;

  const showList = !isLoading && filteredEvents && !isEmpty(filteredEvents) && !reportingOpen;

  const [missingEventTitle, setMissingEventTitle] = useState("");
  const { mutateAsync: reportMissingEvent } = api.companyEvents.reportMissing.useMutation({});

  const handleCloseReport = () => {
    onCancelReport();
    setMissingEventTitle("");
  };

  return (
    <Layout.Group
      vertical
      gap="large"
      style={{
        overflowY: "hidden",
        flex: 1
      }}
    >
      <TextInput
        size="small"
        value={inputValue}
        onChange={handleInputChange}
        endAdornment={<Icon name="search" />}
        placeholder="Search event"
      />

      {isLoading && (
        <Content>
          <CircularLoader size="small" fillParent />
        </Content>
      )}
      {reportingOpen && (
        <StyledCard>
          <Card>
            <Layout.Group vertical gap="xxLarge">
              <Layout.Group vertical gap="small">
                <H4>Report a missing event</H4>
                <Title>
                  Enter the exact name of the event you're looking for, and we'll investigate
                </Title>
              </Layout.Group>
              <Input
                autoFocus
                value={missingEventTitle}
                onChange={setMissingEventTitle}
                placeholder="Name of the event"
              />
              <div className="buttons">
                <Button size="small" variant="blended" onClick={handleCloseReport}>
                  Cancel
                </Button>

                <Button
                  disabled={!missingEventTitle}
                  size="small"
                  onClick={async () => {
                    await reportMissingEvent(
                      { eventTitle: missingEventTitle },
                      {
                        onError: () => toast.error("Something went wrong, please try again later"),
                        onSuccess: () => toast.success("Thank you for reporting")
                      }
                    );
                    handleCloseReport();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Layout.Group>
          </Card>
        </StyledCard>
      )}
      {showNoEventsFound && (
        <StyledCard>
          <Card>
            <Layout.Group vertical alignItems="center">
              <Title variant="bold">We couldn't find any events matching your search</Title>
              <Title>Double-check your search for accuracy</Title>
            </Layout.Group>
          </Card>
        </StyledCard>
      )}

      {showList && (
        <div
          style={{
            maxHeight: height,
            overflowY: "auto"
          }}
        >
          <div ref={measureRef}>
            <EventList vertical gap="small">
              {filteredEvents.map((event, idx) => (
                <RadioCard
                  titleMaxLength={35}
                  key={`available-event-list-item-${event.recurringEventId}-${idx}`}
                  active={selectedEventId === event.recurringEventId}
                  startIcon="calendar"
                  title={event.title}
                  info={event.description}
                  onClick={() => onSelectEvent(event)}
                >
                  <Host>
                    <HostCalendarIcon emailProvider={event.calendar.provider} />
                    <Title variant="bold">{event.calendar.name}</Title>
                  </Host>
                </RadioCard>
              ))}
            </EventList>
          </div>
        </div>
      )}
    </Layout.Group>
  );
};
