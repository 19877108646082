import { IconName, Option, useToast } from "@introist/react-foundation/v2";
import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";

export type CompanyDataEntry = RouterOutput["companyData"]["list"][0] & {
  icon: IconName;
};

export type CompanyDataType = RouterOutput["companyData"]["list"][0]["type"];

export const CompanyDataTypes: Option[] = [
  { key: "employee", title: "Employee" },
  { key: "email", title: "Email address" },
  { key: "text", title: "Text" },
  { key: "date", title: "Date" }
];

export type CompanyDataUpdate = RouterInput["companyData"]["update"]["update"];

const withIcon = (entry: any): CompanyDataEntry => {
  if (!entry) return entry;
  if (entry.type === "email") return { ...entry, icon: "email" };
  if (entry.type === "employee") return { ...entry, icon: "person" };
  if (entry.type === "text") return { ...entry, icon: "description" };
  if (entry.type === "date") return { ...entry, icon: "calendar" };

  return { ...entry, icon: "attribute" };
};

export const useCompanyData = () => {
  const { data: entries } = api.companyData.list.useQuery({});

  return {
    entries: (entries ?? []).map(withIcon) as CompanyDataEntry[]
  };
};

export const useCompanyDataEntry = (id?: string) => {
  const { data: entry } = api.companyData.find.useQuery({ id: id! }, { enabled: !!id });

  return { entry: withIcon(entry) };
};

export const useCompanyDataActions = () => {
  const toast = useToast();

  const createOp = api.companyData.create.useMutation();
  const updateOp = api.companyData.update.useMutation();

  const apiUtils = api.useContext();

  const findOp = apiUtils.companyData.find;
  const listOp = apiUtils.companyData.list;

  const create = async (data: any) => {
    return createOp
      .mutateAsync(data)
      .then(async created => {
        await listOp.refetch();
        return withIcon(created);
      })
      .catch(err => {
        toast.error("Create failed");
        throw err;
      });
  };

  const update = async (id: string, update: CompanyDataUpdate) => {
    return updateOp
      .mutateAsync({ id, update })
      .then(async updated => {
        await listOp.refetch();
        await findOp.refetch({ id });
        return withIcon(updated);
      })
      .catch(() => {
        toast.error("Update failed");
      });
  };

  return { create, update };
};
