import { EmployeeGroup, useEmployeeGroups } from "../hooks/useEmployeeGroups";
import { CircularLoader, Select, SelectProps } from "@introist/react-foundation/v2";
import { useState } from "react";
import { SelectorIcon } from "../fields/EmployeeFieldSelector";

export const EmployeeGroupSelect = ({
  value,
  onSelect,
  ...props
}: {
  value?: string | null;
  onSelect: (group: EmployeeGroup) => unknown;
} & Omit<SelectProps, "onSelect" | "value" | "options">) => {
  const { groups } = useEmployeeGroups();

  const [selecting, setSelecting] = useState(false);

  return (
    <Select
      searchable
      size="small"
      element="button"
      variant="blended"
      startAdornment={<SelectorIcon icon="persons" />}
      value={value ?? undefined}
      placeholder="Select employee group"
      options={(groups || []).map(g => ({
        key: g.id,
        title: g.title,
        startAdornment: <SelectorIcon icon="persons" />
      }))}
      onSelect={opt => {
        setSelecting(true);
        Promise.resolve(onSelect(groups!.find(g => g.id === opt.key)!)).finally(() =>
          setSelecting(false)
        );
      }}
      endAdornment={selecting ? <CircularLoader size="tiny" /> : undefined}
      {...props}
    />
  );
};
