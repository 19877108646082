import { ActionMenu, Card, Icon, IconButton, Select, Stack } from "@introist/react-foundation/v2";
import { DataMapping, DataMappingField } from "./DataMappingEditor";
import { CodeEditorModal } from "../../../components/templates/CodeEditor";
import { useState } from "react";

const mappingFnTemplate = `async function run({data, value}) {
  // Data is the raw webhook payload.
  // Value is the source field value if the mapping has source field set.
  // Return the target value, even if it's the same as the source value.
  return value;
}`;

export const DataSourceMappingCard = ({
  value,
  sourceSelector,
  sourceFields,
  targetFields,
  onChange,
  onRemove,
  blended
}: {
  value: DataMapping;
  sourceSelector?: (onSelected: (field: string) => unknown, value?: string | null) => JSX.Element;
  sourceFields?: DataMappingField[];
  targetFields?: DataMappingField[];
  onChange: (value: DataMapping) => unknown;
  onRemove: () => unknown;
  blended?: boolean;
}) => {
  const [fnEditOpen, setFnEditOpen] = useState(false);

  const items = (
    <Stack style={{ display: "grid", gridTemplateColumns: "20px 1fr auto 1fr auto" }}>
      <div>{value.identifier && <Icon name="key" />}</div>
      {sourceSelector &&
        sourceSelector(val => onChange({ ...value, sourceField: val }), value.sourceField)}
      {!sourceSelector && (
        <Select
          searchable
          element="button"
          variant="blended"
          value={value.sourceField ?? ""}
          options={sourceFields ?? []}
          placeholder="Source field"
          onEnter={val => onChange({ ...value, sourceField: val })}
          onSelect={opt => onChange({ ...value, sourceField: opt.key })}
          style={{ overflowWrap: "anywhere" }}
          attachToRef={false}
        />
      )}
      <Stack>
        {value.transformFn && <Icon name="arrowRight" />}
        <IconButton
          variant="blended"
          size="large"
          icon={!!value.transformFn ? "workflowEditor" : "arrowRight"}
          onClick={() => setFnEditOpen(true)}
        />
        {value.transformFn && <Icon name="arrowRight" />}
      </Stack>
      <Select
        searchable
        element="button"
        variant="blended"
        value={value.targetField}
        options={targetFields ?? []}
        placeholder="Target field"
        onEnter={val => onChange({ ...value, targetField: val })}
        onSelect={opt => onChange({ ...value, targetField: opt.key })}
        style={{ overflowWrap: "anywhere" }}
        attachToRef={false}
      />
      <ActionMenu
        attachToRef={false}
        options={[
          {
            key: "identifier",
            title: value.identifier ? "Remove identifier" : "Mark identifier",
            onClick: () => onChange({ ...value, identifier: !value.identifier }),
            startAdornmentIcon: "key"
          },
          {
            key: "remove",
            title: "Remove",
            onClick: onRemove,
            startAdornmentIcon: "trash"
          }
        ]}
      />
      <CodeEditorModal
        id={`transform-${value.id}-editor`}
        title="Edit data transform"
        value={value.transformFn ?? mappingFnTemplate}
        onSave={code => {
          onChange({ ...value, transformFn: code });
          setFnEditOpen(false);
        }}
        onRemove={() => onChange({ ...value, transformFn: null })}
        open={fnEditOpen}
        onClose={() => setFnEditOpen(false)}
      />
    </Stack>
  );

  if (blended) return items;

  return <Card>{items}</Card>;
};
