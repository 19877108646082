import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";

import { Title } from "@introist/react-foundation/v2";
import { ListItem } from "../../../../foundation/blocks/ListItem";

export const SlackChannelInviteViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Details">
          <StepEditorAccordion.Row label="Inviter">
            <ListItem icon="person">
              <Title variant="bold">{resolvedData.resolvedSenderName}</Title>
            </ListItem>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <ListItem icon="hashtag">
              <Title variant="bold">{resolvedData.channel}</Title>
            </ListItem>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="Users">
            {resolvedData.resolvedUsers.map((user: any) => (
              <ListItem icon="personAdd">
                <Title variant="bold">{user.name}</Title>
              </ListItem>
            ))}
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
