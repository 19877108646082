import {
  useActions,
  useToast,
  Icon,
  Option,
  InfoCard,
  useTheme,
  ActionMenu
} from "@introist/react-foundation/v2";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { cancelJourney } from "services/api/journeys/JourneyApi";
import { JourneyOverview } from "services/api/journeys/JourneyApi";
import { RescheduleAutomationModal } from "../../../automations/containers/RescheduleAutomationModal";
import { useAutomationActions } from "../../../automations/hooks/useAutomations";
import { AutomationFieldDataPreviewModal } from "../../../automations/containers/AutomationFieldDataPreview";

interface JourneyActionProps {
  journey: JourneyOverview;
}

export const JourneyActions = ({ journey }: JourneyActionProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const [dataPreviewOpen, setDataPreviewOpen] = useState(false);

  const { remove } = useAutomationActions();

  const onCancelJourney = onConfirmAction(
    async () => {
      await cancelJourney({ journeyId: journey.id });
      toast.info(`Automation canceled`);
      navigate(`/employees/${journey.employeeId}`);
    },
    {
      title: "Cancel automation",
      description: (
        <InfoCard title="Cancel automation" icon="crossCircle" colorVariant="danger">
          All the upcoming steps will be canceled. This can not be undone.
        </InfoCard>
      ),
      confirmTitle: "Confirm",
      cancelTitle: "Go back"
    }
  );

  const onRemoveJourney = onConfirmAction(
    async () => {
      await remove(journey.id)
        .then(() => navigate(`/employees/${journey.employeeId}`))
        .catch(() => {
          toast.error("Failed to delete automation");
        });
    },
    {
      title: "Permanently delete automation",
      description:
        "This will delete all information about this automation and all its steps. Are you sure?"
    }
  );

  const actions: Option[] = [];
  const [rescheduleOpen, setRescheduleOpen] = useState(false);

  actions.push({
    key: "data-preview",
    title: "Preview field data",
    startAdornment: <Icon name="attributeFilled" />,
    onClick: () => setDataPreviewOpen(true)
  });

  if (journey && journey.status !== "canceled" && journey.status !== "completed") {
    actions.push({
      key: "reschedule",
      startAdornment: <Icon name="calendar" />,
      title: "Reschedule",
      onClick: () => setRescheduleOpen(true)
    });

    if (journey.status !== "draft") {
      actions.push({
        key: "cancel",
        title: "Cancel",
        startAdornment: <Icon name="crossCircle" color={theme.palette.danger.default} />,
        titleStyles: {
          color: theme.palette.danger.default
        },
        onClick: onCancelJourney
      });
    }
  }

  if (["draft", "completed", "canceled"].includes(journey.status)) {
    actions.push({
      key: "remove",
      title: "Delete automation",
      startAdornment: <Icon name="trash" color={theme.palette.danger.default} />,
      titleStyles: {
        color: theme.palette.danger.default
      },
      onClick: onRemoveJourney
    });
  }

  if (actions.length === 0) return null;

  return (
    <>
      <ActionMenu options={actions} />

      <RescheduleAutomationModal
        open={rescheduleOpen}
        automationId={journey.id}
        onClose={() => setRescheduleOpen(false)}
      />
      <AutomationFieldDataPreviewModal
        automationId={journey.id}
        open={dataPreviewOpen}
        onClose={() => setDataPreviewOpen(false)}
      />
    </>
  );
};
