import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";

import { Title } from "@introist/react-foundation/v2";
import { ListItem } from "../../../../foundation/blocks/ListItem";

export const SlackChannelCreateViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Details">
          <StepEditorAccordion.Row label="Creator">
            <ListItem icon="person">
              <Title variant="bold">{resolvedData.resolved.senderName}</Title>
            </ListItem>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <ListItem icon={resolvedData.privateChannel ? "lock" : "hashtag"}>
              <Title variant="bold">{resolvedData.resolved.channelName}</Title>
            </ListItem>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
