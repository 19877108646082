import { useSlackSenderApi } from "../../../../services/api/SlackSenderApi";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import { SingleTagSelect } from "../../../tags/SingleTagSelect";
import { ListItem } from "../../../../foundation/blocks/ListItem";
import { Tags } from "../../../tags";
import { useTags } from "../../../employees/hooks/useTags";
import { isEmail } from "../../../../utils/string-templater";
import { useWorkflowFieldsTagList } from "../../fields/WorkflowFieldTagList";

type SlackUserSelectProps = {
  senderId?: string;
  value: string[];
  onChange: (newValue: string[]) => unknown;
  error?: boolean;
  workflowId: string;
};

export const SlackUserSelect = ({
  senderId,
  value,
  onChange,
  workflowId
}: SlackUserSelectProps) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackUsers } = slackSenderApi.listUsers(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const workflowFieldTags = useWorkflowFieldsTagList(workflowId, ["employee"], true);
  const { tagTitle } = useTags(workflowId);

  const slackUserOptions = (slackUsers ?? []).map(user => ({
    key: user.id,
    title: user.realName,
    startAdornment: <SelectorIcon icon="person" />
  }));

  const resolveTitle = (value: string) => {
    if (isEmail(value)) return value;
    if (Tags.isTag(value)) return tagTitle(value);

    const user = slackUsers?.find(u => u.id === value);
    if (user) return user.realName;

    return tagTitle(value);
  };

  return (
    <Stack vertical gap="xSmall">
      {(value ?? []).map((path: string) => (
        <ListItem onRemove={() => onChange(value.filter(v => v !== path))}>
          <Stack style={{ width: "100%" }} gap="small">
            <SelectorIcon icon={Tags.isTag(path) ? "attribute" : "person"} />
            <Title variant="bold">{resolveTitle(path)}</Title>
          </Stack>
        </ListItem>
      ))}
      <div style={{ marginTop: (value ?? []).length > 0 ? "var(--spacing-small)" : undefined }}>
        <SingleTagSelect
          tagMode
          onChange={path => onChange([...value, path])}
          placeholder="Add users"
          fieldTypes={["email", "employee"]}
          buttonProps={{
            startAdornment: <Icon name="plusSmall" />
          }}
          customCategories={[
            workflowFieldTags,
            {
              key: "slackusers",
              title: "Slack Users",
              options: slackUserOptions,
              icon: "person"
            }
          ]}
        />
      </div>
    </Stack>
  );
};
