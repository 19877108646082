import { useMemo } from "react";
import { Icon } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

import personio from "assets/integrationIcons/personio.svg";
import planmill from "assets/integrationIcons/planmill.png";
import csv from "assets/integrationIcons/csv.svg";
import googleDrive from "assets/integrationIcons/google-drive.svg";
import hibob from "assets/integrationIcons/hibob.png";
import sympahr from "assets/integrationIcons/sympahr.svg";
import greenhouse from "assets/integrationIcons/greenhouse.svg";
import bamboohr from "assets/integrationIcons/bamboohr.svg";
import teamtailor from "assets/integrationIcons/teamtailor.svg";
import integration from "assets/integrationIcons/branch.png";
import googleforms from "assets/integrationIcons/googleforms.svg";
import severa from "assets/integrationIcons/severa.svg";
import docebo from "assets/integrationIcons/docebo.png";
import email from "assets/integrationIcons/email.png";
import jira from "assets/Icons/jira.svg";
import oracleHcm from "assets/integrationIcons/oracle-logo.png";
import docusign from "assets/integrationIcons/docusign-logo.png";
import harvest from "assets/integrationIcons/harvest-logo.png";
import lever from "assets/integrationIcons/lever.png";
import introist from "assets/introist-symbol.svg";

type Props = {
  dataSource: string;
  size?: "small" | "medium" | "large";
};

const StyledDataSourceIcon = styled.div<{ $size: Props["size"] }>`
  overflow: hidden;
  border-radius: var(--rounding-medium);

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          border-radius: var(--rounding-small);
          width: 1rem;
          height: 1rem;
        `;
      case "large":
        return css`
          width: 3rem;
          height: 3rem;
        `;
      default:
      case "medium":
        return css`
          width: 1.7rem;
          height: 1.7rem;
        `;
    }
  }}

  > img {
    width: 100%;
    object-fit: cover;
  }
`;

export const DataSourceIcon = ({ dataSource, size = "medium", ...rest }: Props) => {
  const iconUrl = useMemo(() => {
    switch (dataSource) {
      case "personio":
        return personio;
      case "planmill":
        return planmill;
      case "csv":
      case "csv-upload":
        return csv;
      case "docebo":
        return docebo;
      case "drive_csv":
        return googleDrive;
      case "drivesheet":
        return googleDrive;
      case "hibob":
        return hibob;
      case "sympahr":
        return sympahr;
      case "greenhouse":
        return greenhouse;
      case "bamboohr":
        return bamboohr;
      case "teamtailor":
        return teamtailor;
      case "googleforms":
        return googleforms;
      case "severa":
        return severa;
      case "email":
        return email;
      case "jira":
        return jira;
      case "oraclehcm":
        return oracleHcm;
      case "docusign":
        return docusign;
      case "harvest":
        return harvest;
      case "lever":
        return lever;
      case "introist":
        return introist;
      default:
        return integration;
    }
  }, [dataSource]);

  return (
    <StyledDataSourceIcon {...rest} $size={size}>
      {iconUrl ? <img src={iconUrl} alt={dataSource} /> : <Icon name="questionMark" />}
    </StyledDataSourceIcon>
  );
};
