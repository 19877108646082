import { ReadOnlyStepProps } from "../useStepEditor";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Stack, Title } from "@introist/react-foundation/v2";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";

export const EmployeeAssignStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const executionData = step.executionData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Assignment" defaultOpen={true}>
          <StepEditorAccordion.Row label="Field">
            <Title variant="bold">{executionData.assignedFieldTitle ?? "Unknown field"}</Title>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Employee">
            <Stack gap="small">
              <SelectorIcon icon="person" />
              <Title variant="bold">
                {executionData.selectedEmployeeName ?? "Unknown employee"}
              </Title>
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
