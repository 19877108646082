import { useDatasetEntry, useDatasetEntryActions } from "../../hooks/useDataset";
import {
  Caps2,
  CircularLoader,
  ConfirmationModal,
  Stack,
  Title,
  Tooltip,
  useActions,
  useTheme
} from "@introist/react-foundation/v2";
import { useDatasetFields } from "../../hooks/useDatasetFields";
import { DatasetEntryField } from "./DatasetEntryField";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { get, isEmpty } from "lodash";
import { UnsavedChanges } from "../../../../components/molecules";
import { useNavigate } from "react-router";
import { DrawerHeader } from "../../../../foundation/templates/Drawer/DrawerHeader";
import { useDatasetDef } from "../../hooks/useDatasetDefs";

const FieldRow = styled(Stack)`
  border-color: transparent;
  justify-content: center;
  padding: var(--spacing-xSmall) 0;
`;

const LimitTitle = styled.div`
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const DatasetEntryForm = ({
  datasetId,
  entryId,
  onClose,
  onChanged
}: {
  datasetId: string;
  entryId: string;
  onClose?: () => unknown;
  onChanged?: (changes: Record<string, any>) => unknown;
}) => {
  const { theme } = useTheme();

  const { onConfirmAction } = useActions();
  const navigate = useNavigate();

  const [confirmClose, setConfirmClose] = useState(false);

  const { def } = useDatasetDef({ id: datasetId });
  const { fields } = useDatasetFields(datasetId);

  const { entry } = useDatasetEntry(entryId, { enabled: entryId !== "new" });

  const { create, update, remove } = useDatasetEntryActions();

  const [changes, setChanges] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<{ field: string; message: string }[]>([]);

  useEffect(() => {
    onChanged && onChanged(changes);
  }, [changes, onChanged]);

  const save = useCallback(
    async (close?: boolean) => {
      if (entryId === "new") {
        return create(datasetId, changes)
          .then(created => {
            setChanges({});
            setErrors([]);
            if (close) {
              navigate(`../`, { relative: "path" });
            } else {
              navigate(`../${created.id}`, { relative: "path" });
            }
          })
          .catch(e => {
            setErrors(e.data?.errors ?? []);
          });
      }

      return update(entryId, changes)
        .then(() => {
          setChanges({});
          setErrors([]);
          if (close && onClose) onClose();
        })
        .catch(e => {
          setErrors(e.data?.errors ?? []);
        });
    },
    [create, update, changes, entryId, navigate, datasetId, onClose]
  );

  const onRemove = onConfirmAction(
    async () => {
      await remove(datasetId, entryId).then(() => navigate("../", { relative: "path" }));
    },
    {
      title: "Delete entry",
      description: "Are you sure you want to delete this entry?",
      confirmTitle: "Delete"
    }
  );

  if (!fields || (entryId !== "new" && !entry)) return <CircularLoader fillParent />;

  const getValue = (key: string) => {
    return changes[key] !== undefined ? changes[key] : get(entry?.data ?? {}, key);
  };

  return (
    <Stack vertical gap="xLarge">
      <DrawerHeader
        title={
          entryId === "new" ? `New ${def?.title}` : get(entry?.data ?? {}, fields[0].key) ?? ""
        }
        onClose={() => {
          if (isEmpty(changes)) {
            onClose && onClose();
          } else {
            setConfirmClose(true);
          }
        }}
        actions={
          entryId !== "new"
            ? [
                {
                  key: "delete",
                  title: "Delete",
                  startAdornmentIcon: "trash",
                  onClick: onRemove
                }
              ]
            : undefined
        }
      />
      <Stack vertical>
        {fields.map(field => (
          <FieldRow>
            <LimitTitle style={{ width: "30%" }}>
              <Stack>
                <Tooltip tooltip={field.title}>
                  <Title>{field.title}</Title>
                </Tooltip>
                {field.isRequired && (
                  <Caps2 style={{ color: theme.palette.foreground.subdued }}>*</Caps2>
                )}
              </Stack>
            </LimitTitle>

            <DatasetEntryField
              autoFocus={field.key === fields[0].key && entryId === "new"}
              field={field}
              value={getValue(field.key)}
              hasChanges={get(changes, field.key) !== undefined}
              onChange={val => setChanges({ ...changes, [field.key]: val === "" ? null : val })}
              error={errors.find(e => e.field === field.key)?.message}
            />
          </FieldRow>
        ))}
      </Stack>
      <UnsavedChanges
        show={!isEmpty(changes)}
        onSave={() => save()}
        onDiscard={() => setChanges({})}
        style={{
          position: "fixed",
          bottom: "1rem",
          left: "calc(50% - 150px)",
          width: "300px"
        }}
      />
      <ConfirmationModal
        show={confirmClose}
        onClose={() => setConfirmClose(false)}
        onCancel={onClose}
        onConfirm={() => save(true)}
        config={{
          title: "Unsaved changes",
          description: "You have unsaved changes. Do you want to save them?",
          cancelTitle: "Discard changes",
          confirmTitle: "Save changes"
        }}
      />
    </Stack>
  );
};
