import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Button, ErrorMessage, Stack, Title } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { AttachmentsBlock } from "../../..//workflows/blocks/Attachments";
import { SlackUserSelect } from "./SlackUserSelect";

export const SlackDirectMessageEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const messageError = prefixedForm.error("message");

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Recipients" defaultOpen>
          <Stack vertical alignItems="stretch" gap="large">
            <StepEditorAccordion.Row label="From">
              <SlackSenderSelectV2
                senderId={prefixedForm.get("senderId")}
                onSelectSender={prefixedForm.set("senderId")}
                error={prefixedForm.isError("senderId")}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.MultiOptionRow label="Users">
              <SlackUserSelect
                senderId={prefixedForm.get("senderId")}
                error={prefixedForm.isError("users")}
                value={prefixedForm.get("users") ?? []}
                onChange={prefixedForm.set("users")}
                workflowId={workflowId}
              />
            </StepEditorAccordion.MultiOptionRow>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
              underline={false}
              extra={
                <Button
                  variant="blended"
                  size="small"
                  style={{ width: "80px" }}
                  onClick={() => {
                    const current = prefixedForm.get("textOnly");
                    prefixedForm.set("textOnly")(!current);
                  }}
                >
                  <Title>{prefixedForm.get("textOnly") ? "Text" : "Blocks"}</Title>
                </Button>
              }
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <AttachmentsBlock form={form} />
    </>
  );
};
