import { ModalV2, Stack, Title } from "@introist/react-foundation/v2";
import { ListView, useSearch } from "../../../foundation";
import { api, RouterOutput } from "../../../services/rpc/RpcProvider";
import { ListRow } from "../../../components/organisms";
import { formatDateTime } from "../../../utils/DatesUtils";
import { Allotment } from "allotment";
import { useState } from "react";
import { SearchInput } from "../../../components/molecules";
import { JsonEditor } from "../../../components/templates/CodeEditor/CodeEditor";

type LogEntry = RouterOutput["employees"]["dataSources"]["log"][0];

export const DataSourceRequestLogModal = ({
  dsId,
  open,
  onClose
}: {
  dsId: string;
  open: boolean;
  onClose: () => unknown;
}) => {
  const { data: log } = api.employees.dataSources.log.useQuery({ dataSourceId: dsId });

  const { filtered, search, setSearch } = useSearch(log, {
    fields: ["id", "timestamp", "request", "result"]
  });

  const [selected, setSelected] = useState<LogEntry | undefined>();

  return (
    <ModalV2 maxWidth={1200} title="Last 100 requests" open={open} onClose={onClose}>
      <Stack style={{ height: "65vh" }}>
        <Allotment defaultSizes={[100, 200]}>
          <Stack
            vertical
            style={{ paddingRight: "var(--spacing-xLarge)", height: "100%", overflowY: "auto" }}
          >
            <Stack>
              <SearchInput value={search} onChange={setSearch} style={{ flex: 1 }} />
            </Stack>

            <ListView.List
              style={{ overflowY: "initial" }}
              data={filtered}
              renderRow={l => (
                <ListRow gridTemplateColumns="100px auto" onClick={() => setSelected(l)}>
                  <Title>{formatDateTime(l.timestamp)}</Title>
                  <Title style={{ marginLeft: "auto" }}>{l.code}</Title>
                </ListRow>
              )}
            />
          </Stack>
          <Stack
            vertical
            style={{ padding: "0 var(--spacing-xLarge)", height: "100%", overflowY: "auto" }}
          >
            {!selected && (
              <Title style={{ textAlign: "center" }}>Select entry to view details</Title>
            )}
            {selected && (
              <>
                <Stack>
                  <Title variant="bold">{formatDateTime(selected.timestamp)}</Title>
                  <Title variant="bold">{selected.code}</Title>
                </Stack>
                <Title>Request</Title>
                <JsonEditor
                  value={selected.request}
                  editable={false}
                  readOnly
                  style={{ borderRadius: 8 }}
                />
                <Title>Result</Title>
                <JsonEditor
                  value={selected.result}
                  editable={false}
                  readOnly
                  style={{ borderRadius: 8 }}
                />
              </>
            )}
          </Stack>
        </Allotment>
      </Stack>
    </ModalV2>
  );
};
