import { ListView, TopBarRoute } from "../../../../components/templates";
import { useNavigate, useParams } from "react-router";
import { useDatasetDef } from "../../hooks/useDatasetDefs";
import { CircularLoader, Stack } from "@introist/react-foundation/v2";
import { useDataset } from "../../hooks/useDataset";
import { useDatasetFields } from "../../hooks/useDatasetFields";
import { DatasetTable } from "./DatasetTable";
import { useSearch } from "../../../../foundation";
import { DatasetEntryDrawer } from "./DatasetEntryDrawer";

export const DatasetRoute = () => {
  const navigate = useNavigate();
  const { id, entryId } = useParams();

  const { def } = useDatasetDef({ id: id! });
  const { fields = [] } = useDatasetFields(def?.id ?? "", { enabled: !!def });

  const { entries } = useDataset(def?.id ?? "", { enabled: !!def });

  const { filtered, search, setSearch } = useSearch(entries, {
    fields: (fields ?? []).map(f => "data." + f.key)
  });

  if (!def || !fields) return <CircularLoader fillParent />;

  return (
    <TopBarRoute paths={[{ key: "dataset", title: def.plural }]}>
      <Stack vertical>
        <ListView.Header
          title={def.plural}
          onCreate={() => navigate(`/datasets/${def.id}/new`)}
          search={{
            search,
            setSearch
          }}
        />
        <DatasetTable
          datasetId={def.id}
          entries={filtered ?? []}
          onClick={id => navigate(`/datasets/${def.id}/${id}`)}
        />
      </Stack>
      <DatasetEntryDrawer datasetId={def.id} entryId={entryId} />
    </TopBarRoute>
  );
};
