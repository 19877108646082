import { Stack, Title } from "@introist/react-foundation/v2";

import { useState } from "react";
import { ResourceSelector } from "./modules/system/resources/ResourceSelector";

export const Playground = () => {
  const [value, setValue] = useState<string | null>(null);

  return (
    <Stack vertical justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <Title>{value}</Title>
      <ResourceSelector
        resourceType="linkedaccounts.slack"
        onSelect={setValue}
        value={value ?? undefined}
        tagContext={{
          workflowId: "5c27226f-d7ab-40b1-9962-cf138a44971d"
        }}
        placeholder="Select sender"
      />
    </Stack>
  );
};
