import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { AttendanceBlock } from "../../../journeys/JourneyStepEditor/AttendToEvent/components/AttendanceBlock";

export const AttendToEventStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  return (
    <AttendanceBlock
      attendeeId={(step.executionData as any)?.attendeeId}
      journeyId={step.automationId}
    />
  );
};
