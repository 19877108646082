import { Trigger } from "../../hooks/useTriggers";
import { useWorkflow, useWorkflows } from "../../../flows/hooks/useWorkflows";
import { useTriggerActions } from "../../hooks/useTrigger";
import { useMemo, useState } from "react";
import { Card, OptionList, Popover, Stack, Title } from "@introist/react-foundation/v2";
import { IconPickerIcon } from "../../../../components/organisms";
import { SetupCard } from "./SetupCard";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { EventTriggerSettings } from "../Settings";

export const WorkflowEditor = ({ trigger }: { trigger: Trigger }) => {
  const { workflow } = useWorkflow(trigger.workflowId!, { enabled: !!trigger.workflowId });
  const { workflows } = useWorkflows({});

  const { update } = useTriggerActions();

  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const options = useMemo(() => {
    return (workflows ?? []).map(w => ({
      key: w.id,
      title: w.title,
      startAdornment: <SelectorIcon icon="workflows" />
    }));
  }, [workflows]);

  return (
    <div ref={setRef} style={{ width: "100%", height: "100%" }}>
      {!trigger.workflowId && (
        <SetupCard icon="workflows" title="Select workflow" onClick={() => setOpen(true)} />
      )}
      {trigger.workflowId && (
        <Card
          style={{ width: "100%", height: "100%", padding: "var(--spacing-xLarge)" }}
          onClick={() => setOpen(true)}
        >
          <Stack style={{ height: "100%" }}>
            <IconPickerIcon fallbackIcon="workflows" />
            <Stack>
              <Title variant="bold">{workflow?.title}</Title>
            </Stack>
            <div
              style={{ marginLeft: "auto" }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <EventTriggerSettings triggerId={trigger.id} />
            </div>
          </Stack>
        </Card>
      )}

      <Popover referenceElement={ref} onClose={() => setOpen(false)} open={open}>
        <OptionList
          searchable
          options={options}
          onItemClick={async opt => {
            await update(trigger.id, { workflowId: opt.key });
            setOpen(false);
          }}
        />
      </Popover>
    </div>
  );
};
