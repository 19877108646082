const TagRegex = /^\{\{(.+?)}}$/;

export class Tags {
  static isTag = (val: any) => {
    if (typeof val !== "string") return false;
    return TagRegex.test(val);
  };

  static path = (tag: string) => {
    const match = tag.match(TagRegex);
    if (!match) return tag;

    return match[1];
  };
}
