import { CircularLoader } from "@introist/react-foundation/v2";
import { EventTriggerRoute } from "../event-triggers/EventTriggerRoute";
import { RuleTriggerRoute } from "./RuleTriggerRoute";
import { useTrigger } from "../hooks/useTrigger";
import { useParams } from "react-router";

export const TriggerRoute = () => {
  const { triggerId } = useParams();

  const { trigger } = useTrigger({ id: triggerId! }, { enabled: !!triggerId });

  if (!trigger) return <CircularLoader fillParent />;

  if (trigger.type === "event") return <EventTriggerRoute />;
  return <RuleTriggerRoute />;
};
