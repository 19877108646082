import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useActions,
  useForm,
  ConfirmationModal,
  useToast,
  CircularLoader,
  Layout,
  Title
} from "@introist/react-foundation/v2";
import { getSchemaFieldsForStepContent } from "../../workflows/types";
import { StepHeaderBlock } from "../../workflows/components/steps/StepHeaderBlock";
import { JourneyStepTriggerBlock } from "./JourneyStepTriggerBlock";
import { RescheduleJourneyStep } from "../components/RescheduleJourneyStep";
import { getEditorForStepType } from "./StepEditorFactory";
import { useAlertApi } from "../../issues/api/AlertApi";
import { api, JourneyStepV2, StepTypesV2, StepTypeV2 } from "../../../services/rpc/RpcProvider";

import { useUpdateStep } from "../hooks/use-update-step";
import { useJourneyStepActions } from "../hooks";
import { JourneyStepStatusBlock } from "./JourneyStepStatusBlock";
import styled from "styled-components";
import { StepTypeIcon, TabBarBlock } from "modules/workflows/components/steps";
import { AdvancedJourneySettings } from "modules/workflows/routes/WorkflowEditor/StepEditor/AdvancedSettings";
import { UnsavedChanges } from "../../../components/molecules";
import { WorkflowStepTypes } from "../../flows/stepTypes";
import { AutomationStepErrors } from "../../automations/containers/AutomationStepErrors";
import { AUTOMATION_ROUTES } from "../../automations/routes";
import { useEmployee } from "../../employees/hooks/useEmployees";
import { useAutomation } from "../../automations/hooks/useAutomations";

interface JourneyStepEditorProps {
  journeyStep: JourneyStepV2;
  onClose: () => void;
  enableJourneyNavigate?: boolean;
}

const Content = styled.div`
  padding: var(--spacing-large) 0;
`;

export const JourneyStepEditor = ({
  journeyStep,
  onClose,
  enableJourneyNavigate
}: JourneyStepEditorProps) => {
  const { onAction } = useActions();

  const toast = useToast();
  const [activeTab, setActiveTab] = useState("General");

  const { data: loadedStep, refetch: reloadStep } = api.journeys.steps.find.useQuery({
    stepId: journeyStep.id
  });
  const { automation } = useAutomation({ id: journeyStep.journeyId! });
  const { employee } = useEmployee(
    automation ? { id: automation!.employeeId!, fields: undefined } : undefined
  );

  const { actions, rescheduleOpen, setRescheduleOpen } = useJourneyStepActions(journeyStep);

  const [confirmExit, setConfirmExit] = useState(false);

  const stepForm = useForm<JourneyStepV2>({});
  const { setValue: setFormData } = stepForm;

  const alertApi = useAlertApi();
  const { data: issues } = alertApi.list({
    journeyId: journeyStep.journeyId,
    stepId: journeyStep.id
  }).query;

  const updateStep = useUpdateStep(
    journeyStep.journeyId,
    journeyStep.id,
    stepForm.data?.stepType as StepTypeV2,
    updated => {
      setFormData(updated, false);
    }
  );

  const updateAutomationStep = api.automations.steps.update.useMutation();

  const updateAll = useCallback(async () => {
    await reloadStep();
  }, [reloadStep]);

  useEffect(() => {
    if (loadedStep) setFormData(loadedStep as unknown as JourneyStepV2, false);
  }, [loadedStep, setFormData]);

  const useGen2Editor = useMemo(() => {
    const stepDef = WorkflowStepTypes.find(s => s.stepType === loadedStep?.stepType);
    return !!stepDef?.Editor;
  }, [loadedStep]);

  const saveStep = onAction(async () => {
    if (useGen2Editor) {
      return updateAutomationStep
        .mutateAsync({ id: journeyStep.id, update: { stepData: stepForm.data.previewData } })
        .then(async updated => {
          await updateAll();
          toast.success("Step saved");
        })
        .catch(() => {
          toast.error("Failed to save step");
        });
    }

    if (
      !stepForm.validate({
        previewData: getSchemaFieldsForStepContent(stepForm.data.stepType as StepTypeV2)
      })
    ) {
      throw new Error("Step validation failed");
    }

    await updateStep(stepForm.data.previewData);
    toast.success("Step saved");
  }, "Failed to save step, check the fields");

  const editor = useMemo(() => {
    if (useGen2Editor) {
      const stepDef = WorkflowStepTypes.find(s => s.stepType === loadedStep?.stepType)!;
      if (!loadedStep?.deliveredAt) {
        if (!stepDef.Editor) return null;
        return (
          <stepDef.Editor
            form={stepForm}
            stepDataPath="previewData."
            workflowId={automation!.workflowId}
          />
        );
      }
      if (loadedStep?.deliveredAt && stepDef.Viewer) {
        if (!stepDef.Viewer) return null;
        return <stepDef.Viewer stepId={journeyStep.id} />;
      }
      return null;
    }

    return getEditorForStepType(automation!.workflowId, stepForm, employee, true, async () => {
      await reloadStep();
    });
  }, [stepForm, reloadStep, loadedStep, useGen2Editor, journeyStep.id, employee, automation]);

  if (!stepForm.data.id || !loadedStep) {
    return <CircularLoader fillParent />;
  }

  return (
    <Content>
      <div style={{ position: "sticky", top: 0, background: "white", zIndex: 100 }}>
        <StepHeaderBlock
          onClose={() => {
            if (stepForm.edited) setConfirmExit(true);
            else onClose();
          }}
          onOpenJourney={
            enableJourneyNavigate
              ? () =>
                  window.open(
                    AUTOMATION_ROUTES.automation.path.replace(
                      ":automationId",
                      journeyStep.journeyId
                    ),
                    "_blank"
                  )
              : undefined
          }
          actions={actions}
          left={
            <Layout.Group gap="large">
              <StepTypeIcon compact variant="blended" stepType={loadedStep!.stepType} />
              <Title variant="bold">{loadedStep!.title ?? "Untitled step"}</Title>
            </Layout.Group>
          }
        />
      </div>
      <TabBarBlock onChange={setActiveTab} />

      {activeTab === "Advanced" && (
        <AdvancedJourneySettings
          form={stepForm}
          readOnly={true}
          workflowId={automation!.workflowId}
        />
      )}

      {activeTab === "General" && (
        <>
          <JourneyStepStatusBlock step={stepForm.data} issues={useGen2Editor ? [] : issues} />

          {!["completed", "canceled"].includes(journeyStep.status) && (
            <AutomationStepErrors stepId={journeyStep.id} errors={journeyStep.errors ?? []} block />
          )}

          <JourneyStepTriggerBlock
            journeyStepId={journeyStep.id}
            start={stepForm.data.start}
            end={stepForm.data.stepType === StepTypesV2.Enum.event ? stepForm.data.end : undefined}
            allDay={
              stepForm.data.stepType === "event" &&
              stepForm.data.start.time === stepForm.data.end.time
            }
            stepType={stepForm.data.stepType}
            scheduledAt={stepForm.data.scheduledAt}
            deliveredAt={stepForm.data.deliveredAt}
            status={stepForm.data.status}
            cutoffDays={stepForm.data.cutoffDays}
          />

          {editor}
        </>
      )}

      <ConfirmationModal
        show={confirmExit}
        onClose={() => setConfirmExit(false)}
        onCancel={() => {
          setConfirmExit(false);
          onClose();
        }}
        onConfirm={() => saveStep().then(onClose)}
        config={{
          title: "Unsaved changes",
          description: "You have unsaved changes. What would you like to do?",
          cancelTitle: "Dont'save",
          confirmTitle: "Save changes"
        }}
      />
      <RescheduleJourneyStep
        allowCreate={!stepForm.data.journeyData?.doNotCreate}
        open={rescheduleOpen}
        onClose={() => setRescheduleOpen(false)}
        step={stepForm.data}
        onRescheduled={async () => {
          await updateAll();
          setRescheduleOpen(false);
          toast.success("Step rescheduled!");
        }}
      />

      <div style={{ height: "20px" }} />
      <UnsavedChanges
        show={stepForm.edited}
        onSave={saveStep}
        style={{ position: "sticky", bottom: "1rem", width: "250px", left: "calc(50% - 125px)" }}
      />
    </Content>
  );
};
