import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { Icon } from "@introist/react-foundation/v2";
import styled from "styled-components";

export type DraggableItem = { id: string };

export type OrderableListProps<T extends DraggableItem> = {
  data?: T[];
  onReorder: (items: T[]) => void;
  renderRow: (item: T, index: number) => ReactNode;
  empty?: JSX.Element;
};

const DraggableRow = styled.div`
  padding-top: var(--spacing-small);
`;

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const OrderableList = <T extends DraggableItem>({
  data,
  onReorder,
  renderRow,
  empty
}: OrderableListProps<T>) => {
  const [innerData, setInnerData] = useState<T[]>([]);

  useEffect(() => {
    setInnerData(data ?? []);
  }, [data]);

  const onDragEnd = useCallback(
    async ({ source, destination }: any) => {
      if (!destination) return;
      const reordered = reorder(innerData, source.index, destination.index);

      setInnerData(reordered);
      onReorder && onReorder(reordered);
    },
    [innerData, onReorder]
  );

  if (data && data.length === 0) return empty ?? null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-employee-fields">
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {innerData.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={!onReorder}
              >
                {(provided, snapshot) => (
                  <PortalDraggable
                    child={<DraggableRow>{renderRow(item, index)}</DraggableRow>}
                    provided={provided}
                    snapshot={snapshot}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const PortalDraggable = ({
  child,
  provided,
  snapshot
}: {
  child: ReactNode;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const usePortal: boolean = snapshot.isDragging;

  const elem = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      {child}
    </div>
  );

  if (!usePortal) {
    return elem;
  }

  // if dragging - put the item in a portal
  // this needs to be done so that items do not appear when this whole component is in Drawer (in portal)
  return ReactDOM.createPortal(elem, document.getElementById("root")!);
};

export const DragHandle = () => <Icon name="grab" dimmed />;
