import { filterFnTemplate, Trigger } from "../../hooks/useTriggers";
import { Button, Card, Stack } from "@introist/react-foundation/v2";
import { useState } from "react";
import { CodeEditorModal } from "../../../../components/templates/CodeEditor";
import { useTriggerActions, useTriggerHistory } from "../../hooks/useTrigger";
import { formatDateTime } from "../../../../utils/DatesUtils";
import { EmployeeGroupSelect } from "../../../employees/containers/EmployeeGroupSelect";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";

export const FilterEditor = ({ trigger }: { trigger: Trigger }) => {
  const [filterEditOpen, setFilterEditOpen] = useState<boolean>(false);

  const { history } = useTriggerHistory({ id: trigger.id, includeEvents: true });

  const { update } = useTriggerActions();

  return (
    <>
      <Card
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          padding: "var(--spacing-medium) var(--spacing-xLarge)"
        }}
      >
        <Stack vertical gap="medium">
          <EmployeeGroupSelect
            placeholder="All employees"
            value={trigger.employeeGroupId}
            onSelect={group => update(trigger.id, { employeeGroupId: group.id })}
          />
          <Button
            variant="blended"
            size="small"
            startAdornment={<SelectorIcon icon="filter" />}
            onClick={() => setFilterEditOpen(true)}
            style={{ marginRight: "auto" }}
          >
            Filter function
          </Button>
        </Stack>
      </Card>
      <CodeEditorModal
        id={`${trigger.id}-filter`}
        title="Trigger event filter"
        value={trigger.filterFn ?? filterFnTemplate}
        onSave={async code => {
          await update(trigger.id, { filterFn: code });
          setFilterEditOpen(false);
        }}
        onRemove={() => update(trigger.id, { filterFn: null })}
        open={filterEditOpen}
        onClose={() => setFilterEditOpen(false)}
        testTemplates={(history ?? []).slice(0, 30).map(c => ({
          key: c.id,
          title: `${formatDateTime(c.timestamp)} (${c.id})`,
          json: { event: c.event }
        }))}
      />
    </>
  );
};
