import { ConfirmationModal } from "@introist/react-foundation/v2";

type StepConfirmationModalProps = {
  show: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

export const StepConfirmationModal = ({
  show,
  onClose,
  onCancel,
  onConfirm
}: StepConfirmationModalProps) => {
  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      config={{
        title: "Unsaved changes",
        description: "You have unsaved changes. What would you like to do?",
        cancelTitle: "Discard changes",
        confirmTitle: "Save changes"
      }}
    />
  );
};
