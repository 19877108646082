import { Trigger } from "../hooks/useTriggers";
import { Field, Modal, Select, Stack } from "@introist/react-foundation/v2";
import { useEmployeeGroups } from "../../employees/hooks/useEmployeeGroups";
import { TriggerUpdate, useTriggerActions } from "../hooks/useTrigger";
import { useCallback, useMemo, useState } from "react";
import { FormFooter } from "../../datasources/components/FormFooter";

export const TriggerSettingsModal = ({
  trigger,
  open,
  onClose
}: {
  trigger: Trigger;
  open: boolean;
  onClose: () => unknown;
}) => {
  const { groups } = useEmployeeGroups();

  const { update } = useTriggerActions();

  const [updates, setUpdates] = useState<TriggerUpdate>({});

  const groupOptions = useMemo(() => {
    if (!groups) return [];
    return [
      { key: "all", title: "All employees" },
      ...groups.map(g => ({ key: g.id, title: g.title }))
    ];
  }, [groups]);

  const save = useCallback(async () => {
    const finalUpdates = {
      ...updates,
      employeeGroupId: updates.employeeGroupId === "all" ? null : updates.employeeGroupId
    };

    await update(trigger.id, finalUpdates).then(() => onClose());
  }, [trigger, update, updates, onClose]);

  return (
    <Modal title="Edit settings" open={open} onClose={onClose} style={{ width: "350px" }}>
      <Stack vertical>
        <Field title="Limit to employees in">
          <Select
            searchable
            options={groupOptions}
            style={{ width: "100%" }}
            value={updates?.employeeGroupId ?? trigger?.employeeGroupId ?? "all"}
            onSelect={opt => setUpdates({ ...updates, employeeGroupId: opt.key })}
          />
        </Field>
      </Stack>
      <FormFooter onCancel={onClose} onSubmit={save} />
    </Modal>
  );
};
