import {
  Expression,
  Value,
  OR,
  AND,
  FieldValue,
  EQ,
  LT,
  GT,
  orSchema,
  andSchema,
  IN,
  REGEX,
  includesSchema,
  EXISTS,
  LTE,
  GTE
} from "@introist/introist-commons/dist/object-filter";
import { v4 as uuidv4 } from "uuid";
import {
  buildComparatorSchema,
  ewTransformSchema,
  eqTransformSchema,
  gtTransformSchema,
  inTransformSchema,
  ltTransformSchema,
  NE,
  neTransformSchema,
  NIN,
  ninTransformSchema,
  swTransformSchema,
  newTransformSchema,
  NEW,
  nswTransformSchema,
  NSW,
  notIncludesTransformSchema,
  INC,
  NINC,
  existsTransformSchema,
  lteTransformSchema,
  gteTransformSchema,
  NGTE,
  NLT,
  NLTE,
  notGteTransformSchema,
  notLtTransformSchema,
  notLteTransformSchema,
  NGT,
  notGtTransformSchema
} from "../ExpressionEditor/comparators";
import { Comparator, ComparisonMode, ConditionWithId } from "../ExpressionEditor";

export type ConditionGroupBase = {
  id: string;
  mode: ComparisonMode;
  conditions: ConditionWithId[];
};

export type ConditionGroupLevel1 = ConditionGroupBase & {
  conditions: (ConditionWithId | ConditionGroupBase)[];
};

export type ConditionGroupLevel2 = ConditionGroupBase & {
  conditions: (ConditionWithId | ConditionGroupLevel1)[];
};

export const conditionToValue = (condition: ConditionWithId): Value => {
  return {
    [condition.attribute]: buildComparatorSchema(condition.value as string, condition.comparator)
  } as Value;
};

type ConditionOrGroup = ConditionWithId | ConditionGroupBase;

export const conditionGroupToExpression = (conditionGroup: ConditionGroupLevel2): Expression => {
  const { mode, conditions } = conditionGroup;

  if (mode === "and") {
    const and = conditions.map(condition => {
      if ("conditions" in condition) {
        return conditionGroupToExpression(condition as unknown as ConditionGroupLevel2);
      }
      return conditionToValue(condition as ConditionWithId);
    });
    return { and };
  } else if (mode === "or") {
    const or = conditions.map(condition => {
      if ("conditions" in condition) {
        return conditionGroupToExpression(condition as unknown as ConditionGroupLevel2);
      }
      return conditionToValue(condition as ConditionWithId);
    });

    return { or };
  }

  throw new Error("Invalid condition group mode");
};

export const expressionToConditionGroup = (expression: Expression): ConditionGroupLevel2 => {
  // if the expression doesn't contain AND or OR, it's a single condition
  if (!("and" in expression || "or" in expression)) {
    return {
      mode: "and",
      id: uuidv4(),
      conditions: [valueToCondition(expression as Value)]
    };
  }

  if (orSchema.safeParse(expression).success) {
    const { or } = expression as OR;

    const conditions: ConditionOrGroup[] = or.map(expValue => {
      if ("and" in expValue || "or" in expValue) {
        return expressionToConditionGroup(expValue as Expression);
      }
      return valueToCondition(expValue as Value);
    });

    return {
      mode: "or",
      id: uuidv4(),
      conditions: conditions as unknown as ConditionWithId[] &
        (ConditionWithId | ConditionGroupLevel1)[]
    };
  }
  if (andSchema.safeParse(expression).success) {
    const { and } = expression as AND;

    const conditions: ConditionOrGroup[] = and.map(expValue => {
      if ("and" in expValue || "or" in expValue) {
        return expressionToConditionGroup(expValue as Expression);
      }
      return valueToCondition(expValue as Value);
    });

    return {
      mode: "and",
      id: uuidv4(),
      conditions: conditions as unknown as ConditionWithId[] &
        (ConditionWithId | ConditionGroupLevel1)[]
    };
  }

  throw new Error("Expression is not a valid ConditionGroup");
};

export const valueToCondition = (exprValue: Value) => {
  const attribute = Object.keys(exprValue)[0];
  const data = exprValue[attribute];

  let comparator: Comparator;
  let value: FieldValue;

  if (neTransformSchema.safeParse(data).success) {
    comparator = "ne";

    const {
      not: { eq }
    } = data as NE;

    value = eq;
  } else if (eqTransformSchema.safeParse(data).success) {
    comparator = "eq";

    const { eq } = data as EQ;

    value = eq;
  } else if (ltTransformSchema.safeParse(data).success) {
    comparator = "lt";

    const { lt } = data as LT;

    value = lt;
  } else if (lteTransformSchema.safeParse(data).success) {
    comparator = "lte";

    const { lte } = data as LTE;

    value = lte;
  } else if (gtTransformSchema.safeParse(data).success) {
    comparator = "gt";

    const { gt } = data as GT;

    value = gt;
  } else if (gteTransformSchema.safeParse(data).success) {
    comparator = "gte";

    const { gte } = data as GTE;

    value = gte;
  } else if (inTransformSchema.safeParse(data).success) {
    comparator = "in";

    const { in: inValue } = data as IN;

    value = inValue.join(",");
  } else if (ninTransformSchema.safeParse(data).success) {
    comparator = "nin";

    const {
      not: { in: inValue }
    } = data as NIN;

    value = inValue.join(",");
  } else if (swTransformSchema.safeParse(data).success) {
    comparator = "startswith";

    const { regex } = data as REGEX;

    value = regex.slice(1);
  } else if (nswTransformSchema.safeParse(data).success) {
    comparator = "notstartswith";

    const {
      not: { regex }
    } = data as NSW;

    value = regex.slice(1);
  } else if (ewTransformSchema.safeParse(data).success) {
    comparator = "endswith";

    const { regex } = data as REGEX;

    value = regex.slice(0, -1);
  } else if (newTransformSchema.safeParse(data).success) {
    comparator = "notendswith";

    const {
      not: { regex }
    } = data as NEW;

    value = regex.slice(0, -1);
  } else if (includesSchema.safeParse(data).success) {
    comparator = "includes";

    const { includes } = data as INC;

    value = includes;
  } else if (notIncludesTransformSchema.safeParse(data).success) {
    comparator = "notincludes";

    const {
      not: { includes }
    } = data as NINC;

    value = includes;
  } else if (existsTransformSchema.safeParse(data).success) {
    const { exists } = data as EXISTS;

    comparator = exists ? "exists" : "notexists";
    value = "";
  } else if (notGteTransformSchema.safeParse(data).success) {
    comparator = "ngte";

    const {
      not: { gte }
    } = data as NGTE;

    value = gte;
  } else if (notLtTransformSchema.safeParse(data).success) {
    comparator = "nlt";

    const {
      not: { lt }
    } = data as NLT;

    value = lt;
  } else if (notLteTransformSchema.safeParse(data).success) {
    comparator = "nlte";

    const {
      not: { lte }
    } = data as NLTE;

    value = lte;
  } else if (notGtTransformSchema.safeParse(data).success) {
    comparator = "ngt";

    const {
      not: { gt }
    } = data as NGT;

    value = gt;
  } else {
    throw new Error(`Unknown expression ${JSON.stringify(data)}`);
  }

  return {
    id: uuidv4(),
    attribute,
    comparator,
    value
  };
};
