import { StepEditorProps } from "../useStepEditor";
import { prefixForm } from "../../../../utils/FormUtils";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { CompanyEventSelector } from "../../../workflows/routes/WorkflowEditor/StepEditor/AttendToEventEditor/CompanyEventSelector";
import { Select } from "@introist/react-foundation/v2";

import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { RelativeDateEditor } from "../../../../foundation/blocks/RelativeDateEditor";
import { useWorkflowFieldsTagList } from "../../fields/WorkflowFieldTagList";

export const AttendToEventStepEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const workflowFieldTagList = useWorkflowFieldsTagList(workflowId);

  const { fields } = useEmployeeFields();

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Attendance">
          <StepEditorAccordion.Row label="Event">
            <CompanyEventSelector
              companyEventId={prefixedForm.get("companyEventId")}
              onChange={prefixedForm.set("companyEventId")}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Attendee">
            <Select
              size="small"
              searchable
              attachToRef={false}
              element="button"
              startAdornment={<SelectorIcon icon="person" />}
              variant="blended"
              options={(fields ?? [])
                .filter(f => f.type === "email")
                .map(a => ({ key: a.key, title: a.title }))}
              placeholder="Select attendee"
              value={prefixedForm.get("attendeeAttribute")}
              onSelect={opt => prefixedForm.set("attendeeAttribute")(opt.key)}
              onEnter={value => prefixedForm.set("attendeeAttribute")(value)}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Session timing">
          <StepEditorAccordion.Row label="Earliest">
            <RelativeDateEditor
              value={prefixedForm.get("after")}
              onChange={prefixedForm.set("after")}
              referenceEnabled
              onClear={() => prefixedForm.set("after")(null)}
              placeholder="As soon as possible"
              customReferenceCategories={[workflowFieldTagList]}
              workflowId={workflowId}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Latest">
            <RelativeDateEditor
              value={prefixedForm.get("before")}
              onChange={prefixedForm.set("before")}
              referenceEnabled
              onClear={() => prefixedForm.set("before")(null)}
              placeholder="Anytime"
              customReferenceCategories={[workflowFieldTagList]}
              workflowId={workflowId}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
