import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Stack, Title } from "@introist/react-foundation/v2";
import { Avatar } from "../../../../components/molecules";

type EmailMembersProps = {
  from: string;
  replyTo: string;
  to: string[];
  cc?: string[];
  bcc?: string[];
  sendAs?: string;
};

export const EmailMembers = ({ from, sendAs, replyTo, to, cc, bcc }: EmailMembersProps) => {
  return (
    <StepEditorAccordion title="Sender & Receivers">
      <StepEditorAccordion.Row label={sendAs ? "Sender" : "From"}>
        <Stack vertical gap="small">
          <EmailViewer email={from} />
          {replyTo && <Title>Reply to: {replyTo}</Title>}
        </Stack>
      </StepEditorAccordion.Row>
      {sendAs && (
        <StepEditorAccordion.Row label="Send as">
          <Stack vertical gap="small">
            <EmailViewer email={sendAs} />
          </Stack>
        </StepEditorAccordion.Row>
      )}
      <StepEditorAccordion.MultiOptionRow label="To">
        <Stack vertical gap="small">
          {to.map((email: any) => (
            <EmailViewer email={email} />
          ))}
        </Stack>
      </StepEditorAccordion.MultiOptionRow>
      {cc && cc.length > 0 && (
        <StepEditorAccordion.MultiOptionRow label="Cc">
          <Stack vertical gap="small">
            {cc.map((email: any) => (
              <EmailViewer email={email} />
            ))}
          </Stack>
        </StepEditorAccordion.MultiOptionRow>
      )}
      {bcc && bcc.length > 0 && (
        <StepEditorAccordion.MultiOptionRow label="Bcc">
          <Stack vertical gap="small">
            {bcc.map((email: any) => (
              <EmailViewer email={email} />
            ))}
          </Stack>
        </StepEditorAccordion.MultiOptionRow>
      )}
    </StepEditorAccordion>
  );
};

const EmailViewer = ({ email }: { email: string }) => (
  <Stack>
    <Avatar nameOrEmail={email} />
    <Title variant="bold">{email}</Title>
  </Stack>
);
