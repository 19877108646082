import { SympaConnectForm } from "./sources/sympahr";
import { ConnectFormProps } from "./views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
import { PlanMillConnectForm } from "./sources/planmill";
import { PersonioConnectForm } from "./sources/personio";
import { CsvConnectForm } from "./sources/csv";
import { GoogleDriveCsvConnectForm } from "./sources/drivecsv";
import { HiBobConnectForm } from "./sources/hibob";
import { GoogleDriveSheetConnectForm } from "./sources/drivesheet";
import { GreenhouseConnectForm } from "./sources/greenhouse";
import { BambooHrConnectForm } from "./sources/bamboohr";
import { TeamTailorConnectForm } from "./sources/teamtailor";
import { GoogleFormsConnectForm } from "./sources/googleforms";
import { SeveraConnectForm } from "./sources/severa";
import { EmailDataSourceConnectForm } from "./sources/email";
import { CsvUploadConnectForm, CsvUploadPreviewBuilder } from "./sources/csvupload";
import { DoceboConnectForm } from "./sources/docebo";
import { JiraConnectForm } from "./sources/jira";
import { OracleHcmConnectForm } from "./sources/oraclehcm";
import { DocuSignConnectForm } from "./sources/docusign";
import { HarvestConnectForm } from "./sources/harvest";
import { LeverConnectForm } from "./sources/lever";
import { PushApiConnectForm } from "./sources/pushapi";

export type PreviewBuilderProps = {
  sourceId: string;
  onPreview: (preLoadedData?: any[]) => Promise<void>;
  onCancel: () => void;
};

export type DataSourceSpec = {
  key: string;
  title: string;
  ConnectForm: (props: ConnectFormProps) => JSX.Element;
  PreviewBuilder?: (props: PreviewBuilderProps) => JSX.Element;
  hidden?: boolean;
  reconnect?: boolean;
};

export const DataSources: DataSourceSpec[] = [
  {
    key: "bamboohr",
    title: "BambooHR",
    ConnectForm: BambooHrConnectForm
  },
  {
    key: "hibob",
    title: "Bob",
    ConnectForm: HiBobConnectForm
  },
  {
    key: "csv",
    title: "CSV from URL",
    ConnectForm: CsvConnectForm
  },
  {
    key: "csv-upload",
    title: "CSV File Upload",
    ConnectForm: CsvUploadConnectForm,
    PreviewBuilder: CsvUploadPreviewBuilder
  },
  {
    key: "docebo",
    title: "Docebo",
    ConnectForm: DoceboConnectForm,
    reconnect: true
  },
  {
    key: "drive_csv",
    title: "Google Drive CSV",
    ConnectForm: GoogleDriveCsvConnectForm
  },
  {
    key: "drivesheet",
    title: "Google Drive Sheet",
    ConnectForm: GoogleDriveSheetConnectForm
  },
  {
    key: "email",
    title: "Email",
    ConnectForm: EmailDataSourceConnectForm
  },
  {
    key: "googleforms",
    title: "Google Forms",
    ConnectForm: GoogleFormsConnectForm
  },
  {
    key: "greenhouse",
    title: "Greenhouse",
    ConnectForm: GreenhouseConnectForm
  },
  {
    key: "jira",
    title: "Jira",
    ConnectForm: JiraConnectForm
  },
  {
    key: "lever",
    title: "Lever",
    ConnectForm: LeverConnectForm,
    reconnect: true
  },
  {
    key: "teamtailor",
    title: "TeamTailor",
    ConnectForm: TeamTailorConnectForm
  },
  {
    key: "personio",
    title: "Personio",
    ConnectForm: PersonioConnectForm
  },
  {
    key: "planmill",
    title: "Planmill",
    ConnectForm: PlanMillConnectForm
  },
  {
    key: "pushapi",
    title: "Push API",
    ConnectForm: PushApiConnectForm
  },
  { key: "severa", title: "Severa", ConnectForm: SeveraConnectForm },
  {
    key: "sympahr",
    title: "Sympa HR",
    ConnectForm: SympaConnectForm
  },
  {
    key: "oraclehcm",
    title: "Oracle HCM",
    ConnectForm: OracleHcmConnectForm
  },
  {
    key: "docusign",
    title: "DocuSign",
    ConnectForm: DocuSignConnectForm,
    reconnect: true
  },
  {
    key: "harvest",
    title: "Harvest",
    ConnectForm: HarvestConnectForm
  },
  {
    key: "inari",
    title: "Inari",
    ConnectForm: () => {
      return <div />;
    },
    hidden: true
  }
];

export const getDataSourceSpec = (dataSource: string) => {
  const spec = DataSources.find(ds => ds.key === dataSource);
  if (!spec) {
    throw new Error(`No data source ${dataSource}`);
  }
  return spec;
};
