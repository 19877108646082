import {
  Category,
  EmployeeFieldSelectorPopover,
  SelectorIcon
} from "../employees/fields/EmployeeFieldSelector";
import { useMemo, useState } from "react";
import { useTags } from "../employees/hooks/useTags";
import { Button, ButtonProps, IconButton, Stack } from "@introist/react-foundation/v2";

type SingleTagSelectProps = {
  value?: string;
  onChange: (value: string, title?: string) => unknown;
  placeholder?: string;
  fieldTypes?: string[];
  tagMode?: boolean;
  buttonProps?: Partial<ButtonProps>;
  customCategories?: Category[];
  formsEnabled?: boolean;
  companyDataEnabled?: boolean;
  employeeSelectMode?: boolean;
  onClear?: () => unknown;
};

export const SingleTagSelect = ({
  value,
  onChange,
  placeholder,
  fieldTypes,
  tagMode,
  buttonProps,
  customCategories,
  formsEnabled,
  companyDataEnabled,
  employeeSelectMode,
  onClear
}: SingleTagSelectProps) => {
  const { tagTitle } = useTags();

  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const title = useMemo(() => {
    if (value) {
      const title = tagTitle(value);
      if (title !== value) return title;

      let customTitle = null;

      for (const category of customCategories ?? []) {
        const field = category.options.find(opt => opt.key === value);
        if (field) {
          customTitle = `${category.title} - ${field.title}`;
          break;
        }
      }

      return customTitle ?? value;
    }
    return placeholder ?? "Select value";
  }, [value, tagTitle, placeholder, customCategories]);

  return (
    <Stack gap="small">
      <Button
        variant="blended"
        ref={setRef}
        onClick={() => setOpen(!open)}
        startAdornment={<SelectorIcon icon="attribute" />}
        {...buttonProps}
      >
        {title}
      </Button>
      {onClear && !!value && <IconButton icon="crossSmall" onClick={onClear} />}
      <EmployeeFieldSelectorPopover
        fieldTypes={fieldTypes}
        popoverRef={ref}
        open={open}
        setOpen={setOpen}
        onSelect={onChange}
        customCategories={customCategories}
        tagMode={tagMode}
        formsEnabled={formsEnabled}
        companyDataEnabled={companyDataEnabled}
        employeeSelectMode={employeeSelectMode}
      />
    </Stack>
  );
};
