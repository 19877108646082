import { expressionToConditionGroup } from "../../components/organisms/RecursiveExpressionEditor";
import { getStringifiedRelativeDateValue } from "../../components/organisms/RecursiveExpressionEditor/components/RuleViewer/ConditionParser/ConditionValueParser";
import { Card, Stack, TextButton, Title } from "@introist/react-foundation/v2";
import { Comparators, Rule, RuleCondition } from "./schema";
import { Expression } from "@introist/introist-commons/dist";
import { ReactNode } from "react";
import React from "react";

export type TitleFn = (field: string) => string;

export const ExpressionAsRuleViewer = ({
  expression,
  ...rest
}: { expression: Expression } & Omit<RuleViewerProps, "rule">) => {
  return <RuleViewer rule={expressionToConditionGroup(expression)} {...rest} />;
};

type RuleViewerProps = {
  rule: Rule;
  onEdit?: () => unknown;
  onDelete?: () => unknown;
  header?: ReactNode;
  dark?: boolean;
  titleFn?: TitleFn;
};

export const RuleViewer = ({ rule, onEdit, onDelete, dark, header, titleFn }: RuleViewerProps) => {
  return (
    <Stack
      vertical
      gap="small"
      style={{
        padding: "var(--spacing-medium)",
        background: dark ? "var(--palette-surface-subdued)" : undefined,
        borderRadius: "var(--rounding-medium)"
      }}
    >
      <Stack gap="small">
        {header}
        <Title style={{ flex: 1 }}>{`${
          rule.mode === "and" ? "all" : "any"
        } of the following match`}</Title>
      </Stack>
      {rule.conditions.map((c, idx) => (
        <ConditionCard condition={c} titleFn={titleFn} key={`condition-card-${idx}`} />
      ))}
      {(onEdit || onDelete) && (
        <Stack gap="small" style={{ marginLeft: "auto" }}>
          {onEdit && <TextButton onClick={onEdit}>Edit</TextButton>}
          {onDelete && <TextButton onClick={onDelete}>Delete</TextButton>}
        </Stack>
      )}
    </Stack>
  );
};

const ConditionCard = ({
  condition,
  titleFn = f => f
}: {
  condition: RuleCondition | Rule;
  titleFn?: TitleFn;
}) => {
  const resolveSingleCondition = (condition: RuleCondition) => {
    return {
      title: condition.attribute,
      comparator: condition.comparator,
      values: condition.value ? [condition.value] : []
    };
  };

  const resolveGroupCondition = (condition: Rule) => {
    return {
      title: condition.conditions[0].attribute,
      comparator: condition.conditions[0].comparator,
      values: condition.conditions.map(c => c.value)
    };
  };

  const isGroup = "conditions" in condition;
  const { title, comparator, values } = isGroup
    ? resolveGroupCondition(condition as Rule)
    : resolveSingleCondition(condition as RuleCondition);

  const resolveValueTitle = (value: any) => {
    if (typeof value === "string") {
      return value;
    }

    if (typeof value === "object" && "reldate" in value) {
      return getStringifiedRelativeDateValue(value);
    }

    return "Unknown";
  };

  return (
    <Card style={{ padding: "var(--spacing-small) var(--spacing-medium)" }}>
      <Stack gap="small">
        <Title variant="bold">{titleFn(title)}</Title>
        <Title>{Comparators[comparator] ?? comparator}</Title>

        {values.length > 0 && (
          <Stack gap="xSmall">
            {values.map((value, idx) => (
              <React.Fragment key={idx}>
                <Title variant="bold">{resolveValueTitle(value)}</Title>
                {idx < values.length - 1 && <Title>or</Title>}
              </React.Fragment>
            ))}
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
