import { useCallback } from "react";
import { useEmployeeFields } from "./useEmployeeFields";
import { Tags } from "../../tags";
import { useCompanyData } from "../../companydata/useCompanyData";
import { useWorkflowFields } from "../../flows/hooks/useWorkflowFields";

export const useTags = (workflowId?: string) => {
  const { fieldTitle } = useEmployeeFields();
  const { entries } = useCompanyData();

  const { fields: workflowFields } = useWorkflowFields(workflowId ?? "", { enabled: !!workflowId });

  const tagTitle = useCallback(
    (tag: string) => {
      const path = Tags.path(tag);

      if (path.startsWith("automation.")) {
        const workflowField = (workflowFields ?? []).find(f => f.tag === path);
        if (workflowField) return "Workflow - " + workflowField.title;
        return tag;
      }

      if (path.startsWith("companydata")) {
        //eslint-disable-next-line
        const [_, entryId, field] = path.split(".");
        const entry = entries.find(e => e.id === entryId);

        if (entry?.type === "employee") return `${entry.name} - ${fieldTitle(field)}`;
        return entry?.name ?? "Unknown company data";
      }

      return fieldTitle(path);
    },
    [fieldTitle, entries, workflowFields]
  );

  return { tagTitle };
};
