import { useWorkflowFields } from "../hooks/useWorkflowFields";
import { IconName } from "@introist/react-foundation/v2";

export const useWorkflowFieldsTagList = (
  workflowId: string,
  fieldTypes?: string[],
  tagMode?: boolean,
  options?: any
) => {
  const { options: workflowFieldOptions } = useWorkflowFields(workflowId, {
    fieldTypes,
    tagMode,
    ...(options ?? {})
  });

  return {
    key: "workflow-fields",
    title: "Workflow",
    options: workflowFieldOptions,
    icon: "workflows" as IconName
  };
};
