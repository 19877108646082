import { Card, H4, Stack, Title } from "@introist/react-foundation/v2";
import { AttributeIcon } from "../../../components/atoms";
import { DataSourceMappingCard } from "./DataMappingCard";
import { OrderableList } from "../../../foundation";
import { ShortUuid } from "../../../utils/Utils";
import { SetupCard } from "../../triggers/event-triggers/editors/SetupCard";
import { RouterOutput } from "../../../services/rpc/RpcProvider";

export type DataMapping = RouterOutput["datasources"]["webhooks"]["find"]["mappings"][0];

export type DataMappingField = {
  key: string;
  title: string;
};

export const DataMappingEditor = ({
  value,
  onChange,
  sourceFields,
  sourceSelector,
  targetFields,
  blended,
  placeholder
}: {
  value: DataMapping[];
  onChange: (value: DataMapping[]) => unknown;
  sourceSelector?: (onSelected: (field: string) => unknown, value?: string | null) => JSX.Element;
  sourceFields?: DataMappingField[];
  targetFields?: DataMappingField[];
  blended?: boolean;
  placeholder?: string | null;
}) => {
  const editor = (
    <Stack vertical>
      {value.length > 0 && (
        <OrderableList
          data={value}
          onReorder={items => onChange(items)}
          renderRow={mapping => (
            <DataSourceMappingCard
              value={mapping}
              sourceSelector={sourceSelector}
              sourceFields={sourceFields}
              targetFields={targetFields}
              onChange={mapping => onChange(value.map(m => (m.id === mapping.id ? mapping : m)))}
              onRemove={() => onChange(value.filter(m => m.id !== mapping.id))}
              blended={blended}
            />
          )}
        />
      )}
      {value.length === 0 && placeholder !== null && (
        <Title style={{ alignSelf: "center", margin: "1rem 0" }}>
          {placeholder ?? "No fields mapped"}
        </Title>
      )}
      <SetupCard
        icon="arrowsLeftRight"
        title="Add mapping"
        style={{ padding: "var(--spacing-large)" }}
        onClick={() =>
          onChange([...value, { id: ShortUuid.generate(), sourceField: "", targetField: "" }])
        }
      />
    </Stack>
  );

  if (blended) return editor;

  return (
    <Card>
      <Stack vertical>
        <Stack vertical gap="small">
          <Stack gap="small">
            <AttributeIcon ghosted />
            <H4>Data mapping</H4>
          </Stack>
        </Stack>
        {editor}
      </Stack>
    </Card>
  );
};
