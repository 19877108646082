import { Button, ButtonProps, ModalV2 } from "@introist/react-foundation/v2";
import { useState } from "react";
import { FunctionEditor, CodeEditorProps } from "./FunctionEditor";

export const ButtonCodeEditor = ({
  value,
  onChange,
  onClick,
  title,
  ...props
}: CodeEditorProps & ButtonProps) => {
  const [codeEditorOpen, setCodeEditorOpen] = useState(false);
  return (
    <>
      <Button
        variant="blended"
        size="small"
        onClick={e => {
          setCodeEditorOpen(true);
          if (onClick) onClick(e);
        }}
        {...props}
      >
        Open code editor
      </Button>

      <ModalV2
        open={codeEditorOpen}
        onClose={() => setCodeEditorOpen(false)}
        fullWidth
        title={title}
      >
        <FunctionEditor value={value} onChange={onChange} />
      </ModalV2>
    </>
  );
};
