import { CompanyDataRoute } from "./CompanyDataRoute";

export const COMPANY_DATA_ROUTES = {
  companyData: {
    key: "companydata",
    path: "/companydata",
    title: "Company Data",
    element: <CompanyDataRoute />
  },
  companyDataEntry: {
    key: "companydata-entry",
    path: "/companydata/:id",
    element: <CompanyDataRoute />
  }
};
