import { SelectorIcon } from "../../employees/fields/EmployeeFieldSelector";
import {
  Button,
  ButtonProps,
  CircularLoader,
  IconButton,
  IconName,
  OptionList,
  Popover
} from "@introist/react-foundation/v2";
import { useCallback, useMemo, useState } from "react";
import { Tag, useTag, useTags } from "./useTags";
import { compact } from "lodash";

export type TagSelectorListProps = {
  onSelect: (tag: Tag) => unknown;
  ctx?: TagSelectContext;
  maxDepth?: number;
};

export type TagSelectContext = {
  workflowId?: string;
  expandRoots?: string[];
  enabledResources?: string[];
  fieldTypes?: string[];
};

export const TagSelectorList = ({ onSelect, ctx = {}, maxDepth = 3 }: TagSelectorListProps) => {
  const [path, setPath] = useState("");

  const { tags, loading } = useTags(path, ctx.workflowId);

  const { tags: expandTags } = useTags(ctx.expandRoots ?? [], ctx.workflowId, {
    enabled: !!ctx.expandRoots && path === ""
  });

  const buildTagOption = useCallback(
    (t: Tag) => ({
      key: t.tag,
      title: t.title,
      titleStyles: {
        fontWeight: t.type.startsWith("resource") ? 500 : undefined
      },
      startAdornment: <SelectorIcon icon={t.icon} />,
      endAdornment: t.type.startsWith("resource") ? <SelectorIcon icon="arrowRight" /> : undefined,
      onClick: () => {
        if (t.type.startsWith("resource")) {
          setPath(t.tag);
        } else {
          onSelect(t);
        }
      }
    }),
    [onSelect]
  );

  const options = useMemo(() => {
    return (tags ?? [])
      .filter(t => {
        const depth = path.split(".").length;
        return depth < maxDepth - 1 || !t.type.startsWith("resource");
      })
      .filter(t => {
        if (!ctx?.enabledResources) return true;
        return ctx.enabledResources.some(r => t.tag.startsWith(r));
      })
      .filter(t => {
        if (!ctx.expandRoots || path !== "") return true;
        return ctx.expandRoots.every(e => !t.tag.startsWith(e));
      })
      .filter(t => {
        if (!ctx?.fieldTypes) return true;
        return t.type.startsWith("resource") || ctx.fieldTypes.includes(t.type);
      })
      .map(t => buildTagOption(t));
  }, [tags, path, buildTagOption, ctx, maxDepth]);

  const expandOptions = useMemo(() => {
    if (!ctx.expandRoots || path !== "") return [];

    return (expandTags ?? []).map(t => buildTagOption(t));
  }, [ctx, path, expandTags, buildTagOption]);

  const back = useMemo(() => {
    if (path !== "") {
      return {
        key: "back",
        title: "Back",
        startAdornmentIcon: "arrowLeft" as IconName,
        onClick: () => {
          const parts = path.split(".");
          parts.pop();
          setPath(parts.join("."));
        }
      };
    }
    return null;
  }, [path]);

  return (
    <OptionList
      actions={compact([back])}
      options={[...options, ...expandOptions]}
      loading={loading}
      searchable
      searchPlaceholder="Search"
      clearSearchOnOptionsChange
    />
  );
};

export const TagSelector = ({
  title,
  value,
  variant = "outlined",
  ...props
}: TagSelectorListProps & {
  title?: string;
  variant?: ButtonProps["variant"];
  value?: string | null;
  wrap?: boolean;
}) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const { tag, loading: tagLoading } = useTag(value ?? "", props.ctx?.workflowId, {
    enabled: !!value
  });

  const onSelect = useCallback(
    (tag: Tag) => {
      props.onSelect(tag);
      setOpen(false);
    },
    [props]
  );

  const resolveButton = () => {
    if (!title && !value) {
      return (
        <IconButton
          icon={<SelectorIcon icon="attribute" />}
          onClick={() => setOpen(true)}
          variant={variant}
        />
      );
    }

    if (value) {
      return (
        <Button
          variant={variant}
          startAdornment={
            tagLoading ? (
              <CircularLoader fillParent size="small" />
            ) : (
              <SelectorIcon icon={tag?.icon ?? "questionMark"} />
            )
          }
          onClick={() => setOpen(true)}
        >
          {tag?.fullTitle ?? tag?.title ?? (tagLoading ? "" : "Unknown field")}
        </Button>
      );
    }

    return (
      <Button
        variant={variant}
        startAdornment={<SelectorIcon icon="attribute" />}
        onClick={() => setOpen(true)}
      >
        {title}
      </Button>
    );
  };

  return (
    <div ref={setRef}>
      {resolveButton()}
      <Popover
        referenceElement={ref}
        open={open}
        onClose={() => setOpen(false)}
        attachToRef={false}
      >
        {open && <TagSelectorList {...props} onSelect={onSelect} />}
      </Popover>
    </div>
  );
};
