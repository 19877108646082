import { CircularLoader, Modal } from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { JsonEditor } from "../../../components/templates/CodeEditor/CodeEditor";

export const AutomationFieldDataPreviewModal = ({
  automationId,
  open,
  onClose
}: {
  automationId: string;
  open: boolean;
  onClose: () => unknown;
}) => {
  return (
    <Modal
      title="Field data preview"
      open={open}
      onClose={onClose}
      maxContentHeight="70vh"
      style={{ width: "70vw" }}
    >
      {open && <AutomationFieldDataPreview automationId={automationId} />}
    </Modal>
  );
};

export const AutomationFieldDataPreview = ({ automationId }: { automationId: string }) => {
  const { data, isLoading } = api.automations.fieldset.useQuery({ id: automationId });

  if (isLoading) return <CircularLoader fillParent />;
  return <JsonEditor value={data} />;
};
