import { CircularLoader, LabelledCheckbox, ModalV2, Stack } from "@introist/react-foundation/v2";
import { api, RouterInput } from "../../../services/rpc/RpcProvider";
import {
  DataMapping,
  DataMappingEditor,
  DataMappingField
} from "../../system/data-mapping/DataMappingEditor";
import { FormFooter } from "../components/FormFooter";
import { useEffect, useMemo, useState } from "react";
import { useWebhookConfig } from "../hooks/useWebhookConfig";
import { InfoTooltip } from "../../../foundation";
import { useEmployeeFields } from "../../employees/hooks/useEmployeeFields";

export type WebhookConfigUpdate = RouterInput["datasources"]["webhooks"]["update"]["update"];

export const WebhookEditor = ({
  value,
  onChange,
  sourceFields,
  targetFields
}: {
  value: WebhookConfigUpdate;
  onChange: (value: WebhookConfigUpdate) => unknown;
  sourceFields?: DataMappingField[];
  targetFields?: DataMappingField[];
}) => {
  return (
    <Stack vertical>
      <DataMappingEditor
        sourceFields={sourceFields}
        targetFields={targetFields}
        value={(value.mappings as DataMapping[]) ?? []}
        onChange={mappings => onChange({ ...value, mappings })}
      />
      <Stack alignItems="center">
        <LabelledCheckbox
          label="Create employees"
          checked={value.create!}
          onChange={create => onChange({ ...value, create })}
        />
        <InfoTooltip tooltip="New employees will be created to Introist if webhook data can not be linked to existing employees." />
      </Stack>
      <Stack alignItems="center">
        <LabelledCheckbox
          label="Update employees"
          checked={value.update!}
          onChange={update => onChange({ ...value, update })}
        />
        <InfoTooltip tooltip="Mapped fields from webhook will be updated to employees profile in Introist." />
      </Stack>
    </Stack>
  );
};

export const WebhookEditorModal = ({
  datasourceId,
  open,
  onClose
}: {
  datasourceId: string;
  open: boolean;
  onClose: () => unknown;
}) => {
  const { config, actions } = useWebhookConfig(datasourceId);

  const [value, setValue] = useState<WebhookConfigUpdate | null>();

  const { fieldOptions: targetFields } = useEmployeeFields();

  const { data: webhookFields } = api.datasources.webhooks.fields.useQuery({ datasourceId });

  const sourceFields = useMemo(() => {
    if (!webhookFields) return [];
    return webhookFields.map(f => ({
      key: f.path,
      title: !f.title.includes(f.path) ? `${f.title} (${f.path})` : f.title,
      category: f.category
    }));
  }, [webhookFields]);

  useEffect(() => {
    setValue(config);
  }, [config]);

  return (
    <ModalV2
      maxWidth={900}
      title="Webhook configuration"
      open={open}
      onClose={onClose}
      footer={
        <FormFooter
          onCancel={() => {
            setValue(config);
            onClose();
          }}
          onSubmit={() =>
            actions.update(config!.id, value!).then(() => {
              onClose();
            })
          }
        />
      }
    >
      {!value && <CircularLoader fillParent />}
      {value && (
        <WebhookEditor
          value={value}
          onChange={setValue}
          sourceFields={sourceFields}
          targetFields={targetFields}
        />
      )}
    </ModalV2>
  );
};
