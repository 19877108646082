import { useMemo } from "react";

import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { Icon, IconButton, Select, Stack, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: var(--spacing-xSmall) var(--spacing-medium);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);

    button {
      opacity: 1;
    }
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

const avatarOrAttribute = (value?: string) => {
  if (!value || value.startsWith("U")) return <SelectorIcon icon="person" />;
  return <SelectorIcon icon="attribute" />;
};

type SlackUsersSelectProps = {
  senderId?: string;
  value: string[];
  onChange: (newValue: string[]) => unknown;
  addButtonText?: string;
  error?: boolean;
};

export const SlackUsersSelect = ({ senderId, value, onChange }: SlackUsersSelectProps) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackUsers } = slackSenderApi.listUsers(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const { fields } = useEmployeeFields();

  const options = useMemo(() => {
    const fieldOptions = (fields ?? [])
      .filter(f => ["email", "employee"].includes(f.type))
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment: <Icon name={f.type === "email" ? "atTheRate" : "personSync"} />
      }));

    const userOptions = (slackUsers ?? []).map(user => ({
      key: user.id,
      title: user.realName,
      startAdornment: <Icon name="person" />
    }));

    return [...fieldOptions, ...userOptions];
  }, [fields, slackUsers]);

  return (
    <Stack vertical gap="xSmall">
      {value.map((path: string) => (
        <StyledCard>
          <Stack style={{ width: "100%" }} gap="small">
            {avatarOrAttribute(path)}
            <Title variant="bold">{options.find(o => o.key === path)?.title ?? path}</Title>
            <RemoveButton
              icon="crossSmall"
              onClick={() => {
                onChange(value.filter(v => v !== path));
              }}
              style={{ marginLeft: "auto" }}
            />
          </Stack>
        </StyledCard>
      ))}
      <Select
        searchable
        size="small"
        element="button"
        variant="blended"
        placeholder="Add user"
        startAdornment={<SelectorIcon icon="personAdd" />}
        options={options}
        onSelect={opt => {
          onChange([...value, opt.key]);
        }}
        attachToRef={false}
        style={{ marginTop: value.length > 0 ? "var(--spacing-medium)" : 0 }}
      />
    </Stack>
  );
};
