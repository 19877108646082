import { StepEditorProps } from "../useStepEditor";
import { prefixForm } from "../../../../utils/FormUtils";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { SlackChannelSelect } from "./SlackChannelSelect";
import { SlackUserSelect } from "./SlackUserSelect";

export const SlackChannelInviteEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Details" defaultOpen>
          <StepEditorAccordion.Row label="Inviter">
            <SlackSenderSelectV2
              senderId={prefixedForm.get("senderId")}
              onSelectSender={prefixedForm.set("senderId")}
              error={prefixedForm.isError("senderId")}
              placeholder="Select inviter"
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <SlackChannelSelect
              senderId={prefixedForm.get("senderId")}
              value={prefixedForm.get("channel")}
              onChanged={prefixedForm.set("channel")}
              error={prefixedForm.isError("channel")}
              workflowId={workflowId}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="Users">
            <SlackUserSelect
              senderId={prefixedForm.get("senderId")}
              error={prefixedForm.isError("users")}
              value={prefixedForm.get("users") ?? []}
              onChange={prefixedForm.set("users")}
              workflowId={workflowId}
            />
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
