import { ActionMenu, H4, IconButton, IconName, Option, Stack } from "@introist/react-foundation/v2";
import { IconPickerIcon } from "../../../components/organisms";

export const DrawerHeader = ({
  title,
  onClose,
  icon,
  actions
}: {
  title: string;
  onClose?: () => void;
  actions?: Option[];
  icon?: IconName;
}) => {
  return (
    <Stack>
      {icon && <IconPickerIcon fallbackIcon={icon} />}
      <H4>{title}</H4>
      <Stack style={{ marginLeft: "auto" }}>
        {actions && <ActionMenu options={actions} />}
        {onClose && <IconButton icon="crossSmall" onClick={onClose} />}
      </Stack>
    </Stack>
  );
};
