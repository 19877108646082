import { Icon, Select, SelectProps } from "@introist/react-foundation/v2";
import { useAttributes } from "hooks/use-attributes";
import { useMemo } from "react";
import { useWorkflowFields } from "../hooks/useWorkflowFields";

type Props = Omit<SelectProps, "options"> & { workflowId: string };

export const TargetDateSelect = (props: Props) => {
  const attributes = useAttributes();
  const { fields } = useWorkflowFields(props.workflowId);

  const targetDateOptions = useMemo(() => {
    const attributeOptions = attributes
      .filter(a => a.type === "date")
      .map(a => ({ key: a.variable, title: a.name, category: "Employee" }));

    const workflowFieldOptions = (fields ?? [])
      .filter(f => f.type === "date")
      .map(f => ({ key: f.tag, title: f.title, category: "Workflow" }));

    return [
      { key: "immediately", title: "When enrolled" },
      ...attributeOptions,
      ...workflowFieldOptions
    ];
  }, [attributes, fields]);

  return (
    <Select
      element="button"
      variant="outlined"
      startAdornment={<Icon name="calendarTarget" />}
      options={targetDateOptions}
      {...props}
    />
  );
};
