import { Icon, IconButton, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { Avatar } from "../../../../components/molecules";
import { OptionalAttendeeButton } from "./OptionalAttendeeButton";
import { isEmail } from "../../../../utils/string-templater";
import { EmployeeFieldSelector } from "../../../employees/fields/EmployeeFieldSelector";
import { useTags } from "../../../employees/hooks/useTags";

const StyledAttendeeCard = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-small);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);
    cursor: pointer;

    .remove-btn {
      opacity: 1;
    }
  }
`;

const AvatarBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--rounding-small);
  background: var(--palette-primary-ghosted);
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

type Attendee = { value: string; optional?: boolean };

type MeetingAttendeeSelectProps = {
  value: Attendee[];
  onChange: (attendees: Attendee[]) => void;
};

export const MeetingAttendeeSelect = ({ value, onChange }: MeetingAttendeeSelectProps) => {
  const { theme } = useTheme();
  const { tagTitle } = useTags();

  return (
    <Stack vertical gap="xSmall">
      {value.map(attendee => {
        return (
          <StyledAttendeeCard key={attendee.value}>
            <Stack>
              <AvatarBackground>
                {isEmail(attendee.value) ? (
                  <Avatar nameOrEmail={attendee.value} />
                ) : (
                  <Icon
                    style={{
                      width: 14
                    }}
                    name="attribute"
                    color={theme.palette.purple.default}
                  />
                )}
              </AvatarBackground>
              <Title variant="bold">{tagTitle(attendee.value)}</Title>
            </Stack>
            <Stack>
              <OptionalAttendeeButton
                optional={attendee.optional}
                onClick={() =>
                  onChange(
                    value.map(a => {
                      if (a.value === attendee.value) {
                        return { ...a, optional: !a.optional };
                      }
                      return a;
                    })
                  )
                }
              />
              <RemoveButton
                className="remove-btn"
                icon="crossSmall"
                onClick={() => onChange(value.filter(a => a.value !== attendee.value))}
              />
            </Stack>
          </StyledAttendeeCard>
        );
      })}
      <div>
        <EmployeeFieldSelector
          onSelect={path => {
            onChange([...value, { value: path }]);
            return true;
          }}
          onAdd={path => {
            onChange([...value, { value: path }]);
            return true;
          }}
          fieldTypes={["email", "employee"]}
          variant="blended"
          startIcon="personAdd"
        >
          Add attendees
        </EmployeeFieldSelector>
      </div>
    </Stack>
  );
};
