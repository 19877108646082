export const Comparators = {
  eq: "is",
  ne: "is not",
  gt: "is greater than",
  ngt: "is not greater than",
  lt: "is less than",
  nlt: "is not less than",
  gte: "is greater than or equal",
  ngte: "is not greater than or equal",
  lte: "is less than or equal",
  nlte: "is not less than or equal",
  startswith: "starts with",
  notstartswith: "does not start with",
  endswith: "ends with",
  notendswith: "does not end with",
  includes: "includes",
  notincludes: "does not include",
  exists: "has any value",
  notexists: "does not have value",
  in: "is one of",
  nin: "is not one of"
};

export type Comparator = keyof typeof Comparators;

export type RuleCondition = {
  attribute: string;
  comparator: Comparator;
  value: any;
};

export type Rule = {
  mode: "and" | "or";
  conditions: RuleCondition[];
};
