import {
  Popover,
  Card,
  Input,
  Select,
  Stack,
  Title,
  IconButton
} from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { IconToggleButton } from "../../modules/workflows/components/IconToggleButton";
import { TimeUnits } from "../../utils/dates";

interface RelativeDateEditorProps {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onClear?: () => unknown;
}

export const TimePeriodRegex = /^(\d+)([dwm])$/;

export const TimePeriodSelector = ({
  value,
  onChange,
  placeholder,
  onClear
}: RelativeDateEditorProps) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const [amount, unit] = useMemo(() => {
    if (!value) return ["", undefined];
    const match = value.match(TimePeriodRegex);

    if (match) {
      return [match[1], match[2]];
    }
    return ["", undefined];
  }, [value]);

  const stringify = () => {
    return `${amount} ${unit ? TimeUnits[unit] : ""}`;
  };

  return (
    <>
      <IconToggleButton ref={setRef} onClick={() => setOpen(!open)}>
        {value && value !== "" ? (
          <Title variant="bold">{stringify()}</Title>
        ) : (
          <Title>{placeholder ?? "No value"}</Title>
        )}
      </IconToggleButton>
      <Popover
        referenceElement={ref}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
        attachToRef={false}
      >
        <Card elevated>
          <Stack gap="small">
            <Input
              type="number"
              size="small"
              style={{ width: "40px" }}
              variant="blended"
              value={amount}
              onChange={newAmount => onChange(`${newAmount}${unit ?? "d"}`)}
              placeholder="0"
              min={0}
            />
            <Select
              size="small"
              variant="blended"
              style={{ width: "120px" }}
              options={[
                { key: "d", title: "days" },
                { key: "w", title: "weeks" },
                { key: "m", title: "months" }
              ]}
              value={unit}
              onSelect={opt => onChange(amount + opt.key)}
              placeholder="days"
            />
            {onClear && (
              <IconButton
                variant="blended"
                icon="crossSmall"
                onClick={() => {
                  onClear();
                  setOpen(false);
                }}
              />
            )}
          </Stack>
        </Card>
      </Popover>
    </>
  );
};
