import {
  ActionMenu,
  Button,
  H4,
  Icon,
  IconName,
  Option,
  Stack,
  Title
} from "@introist/react-foundation/v2";
import { SearchInput, Skeletons } from "../../components/molecules";
import { List, NoResults } from "../../components/organisms";
import { DraggableItem, Empty, EmptyListProps, OrderableList, OrderableListProps } from "../blocks";
import { CSSProperties, ReactNode, useMemo, useState } from "react";

type HeaderProps = {
  title: string;
  iconName?: IconName;
  count?: number;
  create?: ReactNode;
  onCreate?: () => void;
  search?: { search: string; setSearch: (search: string) => void };
  actions?: Option[];
  left?: ReactNode;
  right?: ReactNode;
};

const Header = ({
  title,
  iconName,
  count,
  create,
  onCreate,
  search,
  actions,
  left,
  right
}: HeaderProps) => {
  return (
    <Stack>
      <Stack>
        {iconName && <Icon name={iconName} dimmed />}
        <H4>{title}</H4>
        {<Title>{count ? count : " "}</Title>}
        {left}
        {create}
        {!create && onCreate && (
          <Button
            variant="outlined"
            size="small"
            startAdornment={<Icon name="plus" />}
            style={{ marginLeft: "auto" }}
            onClick={onCreate}
          >
            New
          </Button>
        )}
      </Stack>
      <Stack style={{ marginLeft: "auto" }} gap="small">
        {right}
        {actions && <ActionMenu options={actions} />}
        {search && <SearchInput value={search.search} onChange={search.setSearch} />}
      </Stack>
    </Stack>
  );
};

type ListViewListProps<T> = {
  data?: T[];
  renderRow?: (item: T) => React.ReactNode;
  empty?: EmptyListProps;
  skeletonCount?: number;
  style?: CSSProperties;
};

const ListViewList = <T,>({
  data,
  renderRow,
  empty,
  skeletonCount,
  style
}: ListViewListProps<T>) => {
  if (!data) return <Skeletons.List rowCount={skeletonCount} />;
  if (data.length === 0) return <Empty {...empty} />;
  return <List style={style}>{data.map(i => (renderRow ? renderRow(i) : <div />))}</List>;
};

const ListViewOrderableList = <T extends DraggableItem>(props: OrderableListProps<T>) => {
  if (!props.data) return <Skeletons.List />;
  if (props.data && props.data.length === 0) return <NoResults />;

  return <OrderableList {...props} />;
};

const ListViewLimitedList = <T,>({
  initialRowCount,
  data,
  ...rest
}: ListViewListProps<T> & { initialRowCount: number }) => {
  const [rowCount, setRowCount] = useState(initialRowCount);

  const dataLength = data?.length ?? 0;

  const limitedData = useMemo(() => {
    if (!data) return undefined;
    return data?.slice(0, rowCount);
  }, [data, rowCount]);

  return (
    <Stack vertical>
      <ListViewList data={limitedData} {...rest} skeletonCount={rowCount} />
      {rowCount < dataLength && (
        <Button variant="blended" size="small" onClick={() => setRowCount(dataLength)}>
          <Title>Show all ({dataLength})</Title>
        </Button>
      )}
      {rowCount === dataLength && dataLength > initialRowCount && (
        <Button variant="blended" size="small" onClick={() => setRowCount(initialRowCount)}>
          <Title>Show less</Title>
        </Button>
      )}
    </Stack>
  );
};

export const ListView = {
  Header,
  List: ListViewList,
  OrderableList: ListViewOrderableList,
  LimitedList: ListViewLimitedList
};
