import { MessageEditor, MessageEditorProps } from "./MessageEditor";
import { ConditionalGroupJson } from "@introist/introist-commons/dist";
import {
  Button,
  IconButton,
  InfoCard,
  Select,
  Stack,
  Title,
  useActions
} from "@introist/react-foundation/v2";
import { useEmployeeField } from "../../modules/employees/hooks/useEmployeeFields";
import { useCallback, useMemo, useState } from "react";
import { get, isNil } from "lodash";

export type LangMessageEditorProps = Omit<MessageEditorProps, "message" | "onChange" | "extra"> & {
  value: ConditionalGroupJson;
  onChange: (langs: ConditionalGroupJson) => unknown;
};

export const LangMessageEditor = ({ value, onChange, ...rest }: LangMessageEditorProps) => {
  const { onConfirmAction } = useActions();

  const { field } = useEmployeeField("language");

  const [selectedLang, setLang] = useState(
    value.defaultValue ? "default" : get(value, "items[0].id") ?? "default"
  );

  const onCreate = useCallback(() => {
    if (selectedLang === "default") {
      onChange({ ...value, defaultValue: "" });
    } else {
      onChange({
        ...value,
        items: [
          ...(value.items ?? []),
          {
            id: selectedLang,
            title: selectedLang,
            condition: { and: [{ language: { eq: selectedLang } }] },
            value: ""
          }
        ]
      });
    }
  }, [value, selectedLang, onChange]);

  const onRemove = onConfirmAction(
    (itemId: string) => {
      if (itemId === "default") {
        onChange({ ...value, defaultValue: null });
      } else {
        const items = value.items?.filter(i => i.id !== itemId);
        onChange({ ...value, items });
      }
    },
    {
      title: "Remove language version",
      description: "Are you sure you want to remove this language version?"
    }
  );

  const langs = useMemo(() => ["default", ...(field?.options ?? [])], [field]);

  const langSelect = (itemId?: string) => {
    if (langs.length === 1) return null;

    return (
      <Stack gap="xSmall">
        <Select
          element="button"
          variant="blended"
          size="small"
          options={langs.map(lang => ({ key: lang }))}
          value={selectedLang}
          onSelect={opt => setLang(opt.key)}
          attachToRef={false}
        />
        {itemId && <IconButton icon="trash" onClick={() => onRemove(itemId)} />}
      </Stack>
    );
  };

  return (
    <div>
      {langs.map(lang => {
        if (selectedLang !== lang) return null;

        if (lang === "default" && !isNil(value.defaultValue)) {
          return (
            <MessageEditor
              key={lang}
              message={(value.defaultValue as string) ?? ""}
              onChange={message => {
                onChange({ ...value, defaultValue: message });
              }}
              extra={langSelect(lang)}
              {...rest}
            />
          );
        }

        const item = value.items?.find(i => i.id === lang);
        if (item) {
          return (
            <MessageEditor
              key={lang}
              message={(item.value as string) ?? ""}
              onChange={message => {
                const items = value.items?.map(i => {
                  if (i.id === lang) return { ...i, value: message };
                  return i;
                });
                onChange({ ...value, items });
              }}
              extra={langSelect(item.id)}
              {...rest}
            />
          );
        } else {
          return (
            <Stack vertical style={{ height: "290px" }} alignItems="center" justifyContent="center">
              <Stack gap="xSmall">
                <Title>No message for language</Title>
                {langSelect()}
              </Stack>
              {lang === "default" && (
                <InfoCard title="Default message" colorVariant="primary" style={{ width: "400px" }}>
                  <Title>Default message is used if the right language version is not found.</Title>
                </InfoCard>
              )}
              <Button style={{ margin: "0 auto" }} variant="outlined" onClick={onCreate}>
                {lang === "default" ? "Create default message" : "Create language version"}
              </Button>
            </Stack>
          );
        }
      })}
    </div>
  );
};
