import { useState } from "react";
import { Card, Icon, Title, Button, Stack, Select } from "@introist/react-foundation/v2";

import { ConditionMode } from "../../../../routes/WorkflowEditor/StepEditor/ConditionEditorModal/v2";
import { Expression } from "@introist/introist-commons/dist/object-filter";
import { RuleEditorModal } from "../../../../../../components/organisms/RuleEditorModal/RuleEditorModal";
import { ExpressionAsRuleViewer } from "../../../../../rules/RuleViewer";
import { useWorkflowFields } from "../../../../../flows/hooks/useWorkflowFields";
import { useTags } from "../../../../../employees/hooks/useTags";

type ConditionalStepSettingProps = {
  condition?: Expression;
  readOnly?: boolean;
  conditionMode?: ConditionMode;
  onDelete: () => void | unknown;
  onConditionChanged: (condition: Expression, conditionMode: string) => void | unknown;
  workflowId: string;
};

export const ConditionalStepSetting = ({
  condition,
  conditionMode = "executeif",
  onDelete,
  onConditionChanged,
  readOnly,
  workflowId,
  ...rest
}: ConditionalStepSettingProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { tagTitle } = useTags(workflowId);

  const { fields } = useWorkflowFields(workflowId);

  return (
    <Card
      style={{ background: readOnly && !condition ? "var(--palette-surface-subdued)" : undefined }}
    >
      <Stack {...rest} vertical gap="large">
        <Stack vertical gap="xSmall">
          <Stack>
            <Icon name="condition" />
            <Title variant="bold">Conditional step</Title>
          </Stack>
        </Stack>

        {condition && (
          <ExpressionAsRuleViewer
            dark
            expression={condition!}
            onEdit={!readOnly ? () => setModalOpen(true) : undefined}
            onDelete={!readOnly ? onDelete : undefined}
            titleFn={tagTitle}
            header={
              <Select
                readonly={readOnly}
                size="small"
                element="button"
                variant="blended"
                options={[
                  { key: "executeif", title: "Execute only if" },
                  { key: "cancelif", title: "Cancel if" }
                ]}
                value={conditionMode}
                onSelect={option => onConditionChanged(condition || { and: [] }, option.key)}
              />
            }
          />
        )}

        {!condition && !readOnly && (
          <Stack>
            <Button
              size="small"
              variant="blended"
              startIcon="plusSmall"
              onClick={() => setModalOpen(true)}
            >
              Add rules
            </Button>
          </Stack>
        )}
        {!condition && readOnly && (
          <Title style={{ marginLeft: 32 }}>This step does not have any conditions</Title>
        )}
      </Stack>

      <RuleEditorModal
        open={modalOpen}
        setOpen={setModalOpen}
        expression={condition ?? { and: [] }}
        onSave={value => onConditionChanged(value, conditionMode)}
        customOptions={(fields || []).map(f => ({
          variable: f.tag,
          name: f.title,
          type: f.type === "date" ? "date" : "text",
          category: "Workflow"
        }))}
      />
    </Card>
  );
};
