import { Button, Input, Stack } from "@introist/react-foundation/v2";
import { FullscreenModal } from "../FullscreenModal";
import {
  ExpressionField,
  ExpressionFieldType,
  RecursiveExpressionEditor
} from "../RecursiveExpressionEditor";
import { useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";
import { Expression } from "@introist/introist-commons/dist";
import { useEffect, useMemo, useState } from "react";

export const RuleEditorModal = ({
  open,
  setOpen,
  expression,
  onSave,
  title,
  customOptions
}: {
  open: boolean;
  setOpen: (open: boolean) => unknown;
  expression: Expression;
  onSave: (expression: Expression, title?: string) => unknown;
  title?: string;
  customOptions?: ExpressionField[];
}) => {
  const { fields } = useEmployeeFields();

  const [innerTitle, setInnerTitle] = useState(title ?? "");

  const [expressionToBeSaved, setExpressionToBeSaved] = useState<Expression>(expression);

  useEffect(() => {
    setExpressionToBeSaved(expression);
  }, [expression, setExpressionToBeSaved]);

  const options = useMemo(() => {
    const employeeOptions = (fields ?? []).map(f => ({
      variable: f.key,
      name: f.title,
      type: f.type as ExpressionFieldType
    }));

    return [...employeeOptions, ...(customOptions ?? [])];
  }, [fields, customOptions]);

  return (
    <FullscreenModal.Default
      header={{
        title: title ? (
          <Input
            variant="blended"
            value={innerTitle}
            onChange={setInnerTitle}
            style={{ width: "500px" }}
          />
        ) : (
          "Edit match rules"
        ),
        bottomBorder: true,
        onClose: () => setOpen(false)
      }}
      footer={{
        children: (
          <Stack justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => {
                setExpressionToBeSaved(expression);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClickWithLoading={async () => {
                await Promise.resolve(onSave(expressionToBeSaved, innerTitle));
                setOpen(false);
              }}
            >
              Save
            </Button>
          </Stack>
        ),
        topBorder: true
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <RecursiveExpressionEditor
        expression={expressionToBeSaved}
        fields={options}
        onChange={setExpressionToBeSaved}
        config={{
          attributeListTitle: "Create rule based on...",
          placeholderConditionCardText: "Create rule based on...",
          placeholderConditionCardDescription: (
            <Stack gap="small">
              <span>Start by selecting the employee field on the right</span>
            </Stack>
          ),
          allowToUseEmployeeFields: true
        }}
      />
    </FullscreenModal.Default>
  );
};
