import { Field, Input, Select, Stack } from "@introist/react-foundation/v2";

import { CompanyDataEntry, CompanyDataType, CompanyDataTypes } from "./useCompanyData";
import { UpsertFormProps } from "../../foundation";

export const CompanyDataForm = ({ value, onChange }: UpsertFormProps<CompanyDataEntry>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
      <Field title="Type">
        <Select
          style={{ width: "100%" }}
          options={CompanyDataTypes}
          value={value.type}
          onSelect={opt => onChange({ ...value, type: opt.key as CompanyDataType })}
        />
      </Field>
    </Stack>
  );
};
