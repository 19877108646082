import { DatasetEntryForm } from "./DatasetEntryForm";
import { Drawer, useToast } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router";
import { useState } from "react";

export const DatasetEntryDrawer = ({
  entryId,
  datasetId
}: {
  entryId?: string;
  datasetId: string;
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [hasChanges, setHasChanges] = useState(false);

  const handleClose = (force?: boolean) => {
    const doClose = () => navigate(`../`, { relative: "path" });

    if (force) doClose();
    else if (hasChanges) {
      toast.info("You have unsaved changes. Please save or discard before closing.");
    } else doClose();
  };

  return (
    <Drawer
      width="540px"
      open={!!entryId}
      onClose={() => handleClose()}
      style={{ padding: "var(--spacing-xLarge)" }}
    >
      {entryId && (
        <DatasetEntryForm
          datasetId={datasetId}
          entryId={entryId}
          onClose={() => handleClose(true)}
          onChanged={changes => setHasChanges(Object.keys(changes).length > 0)}
        />
      )}
    </Drawer>
  );
};
