import { Block } from "components/atoms";
import { api } from "services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import { Button, Select, Stack, Title } from "@introist/react-foundation/v2";
import { CreateAccountMappingModal } from "./CreateAccountMappingModal";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { DataSourceIcon } from "../../../datasources/components/DataSourceIcon";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { StepEditorProps } from "../useStepEditor";
import { prefixForm } from "../../../../utils/FormUtils";

const supportedDataSources = ["bamboohr", "drivesheet"];

export const CreateAccountStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { data: dataSources } = api.employees.dataSources.list.useQuery({});

  const { fields } = useEmployeeFields();

  const prefixedForm = prefixForm(form, stepDataPath);

  const [mappingOpen, setMappingOpen] = useState(false);

  const dataSourceOptions = useMemo(() => {
    return (dataSources || [])
      .filter(ds => supportedDataSources.includes(ds.sourceType))
      .map(ds => ({
        key: ds.id,
        title: ds.name,
        startAdornment: <DataSourceIcon size="small" dataSource={ds.sourceType} />
      }));
  }, [dataSources]);

  const selectedIcon = useMemo(() => {
    return dataSourceOptions.find(ds => ds.key === prefixedForm.get("dataSourceId"))
      ?.startAdornment;
  }, [prefixedForm, dataSourceOptions]);

  return (
    <Block compact blended>
      <Stack vertical>
        <StepEditorAccordion title="Target">
          <StepEditorAccordion.Row label="Target">
            <Select
              attachToRef={false}
              size="small"
              element="button"
              variant="blended"
              placeholder="Select target system"
              options={dataSourceOptions}
              value={prefixedForm.get("dataSourceId")}
              startAdornment={selectedIcon}
              onSelect={opt => prefixedForm.set("dataSourceId")(opt.key)}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
        <StepEditorAccordion title="Fields">
          <Stack vertical>
            {(prefixedForm.get("fields") ?? []).map((row: any) => (
              <Stack
                key={row.id}
                style={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 1fr 20px"
                }}
              >
                <Title
                  variant="bold"
                  style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {(fields ?? []).find((f: any) => f.variable === row.introistField)?.title ??
                    row.introistField}
                </Title>
                <Title style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {row.integrationField}
                </Title>
              </Stack>
            ))}
            <Button variant="blended" onClick={() => setMappingOpen(true)}>
              Edit mapping
            </Button>
          </Stack>
        </StepEditorAccordion>
        <StepEditorAccordion title="Options">
          <StepEditorAccordion.Row label="Save ID to">
            <Select
              searchable
              attachToRef={false}
              size="small"
              element="button"
              variant="blended"
              placeholder="Save ID to employee field"
              options={(fields ?? []).map(f => ({ key: f.key, title: f.title }))}
              value={prefixedForm.get("saveIdToField")}
              onSelect={opt => prefixedForm.set("saveIdToField")(opt.key)}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
        <CreateAccountMappingModal
          open={mappingOpen}
          onClose={() => setMappingOpen(false)}
          dataSourceId={prefixedForm.get("dataSourceId")}
          value={prefixedForm.get("fields")}
          onChange={fields => prefixedForm.set("fields")(fields)}
        />
      </Stack>
    </Block>
  );
};
