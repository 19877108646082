import {
  Popover,
  Card,
  Input,
  Select,
  Stack,
  Title,
  IconButton
} from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { IconToggleButton } from "../../modules/workflows/components/IconToggleButton";
import { SingleTagSelect } from "../../modules/tags/SingleTagSelect";
import { useTags } from "../../modules/employees/hooks/useTags";
import { RelativeDate } from "@introist/reldate";
import { Category } from "../../modules/employees/fields/EmployeeFieldSelector";

interface RelativeDateEditorProps {
  value?: string;
  onChange: (value: string) => void;
  referenceEnabled?: boolean;
  placeholder?: string;
  onClear?: () => unknown;
  customReferenceCategories?: Category[];
  workflowId?: string;
}

export const RelativeDateEditor = ({
  value,
  onChange,
  referenceEnabled,
  placeholder,
  onClear,
  customReferenceCategories,
  workflowId
}: RelativeDateEditorProps) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const { tagTitle } = useTags(workflowId);

  const val = useMemo(() => RelativeDate.parse(value), [value]);

  return (
    <>
      <IconToggleButton ref={setRef} onClick={() => setOpen(!open)}>
        {val.valid() ? val.stringify(tagTitle) : <Title>{placeholder ?? "No value"}</Title>}
      </IconToggleButton>
      <Popover
        referenceElement={ref}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
        attachToRef={false}
      >
        <Card elevated>
          <Stack gap="small">
            <Input
              type="number"
              size="small"
              style={{ width: "40px" }}
              variant="blended"
              value={val.amount}
              onChange={amount => onChange(val.change({ amount }).toString())}
              placeholder="0"
            />
            <Select
              size="small"
              variant="blended"
              style={{ width: "120px" }}
              options={[
                { key: "d", title: "days" },
                { key: "w", title: "weeks" },
                { key: "m", title: "months" }
              ]}
              value={val.unit}
              onSelect={opt => onChange(val.change({ unit: opt.key }).toString())}
              placeholder="days"
            />
            <Select
              size="small"
              variant="blended"
              style={{ width: "110px" }}
              options={[
                { key: "-", title: "before" },
                { key: "+", title: "after" }
              ]}
              value={val.side}
              onSelect={opt => onChange(val.change({ side: opt.key }).toString())}
              placeholder="after"
            />
            {referenceEnabled && (
              <SingleTagSelect
                placeholder="Select reference"
                value={val.reference}
                onChange={reference => onChange(val.change({ reference }).toString())}
                fieldTypes={["date"]}
                customCategories={customReferenceCategories}
              />
            )}
            {onClear && <IconButton variant="blended" icon="crossSmall" onClick={onClear} />}
          </Stack>
        </Card>
      </Popover>
    </>
  );
};
