import { StepEditorProps } from "../useStepEditor";
import { prefixForm } from "../../../../utils/FormUtils";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { Toggle } from "@introist/react-foundation/v2";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";

export const SlackChannelCreateEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Details" defaultOpen>
          <StepEditorAccordion.Row label="Creator">
            <SlackSenderSelectV2
              senderId={prefixedForm.get("senderId")}
              onSelectSender={prefixedForm.set("senderId")}
              error={prefixedForm.isError("senderId")}
              placeholder="Select creator"
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <FieldAwareInput
              size="small"
              onChange={prefixedForm.set("channelName")}
              value={prefixedForm.get("channelName")}
              workflowId={workflowId}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Private">
            <Toggle
              checked={prefixedForm.get("privateChannel")}
              onChange={prefixedForm.set("privateChannel")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
