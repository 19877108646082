import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Icon, Select, Stack } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { useEffect } from "react";
import { SlackChannelSelect } from "./SlackChannelSelect";
import { SlackUserSelect } from "./SlackUserSelect";
import { SingleTagSelect } from "../../../tags/SingleTagSelect";
import { useWorkflowFieldsTagList } from "../../fields/WorkflowFieldTagList";
import { LangMessageEditor } from "../../../../components/editors/LangMessageEditor";

export const SlackQuestionMessageEditor = ({ form, stepDataPath, workflowId }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const messageError = prefixedForm.error("message");

  const workflowFieldTagList = useWorkflowFieldsTagList(workflowId);

  useEffect(() => {
    const targetType = prefixedForm.get("targetType");
    if (!["channel", "users"].includes(targetType)) {
      prefixedForm.set("targetType")("users");
    }
  }, [prefixedForm]);

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Sender & Recipients" defaultOpen>
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={prefixedForm.get("senderId")}
              onSelectSender={prefixedForm.set("senderId")}
              error={prefixedForm.isError("senderId")}
            />
          </StepEditorAccordion.Row>
          <Stack vertical gap="small">
            <StepEditorAccordion.Row label="Sending to">
              <Select
                element="button"
                variant="blended"
                options={[
                  { key: "users", title: "Users", startAdornmentIcon: "persons" },
                  { key: "channel", title: "Channel", startAdornmentIcon: "hashtag" }
                ]}
                value={prefixedForm.get("targetType")}
                onSelect={opt => prefixedForm.set("targetType")(opt.key)}
                error={prefixedForm.isError("targetType")}
                placeholder="Select users or channel"
                attachToRef={false}
                searchable
                startAdornment={
                  <Icon name={prefixedForm.get("targetType") === "users" ? "persons" : "hashtag"} />
                }
              />
            </StepEditorAccordion.Row>
            {prefixedForm.get("targetType") === "users" && (
              <StepEditorAccordion.MultiOptionRow label="">
                <SlackUserSelect
                  senderId={prefixedForm.get("senderId")}
                  error={prefixedForm.isError("users")}
                  value={prefixedForm.get("users") ?? []}
                  onChange={users => {
                    prefixedForm.set("users")(users);
                    prefixedForm.set("channel")(undefined);
                  }}
                  workflowId={workflowId}
                />
              </StepEditorAccordion.MultiOptionRow>
            )}
            {prefixedForm.get("targetType") === "channel" && (
              <StepEditorAccordion.Row label="">
                <SlackChannelSelect
                  senderId={prefixedForm.get("senderId")}
                  value={prefixedForm.get("channel")}
                  onChanged={channel => {
                    prefixedForm.set("channel")(channel);
                    prefixedForm.set("users")([]);
                  }}
                  error={prefixedForm.isError("channel")}
                />
              </StepEditorAccordion.Row>
            )}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <LangMessageEditor
              value={prefixedForm.get("langs")}
              onChange={prefixedForm.set("langs")}
              error={!!messageError}
              tags={{
                workflowId
              }}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Collected Information">
          <StepEditorAccordion.Row label="Field">
            <SingleTagSelect
              onChange={prefixedForm.set("field")}
              value={prefixedForm.get("field")}
              companyDataEnabled={false}
              formsEnabled={false}
              customCategories={[workflowFieldTagList]}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
