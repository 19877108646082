import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

type Params = RouterInput["triggers"]["find"];

export type TriggerEvent = NonNullable<RouterOutput["triggers"]["find"]["triggerEvent"]>;

export type TriggerUpdate = RouterInput["triggers"]["update"]["updates"];

export const useTrigger = (params: Params, options?: any) => {
  const { data: trigger } = api.triggers.find.useQuery(params, options);

  return { trigger };
};

type HistoryParams = RouterInput["triggers"]["events"]["history"];

export const useTriggerHistory = (params: HistoryParams) => {
  const { data: history } = api.triggers.events.history.useQuery(params);

  return { history };
};

export const useTriggerActions = () => {
  const toast = useToast();

  const updateOp = api.triggers.update.useMutation();
  const archiveOp = api.triggers.archive.useMutation();
  const deleteOp = api.triggers.delete.useMutation();

  const apiUtils = api.useContext();
  const listOp = apiUtils.triggers.list;
  const findOp = apiUtils.triggers.find;

  const update = useCallback(
    async (id: string, updates: TriggerUpdate) => {
      await updateOp
        .mutateAsync({ id, updates })
        .then(async () => {
          await findOp.refetch({ id });
        })
        .catch(e => {
          toast.error("Failed to save changes");
          throw e;
        });
    },
    [updateOp, findOp, toast]
  );

  const archive = useCallback(
    async (id: string) => {
      return archiveOp
        .mutateAsync({ id })
        .then(async () => {
          toast.success("Trigger archived");
          await listOp.invalidate();
        })
        .catch(e => {
          toast.error("Failed to archive trigger");
          throw e;
        });
    },
    [listOp, archiveOp, toast]
  );

  const remove = useCallback(
    async (id: string) => {
      return deleteOp
        .mutateAsync({ id })
        .then(async () => {
          toast.success("Trigger deleted");
          await listOp.invalidate();
        })
        .catch(e => {
          toast.error("Failed to delete trigger");
          throw e;
        });
    },
    [listOp, deleteOp, toast]
  );

  return { update, archive, remove };
};

export const useTriggerEvents = () => {
  const { data: availableEvents } = api.triggers.events.events.useQuery({});

  return { availableEvents };
};
