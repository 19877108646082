export type DateFormat = string;
export const DateFormats = {
  time: "HH:mm" as DateFormat,
  date: {
    withDay: {
      short: "ddd DD.MM." as DateFormat,
      long: "ddd DD.MM.YYYY" as DateFormat
    },
    withMonth: {
      short: "MMM DD" as DateFormat,
      long: "MMM DD, YYYY" as DateFormat
    },
    short: "DD.MM." as DateFormat,
    long: "DD.MM.YYYY" as DateFormat
  },
  datetime: {
    withDay: {
      short: "ddd DD.MM. HH:mm" as DateFormat,
      long: "ddd DD.MM.YYYY HH:mm" as DateFormat
    },
    withMonth: {
      short: "MMM DD, YY HH:mm" as DateFormat,
      long: "MMM DD, YYYY HH:mm" as DateFormat
    },
    short: "DD.MM. HH:mm" as DateFormat,
    long: "DD.MM.YYYY HH:mm" as DateFormat
  }
};

export const IntroistDateTimeFormats = {
  date: "YYYY-MM-DD",
  time: "HH:mm"
};

export const TimeUnits: { [i: string]: string } = {
  d: "days",
  w: "weeks",
  m: "months"
};
