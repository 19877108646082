import styled from "styled-components";
import { Card } from "@introist/react-foundation/v2";

export const HoverableCard = styled(Card)`
  box-shadow: none;
  transition-property: transform, box-shadow;
  transition-duration: 140ms;
  transition-timing-function: ease;

  :hover {
    transform: translateY(-1px);
    box-shadow: rgba(14, 24, 29, 0.1) 0px 4px 12px -2px, rgba(14, 24, 29, 0.3) 0px 4px 12px -8px;
  }

  &:hover button {
    visibility: visible !important;
  }
`;
