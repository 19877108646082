import { Popover, OptionList } from "@introist/react-foundation/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { useRef, useState, useMemo } from "react";
import { api } from "services/rpc/RpcProvider";
import { SelectorIcon } from "../../../../../employees/fields/EmployeeFieldSelector";

export const CompanyEventSelector = ({
  companyEventId,
  onChange
}: {
  companyEventId: string;
  onChange: (value: string) => unknown;
}) => {
  const popoverRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const { data: companyEvents } = api.companyEvents.list.useQuery({});

  const eventOptions = useMemo(() => {
    return (
      companyEvents?.map(event => ({
        key: event.id,
        title: event.title,
        onClick: () => {
          onChange(event.id);
          setOpen(false);
        }
      })) ?? []
    );
  }, [companyEvents, onChange]);

  const selectedEvent = useMemo(() => {
    return companyEvents?.find(_ => _.id === companyEventId);
  }, [companyEventId, companyEvents]);

  return (
    <>
      <IconToggleButton
        size="small"
        startAdornment={<SelectorIcon icon="events" />}
        ref={popoverRef}
        active={open}
        onClick={() => setOpen(!open)}
      >
        {selectedEvent?.title ?? "Select event"}
      </IconToggleButton>

      <Popover referenceElement={popoverRef.current} open={open} onClose={() => setOpen(false)}>
        <OptionList selected={companyEventId} searchable options={eventOptions} />
      </Popover>
    </>
  );
};
