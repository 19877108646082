import {
  Button,
  Card,
  IconButton,
  Popover,
  Stack,
  Title,
  Toggle
} from "@introist/react-foundation/v2";
import { useState } from "react";
import { useTrigger, useTriggerActions } from "../hooks/useTrigger";
import { InfoTooltip } from "../../../foundation";
import { SelectorIcon } from "../../employees/fields/EmployeeFieldSelector";
import { preprocessFnTemplate } from "../hooks/useTriggers";
import { CodeEditorModal } from "../../../components/templates/CodeEditor";

export const EventTriggerSettings = ({ triggerId }: { triggerId: string }) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const [preprocessEditOpen, setPreprocessEditOpen] = useState<boolean>(false);

  const { trigger } = useTrigger({ id: triggerId });
  const { update } = useTriggerActions();

  return (
    <div ref={setRef}>
      <IconButton variant="blended" icon="more" onClick={() => setOpen(true)} />
      <Popover
        closeOnContentClick={false}
        open={open}
        referenceElement={ref}
        onClose={() => setOpen(false)}
        placement="bottom-end"
      >
        <Card>
          <Stack vertical>
            <Stack>
              <Toggle
                checked={trigger.startAsDraft}
                onChange={checked => update(triggerId, { startAsDraft: checked })}
              />
              <Title>Start automation as draft</Title>
            </Stack>

            <Stack>
              <Toggle
                checked={trigger.mergeActiveAutomation ?? false}
                onChange={checked => update(triggerId, { mergeActiveAutomation: checked })}
              />
              <Title>Merge active automation</Title>
              <InfoTooltip tooltip="If there is an active automation for employee, do not start a new one but consider trigger as success." />
            </Stack>
            <Button
              variant="blended"
              startAdornment={<SelectorIcon icon="attributeGroup" />}
              onClick={() => {
                setOpen(false);
                setPreprocessEditOpen(true);
              }}
            >
              Edit automation data
            </Button>
          </Stack>
        </Card>
      </Popover>
      <CodeEditorModal
        id={`${trigger.id}-event`}
        title="Trigger event preprocess"
        value={trigger.preprocessFn ?? preprocessFnTemplate}
        onSave={async code => {
          await update(trigger.id, { preprocessFn: code });
          setPreprocessEditOpen(false);
        }}
        onRemove={() => update(trigger.id, { preprocessFn: null })}
        open={preprocessEditOpen}
        onClose={() => setPreprocessEditOpen(false)}
      />
    </div>
  );
};
