import {
  DateInput,
  Icon,
  IconButton,
  Input,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { DatasetField } from "../../index";

type DatasetEntryFieldProps = {
  field: DatasetField;
  value: string | null;
  onChange: (val: string | null) => void;
  hasChanges?: boolean;
  error?: string;
  onClear?: () => void;
  autoFocus?: boolean;
};

export const DatasetEntryField = ({
  field,
  value,
  onChange,
  hasChanges,
  error,
  onClear,
  autoFocus
}: DatasetEntryFieldProps) => {
  const { theme } = useTheme();

  const resolveField = () => {
    const fieldStyles = {
      flex: 1,
      background: hasChanges && !error ? theme.palette.surface.subdued : undefined
    };

    if (field.type === "date") {
      return (
        <DateInput
          size="small"
          variant="blended"
          style={fieldStyles}
          inputStyles={{ color: theme.palette.foreground.default }}
          endAdornment={<Icon name="calendar" color={theme.palette.foreground.dimmed} />}
          value={value ?? undefined}
          displayFormat={"DD.MM.yyyy"}
          onChange={val => onChange(val ?? null)}
          error={!!error}
          placeholder="-"
          autoFocus={autoFocus}
        />
      );
    }

    return (
      <Input
        size="small"
        type={field.type === "number" ? "number" : "text"}
        variant="blended"
        style={fieldStyles}
        inputStyles={{
          color: theme.palette.foreground.default
        }}
        value={value ?? ""}
        onChange={val => onChange(val)}
        error={!!error}
        placeholder="-"
        autoFocus={autoFocus}
      />
    );
  };

  return (
    <div style={{ flex: 3 }}>
      <Stack>
        <div style={{ flex: 1 }}>{resolveField()}</div>
        {onClear && <IconButton icon="crossSmall" onClick={onClear} dimmed />}
      </Stack>
      {error && (
        <Title
          color={theme.palette.danger.default}
          style={{ marginTop: theme.spacing.small, fontSize: 12 }}
        >
          {error}
        </Title>
      )}
    </div>
  );
};
