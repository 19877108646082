import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { IconButton, Stack, Title } from "@introist/react-foundation/v2";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { useDatasetEntry } from "../../../datasets/hooks/useDataset";

export const DatasetEntryCreateViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  const executionData = step?.executionData as any;

  const { entry } = useDatasetEntry(executionData?.entryId, {
    enabled: !!executionData?.entryId
  });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <Block blended compact>
      <StepEditorAccordion title="Data">
        <StepEditorAccordion.Row label="Dataset">
          <Stack>
            <SelectorIcon icon="files" />
            <Title variant="bold">{resolvedData.datasetTitle ?? "Unknown data set"}</Title>
          </Stack>
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Entry">
          <Stack>
            <SelectorIcon icon="file" />
            <Title variant="bold">{entry ? entry.title : "Unknown entry"}</Title>
            <IconButton
              icon="moveTo"
              onClick={() => window.open(`/datasets/${entry.datasetId}/${entry.id}`, "_blank")}
              dimmed
              style={{ marginLeft: "auto" }}
            />
          </Stack>
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
