import { isEmpty } from "lodash";

import { DataForm } from "@introist/react-foundation/v2";
import { WebhookStepEditor } from "modules/workflows/routes/WorkflowEditor/StepEditor/WebhookStepEditor";
import { InviteStepEditor } from "../../workflows/routes/WorkflowEditor/StepEditor/InviteStepEditor";
import { EmailFormJourneyStepEditor } from "./EmailFormJourneyStepEditor";
import { EmailTaskJourneyStepEditor, SlackTaskJourneyStepEditor } from "./tasks";
import { isActiveJourneyStep, isUpdatableJourneyStep, isScheduledJourneyStep } from "../util";
import { JourneyStepV2, StepTypesV2 } from "services/rpc/RpcProvider";
import { EventStepEditor } from "modules/workflows/routes/WorkflowEditor/StepEditor/EventStepEditor";
import { WorkflowTriggerEditor } from "./WorkflowTriggerEditor/WorkflowTriggerEditor";
import { SlackStepEditorV2 } from "modules/workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2";
import { IntroistDateTime } from "@introist/introist-commons/dist";
import { FreshserviceOnboardingEditor } from "modules/workflows/routes/WorkflowEditor/StepEditor/FreshserviceOnboarding";
import { AttendToEventJourneyEditor } from "./AttendToEvent";
import { SlackQuestionJourneyStepEditor } from "./slack/SlackQuestionJourneyStepEditor";
import { SlackQuestionEditor } from "../../workflows/routes/WorkflowEditor/StepEditor/SlackQuestionEditor";
import { EmployeeSelectJourneyStepEditor } from "./EmployeeSelectJourneyStepEditor";
import { WorkflowTriggerStepEditorV3 } from "../../workflows/routes/WorkflowEditor/StepEditor/WorkflowTriggerStepEditorV3";
import { WorkflowStepTypes } from "../../flows/stepTypes";

export const getEditorForStepType = (
  workflowId: string,
  form: DataForm<JourneyStepV2>,
  attributes: any,
  isJourney?: boolean,
  onUpdate?: () => Promise<void>
) => {
  const stepDef = WorkflowStepTypes.find(st => st.stepType === form.data.stepType);

  if (["draft", "scheduled"].includes(form.data.status) && stepDef?.Editor) {
    return <stepDef.Editor form={form} stepDataPath="previewData." workflowId={workflowId} />;
  }

  const status = form.data.status;
  const activeStep = isActiveJourneyStep(form.data);

  const hasJourneyData = !!form.get("journeyData");

  const isDraft = form.data.status === "draft";

  const isUpdatable = isUpdatableJourneyStep(form.data);

  const getDataToUse = () => {
    if (!activeStep && hasJourneyData && !isDraft) return "journeyData.";
    return "previewData.";
  };

  if (isEmpty(form.data)) return;

  if (form.data.stepType === StepTypesV2.Enum.slack) {
    return (
      <SlackStepEditorV2
        form={form}
        formPrefix={getDataToUse()}
        readOnly={!isUpdatable}
        journeyAttributes={attributes}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.enroll)
    return <WorkflowTriggerEditor form={form} readOnly={!isUpdatable} />;

  if (form.data.stepType === StepTypesV2.Enum.event) {
    const isScheduled = isScheduledJourneyStep(
      form.data.status,
      form.data.start as IntroistDateTime
    );
    return (
      <EventStepEditor
        isJourneyEditor={isJourney && isScheduled}
        form={form}
        formPrefix={getDataToUse()}
        readOnly={form.data.status !== "draft"}
        enableSyncStatus={isJourney}
        onUpdate={onUpdate}
        attendees={form.get("journeyData.attendees")}
        journeyAttributes={attributes}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.webhook) {
    return <WebhookStepEditor form={form} readonly={true} formPrefix={getDataToUse()} />;
  }

  if (form.data.stepType === StepTypesV2.Enum.invite) {
    return (
      <InviteStepEditor
        form={form}
        formPrefix={hasJourneyData ? "journeyData." : "previewData."}
        mode={"journey"}
        readonly={!isUpdatable}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.emailtask) {
    return (
      <EmailTaskJourneyStepEditor
        form={form}
        readOnly={!["draft", "scheduled", "failing"].includes(status)}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.emailform) {
    return (
      <EmailFormJourneyStepEditor form={form} readOnly={!["draft", "scheduled"].includes(status)} />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.slacktask) {
    return (
      <SlackTaskJourneyStepEditor
        form={form}
        readOnly={!["draft", "scheduled", "failing"].includes(status)}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.freshserviceonboarding) {
    return (
      <FreshserviceOnboardingEditor
        form={form}
        formPrefix={getDataToUse()}
        readOnly={["completed", "canceled"].includes(status)}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.eventattend) {
    return (
      <AttendToEventJourneyEditor
        form={form}
        formPrefix={getDataToUse()}
        readOnly={["completed", "delivered"].includes(status)}
      />
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.slackquestion) {
    const isEditable = ["draft", "scheduled"].includes(form.data.status);

    if (isEditable) {
      return <SlackQuestionEditor form={form} formPrefix="previewData." />;
    }

    return (
      <>
        <SlackQuestionJourneyStepEditor
          questionId={form.get("executionData")?.questionId}
          index={3}
          step={form.data}
        />
      </>
    );
  }

  if (form.data.stepType === StepTypesV2.Enum.employeeselect) {
    return <EmployeeSelectJourneyStepEditor form={form} />;
  }

  if (form.data.stepType === StepTypesV2.Enum.workflowtrigger) {
    const prefix = getDataToUse();
    return (
      <WorkflowTriggerStepEditorV3
        form={form}
        formPrefix={prefix}
        readOnly={prefix !== "previewData."}
      />
    );
  }
};
