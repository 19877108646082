import styled from "styled-components";
import { IconName, Layout } from "@introist/react-foundation/v2";
import { Expression } from "@introist/introist-commons/dist";
import useMeasure from "react-use-measure";

import { ExpressionEditorContextProvider, useExpressionEditorContext } from "./context";
import { RecursiveConditionEditor } from "./components/RecursiveConditionEditor";
import { ReactNode } from "react";
import { AttributeList } from "../AttributeList";
import { ConditionHeader } from "./components/ConditionHeader";
import { ConditionViewer } from "./components/ConditionViewer";

export type ExpressionFieldType = "text" | "date" | "select";

export type ExpressionField = {
  variable: string;
  name: string;
  type: ExpressionFieldType;
  options?: string[];
  category?: string;
};

export type RescursiveExperssionEditorConfig = {
  topContent?: ReactNode;
  titleStartIcon?: IconName;
  title?: ReactNode;
  description?: ReactNode;
  placeholderConditionCardText?: ReactNode;
  placeholderConditionCardDescription?: ReactNode;
  comparisonModeStartText?: string;
  attributeListTitle?: string;
  allowToUseEmployeeFields?: boolean;
};

type RecursiveExpressionEditorProps = {
  expression?: Expression;
  integrationId?: string;
  fields: ExpressionField[];
  onChange: (expression: Expression) => void;
  hideComparisonMode?: boolean;
  conditionLimit?: number;
  hideEditorSection?: boolean;
  config?: RescursiveExperssionEditorConfig;
};

const StyledRecursiveExpressionEditor = styled.div`
  display: grid;
  grid-template-columns: auto 25rem;
  overflow: hidden;
  height: 100%;
`;

const ConditionTree = styled(Layout.Group)`
  padding: var(--spacing-xxLarge);
  overflow: auto;
`;

const AttributeListWrapper = styled.div`
  display: grid;
  flex: 1;
  overflow: hidden;
  padding: var(--spacing-xxLarge);
  box-sizing: border-box;
  border-left: 1px solid var(--palette-border-subdued);
`;

export const RecursiveExpressionEditorComponent = ({
  integrationId,
  onChange,
  config,
  fields,
  conditionLimit,
  hideEditorSection,
  hideComparisonMode,
  ...rest
}: RecursiveExpressionEditorProps) => {
  const [heightRef, { height }] = useMeasure();

  const { selectedConditionId, createCondition } = useExpressionEditorContext();

  return (
    <StyledRecursiveExpressionEditor {...rest}>
      <ConditionTree vertical gap="xxLarge">
        <ConditionHeader
          topContent={config?.topContent}
          titleStartIcon={config?.titleStartIcon}
          title={config?.title}
          description={config?.description}
        />
        {!hideEditorSection && (
          <ConditionViewer
            config={config}
            hideComparisonMode={hideComparisonMode}
            fields={fields}
            conditionLimit={conditionLimit}
          />
        )}
      </ConditionTree>

      {!hideEditorSection && (
        <AttributeListWrapper ref={heightRef}>
          {selectedConditionId && (
            <RecursiveConditionEditor
              maxHeight={height - 64}
              fields={fields}
              conditionId={selectedConditionId}
              allowToUseEmployeeFields={config?.allowToUseEmployeeFields ?? false}
            />
          )}
          {!selectedConditionId && (
            <AttributeList
              fields={fields}
              searchable
              maxHeight={height}
              title={config?.attributeListTitle}
              onFieldClick={createCondition}
            />
          )}
        </AttributeListWrapper>
      )}
    </StyledRecursiveExpressionEditor>
  );
};

export const RecursiveExpressionEditor = ({ ...props }: RecursiveExpressionEditorProps) => {
  return (
    <ExpressionEditorContextProvider expression={props.expression} onChange={props.onChange}>
      <RecursiveExpressionEditorComponent {...props} />
    </ExpressionEditorContextProvider>
  );
};
