import { PanelLayout, TopBarRoute } from "components/templates";
import { useNavigate, useParams } from "react-router";
import { api } from "services/rpc/RpcProvider";
import { TriggerLog, TriggerPanel, TriggerPreview } from "../containers";
import { TabBar } from "components/molecules";
import { useState } from "react";
import { Stack } from "@introist/react-foundation/v2";
import { Trigger } from "../hooks/useTriggers";

export const RuleTriggerRoute = () => {
  const navigate = useNavigate();
  const { triggerId } = useParams();

  const trigger = api.triggers.find.useQuery({ id: triggerId! }, { enabled: !!triggerId });

  const [currentTab, setCurrentTab] = useState("trigger-log");

  return (
    <TopBarRoute
      paths={[
        { key: "triggers", title: "Triggers", onClick: () => navigate("/triggers") },
        { key: "trigger", title: trigger?.data?.name || "" }
      ]}
      withoutContentPadding
    >
      <PanelLayout.View>
        <PanelLayout.Grid>
          <PanelLayout.Content>
            <Stack vertical gap="xLarge">
              <div style={{ marginRight: "auto" }}>
                <TabBar
                  tabs={[
                    {
                      id: "trigger-log",
                      title: "Automations",
                      iconName: "trigger"
                    },
                    {
                      id: "trigger-preview",
                      title: "Preview",
                      iconName: "preview"
                    }
                  ]}
                  activeTab={currentTab}
                  onTabChange={t => setCurrentTab(t)}
                />
              </div>

              {currentTab === "trigger-log" && <TriggerLog triggerId={triggerId!} />}
              {currentTab === "trigger-preview" && triggerId && (
                <TriggerPreview triggerId={triggerId} />
              )}
            </Stack>
          </PanelLayout.Content>
          <PanelLayout.Panel>
            <TriggerPanel trigger={trigger?.data as Trigger} loading={trigger.isLoading} />
          </PanelLayout.Panel>
        </PanelLayout.Grid>
      </PanelLayout.View>
    </TopBarRoute>
  );
};
