import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

export type WebhookConfig = RouterOutput["datasources"]["webhooks"]["find"];
export type WebhookConfigUpdate = RouterInput["datasources"]["webhooks"]["update"]["update"];

export const useWebhookConfig = (datasourceId: string) => {
  const { data: config } = api.datasources.webhooks.find.useQuery({ datasourceId });

  return { config, actions: useWebhookConfigActions() };
};

export const useWebhookConfigActions = () => {
  const toast = useToast();

  const updateOp = api.datasources.webhooks.update.useMutation();

  const apiUtils = api.useContext();

  const findOp = apiUtils.datasources.webhooks.find;

  const update = useCallback(
    async (id: string, update: WebhookConfigUpdate) => {
      return updateOp
        .mutateAsync({ id, update })
        .then(async () => {
          await findOp.refetch();
          toast.success("Webhook config updated");
        })
        .catch(err => {
          toast.error("Failed to update");
          throw err;
        });
    },
    [findOp, updateOp, toast]
  );

  return { update };
};
