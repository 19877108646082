import { vscodeLightInit } from "@uiw/codemirror-theme-vscode";
import { javascript } from "@codemirror/lang-javascript";
import CodeMirror, { ReactCodeMirrorProps, EditorView } from "@uiw/react-codemirror";
import { useTheme } from "@introist/react-foundation/v2";
import { json } from "@codemirror/lang-json";

export const CodeEditor = (props: ReactCodeMirrorProps) => {
  const { theme } = useTheme();

  return (
    <CodeMirror
      theme={vscodeLightInit({
        settings: {
          background: theme.palette.surface.dimmed,
          fontSize: theme.typography.size.title
        }
      })}
      basicSetup={{
        lineNumbers: false,
        foldGutter: false
      }}
      extensions={[javascript(), EditorView.lineWrapping]}
      style={{
        borderRadius: 8,
        height: "100%",
        overflowY: "auto"
      }}
      {...props}
    />
  );
};

export const JsonEditor = ({
  value,
  ...props
}: Omit<ReactCodeMirrorProps, "value"> & { value?: string | object }) => {
  const { theme } = useTheme();

  return (
    <CodeMirror
      theme={vscodeLightInit({
        settings: {
          background: theme.palette.surface.dimmed,
          fontSize: theme.typography.size.title
        }
      })}
      basicSetup={{
        lineNumbers: false
      }}
      extensions={[json(), EditorView.lineWrapping]}
      style={{
        borderRadius: 8,
        height: "100%",
        overflowY: "auto"
      }}
      value={typeof value === "string" ? value : JSON.stringify(value ?? {}, null, 2)}
      {...props}
    />
  );
};
