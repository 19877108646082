import { Icon, OptionList, useTheme } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { CompanyDataEntry, useCompanyData } from "./useCompanyData";
import { SelectorIcon } from "../employees/fields/EmployeeFieldSelector";
import { EmployeeFieldSelectList } from "../employees/fields/EmployeeFieldSelectList";

export const CompanyDataSelectorList = ({
  onSelect,
  onBack,
  fieldTypes
}: {
  onSelect: (path: string, title: string) => void;
  onBack: () => void;
  fieldTypes?: string[];
}) => {
  const { theme } = useTheme();

  const { entries } = useCompanyData();

  const [selectedEntry, setSelectedEntry] = useState<CompanyDataEntry | undefined>();

  const options = useMemo(() => {
    if (!selectedEntry) {
      return (entries ?? [])
        .filter(r => !fieldTypes || fieldTypes.includes(r.type))
        .map(r => ({
          key: r.id,
          title: r.name,
          startAdornment: <SelectorIcon icon={r.icon} />,
          onClick: () => {
            if (r.type === "employee") setSelectedEntry(r);
            else onSelect(`companydata.${r.id}`, r.name);
          }
        }));
    }

    return [];
  }, [entries, onSelect, selectedEntry, fieldTypes]);

  if (selectedEntry) {
    return (
      <EmployeeFieldSelectList
        onSelect={(path, title) =>
          onSelect(`companydata.${selectedEntry.id}.${path}`, `${selectedEntry.name} - ${title}`)
        }
        onBack={() => setSelectedEntry(undefined)}
        title={selectedEntry?.name}
        disableLinkedEmployee
        fieldTypes={fieldTypes}
      />
    );
  }

  return (
    <OptionList
      actions={[
        {
          key: "back",
          title: "Company Data",
          startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
          onClick: onBack
        }
      ]}
      searchable
      options={options}
      clearSearchOnOptionsChange
    />
  );
};
