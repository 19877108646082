import { Button, Stack } from "@introist/react-foundation/v2";
import { ReactNode } from "react";

export const FormFooter = ({
  onCancel,
  onSubmit,
  left,
  cancel = "Cancel",
  submit = "Save",
  submitDisabled,
  slim
}: {
  onCancel: VoidFunction;
  onSubmit: () => Promise<unknown>;
  left?: ReactNode;
  cancel?: string;
  submit?: string;
  submitDisabled?: boolean;
  slim?: boolean;
}) => {
  return (
    <Stack justifyContent="flex-end" style={{ marginTop: slim ? 0 : "1rem" }} gap="medium">
      {left && <div style={{ marginRight: "auto" }}>{left}</div>}

      <Button variant="outlined" onClick={onCancel}>
        {cancel}
      </Button>
      <Button onClickWithLoading={onSubmit} disabled={submitDisabled}>
        {submit}
      </Button>
    </Stack>
  );
};
