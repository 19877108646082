import { ContentCard, DragHandle, OrderableList, UpsertModal } from "../../../foundation";
import {
  useWorkflowFieldActions,
  useWorkflowFields,
  WorkflowField,
  WorkflowFieldCreate
} from "../hooks/useWorkflowFields";
import { Button, Card, IconButton, Stack, Title, useActions } from "@introist/react-foundation/v2";
import { FieldTypeIcon } from "../../employees/components/FieldTypeIcon";
import { useState } from "react";
import { WorkflowFieldForm } from "./WorkflowFieldForm";

export const WorkflowFieldsEditor = ({ workflowId }: { workflowId: string }) => {
  const { onConfirmAction } = useActions();

  const { fields } = useWorkflowFields(workflowId);
  const { create, update, remove, order } = useWorkflowFieldActions(workflowId);

  const [createOpen, setCreateOpen] = useState(false);
  const [editField, setEditField] = useState<WorkflowField | undefined>();

  const onRemove = onConfirmAction(async (id: string) => remove(id), {
    confirmTitle: "Remove workflow field",
    description: "Are you sure you want to remove this workflow field?"
  });

  return (
    <ContentCard title="Workflow Fields">
      <OrderableList
        data={fields}
        empty={
          <Stack justifyContent="center">
            <Title>No workflow fields</Title>
          </Stack>
        }
        onReorder={items => order(items.map(i => i.id))}
        renderRow={field => (
          <Card>
            <Stack>
              <DragHandle />
              <FieldTypeIcon type={field.type} />
              <Title variant="bold">{field.title}</Title>
              <Title>{field.key}</Title>
              <IconButton
                size="small"
                icon="pencil"
                onClick={() => setEditField(field)}
                dimmed
                style={{ marginLeft: "auto" }}
              />
              <IconButton icon="trash" size="small" onClick={() => onRemove(field.id)} dimmed />
            </Stack>
          </Card>
        )}
      />
      <div>
        <Button variant="blended" startIcon="plusSmall" onClick={() => setCreateOpen(true)}>
          Add workflow field
        </Button>
      </div>

      <UpsertModal
        open={createOpen || !!editField}
        entry={editField}
        template={{
          workflowId,
          type: "text"
        }}
        onCreate={data => create(data as WorkflowFieldCreate).then(() => setCreateOpen(false))}
        onUpdate={data => update(editField!.id, data).then(() => setEditField(undefined))}
        onClose={() => {
          setCreateOpen(false);
          setEditField(undefined);
        }}
        Form={WorkflowFieldForm}
        entryName="workflow field"
      />
    </ContentCard>
  );
};
