import { useSlackSenderApi } from "../../../../services/api/SlackSenderApi";
import { useMemo, useState } from "react";
import { Button, Layout, Modal, Option, Select } from "@introist/react-foundation/v2";
import { TextInput } from "../../../../components/atoms";
import { SelectorIcon } from "../../../employees/fields/EmployeeFieldSelector";
import { useCompanyData } from "../../../companydata/useCompanyData";
import { useWorkflowFields } from "../../hooks/useWorkflowFields";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";

export const SlackChannelSelect = ({
  senderId,
  value,
  onChanged,
  error,
  workflowId,
  employeeFieldsEnabled
}: {
  senderId: string;
  value: string;
  onChanged: (channel: string) => void;
  error?: boolean;
  workflowId?: string;
  employeeFieldsEnabled?: boolean;
}) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackChannels, isLoading } = slackSenderApi.listChannel(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const { entries } = useCompanyData();
  const { fields: workflowFields } = useWorkflowFields(workflowId ?? "", { enabled: !!workflowId });
  const { fields: employeeFields } = useEmployeeFields({}, { enabled: employeeFieldsEnabled });

  const [privateChannelModalOpen, setPrivateChannelModalOpen] = useState(false);
  const [privateChannel, setPrivateChannel] = useState("");

  const options = useMemo(() => {
    if (isLoading) return [];

    const options = [];

    options.push({
      key: "add",
      title: "Select private channel",
      startAdornmentIcon: "lock",
      onClick: (e: any) => {
        setPrivateChannelModalOpen(true);
        e.stopPropagation();
        e.preventDefault();
      }
    });

    for (const channel of slackChannels ?? []) {
      options.push({
        key: channel,
        title: channel,
        startAdornmentIcon: "hashtag"
      });
    }

    for (const field of (employeeFields ?? []).filter(f => f.type === "text")) {
      options.push({
        key: `{{${field.key}}}`,
        title: field.title,
        startAdornmentIcon: "description"
      });
    }

    for (const entry of entries) {
      if (entry.type === "text") {
        options.push({
          key: `{{companydata.${entry.id}}}`,
          title: entry.name,
          startAdornmentIcon: "office"
        });
      }
    }

    for (const field of workflowFields ?? []) {
      options.push({
        key: `{{${field.tag}}}`,
        title: field.title,
        startAdornmentIcon: "workflows"
      });
    }

    return options as Option[];
  }, [isLoading, slackChannels, entries, workflowFields, employeeFields]);

  return (
    <>
      <Select
        element="button"
        variant="blended"
        size="small"
        placeholder="Select channel"
        startAdornment={
          <SelectorIcon
            icon={(options ?? []).find(o => o.key === value)?.startAdornmentIcon ?? "hashtag"}
          />
        }
        options={options}
        value={value}
        attachToRef={false}
        searchable
        onSelect={opt => onChanged(opt.key)}
        error={error}
      />
      <Modal
        icon="lock"
        title="Select a private channel"
        description="Introist does not list private channels for privacy reasons. Type the exact name of the private channel the sender has access to."
        open={privateChannelModalOpen}
        onClose={() => setPrivateChannelModalOpen(false)}
        onCloseWithOutsideClick={() => setPrivateChannelModalOpen(false)}
        backdropElevation="jupiter"
        maxWidth={512}
      >
        <Layout.Group vertical lastChildToEnd gap="xxLarge" style={{ marginTop: "1.5rem" }}>
          <TextInput label="Private channel" onChange={setPrivateChannel} autoFocus />
          <Button
            disabled={!privateChannel}
            onClick={() => {
              onChanged(privateChannel);
              setPrivateChannelModalOpen(false);
            }}
          >
            Add private channel
          </Button>
        </Layout.Group>
      </Modal>
    </>
  );
};
