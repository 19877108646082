import { ReactNode, useMemo, useState } from "react";
import { CollapseList } from "../../../components/organisms";
import { ActionMenu, Stack, Title } from "@introist/react-foundation/v2";
import { FittedTitle } from "../../../components/atoms";
import { RuleEditorModal } from "../../../components/organisms/RuleEditorModal/RuleEditorModal";
import { Conditional } from "@introist/introist-commons";

export type ConditionEditorProps = {
  value: Conditional;
  onChange: (value: Conditional) => unknown;
  onRemove: () => unknown;
  renderValueEditor: (
    condition: Conditional,
    onChange: (newValue: Conditional) => unknown
  ) => ReactNode;
  defaultOpen?: boolean;
};

export const ConditionEditor = ({
  value,
  onChange,
  onRemove,
  renderValueEditor,
  defaultOpen
}: ConditionEditorProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const [ruleOpen, setRuleOpen] = useState(false);

  const conditionCount = useMemo(() => {
    const conditions = Object.values(value.condition)[0];
    if (Array.isArray(conditions)) return conditions.length;
    return null;
  }, [value.condition]);

  const conditionDescription = useMemo(() => {
    if (conditionCount === null || value.id === "default") return "";
    if (conditionCount === 0) return "No conditions";
    return `${conditionCount} condition${conditionCount > 1 ? "s" : ""}`;
  }, [conditionCount, value]);

  return (
    <CollapseList
      header={
        <Stack style={{ flex: 1 }}>
          <FittedTitle variant="bold" maxLength={35} showTooltip>
            {value.title ?? "Untitled option"}
          </FittedTitle>
          <Title style={{ marginLeft: "auto", whiteSpace: "nowrap" }}>{conditionDescription}</Title>
          {value.id !== "default" && (
            <ActionMenu
              attachToRef={false}
              options={[
                {
                  key: "edit",
                  title: "Edit rule",
                  onClick: () => setRuleOpen(true),
                  startAdornmentIcon: "pencil"
                },
                {
                  key: "delete",
                  title: "Remove",
                  onClick: () => Promise.resolve(onRemove()),
                  startAdornmentIcon: "trash"
                }
              ]}
              onOpen={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            />
          )}
        </Stack>
      }
      open={open}
      onToggleOpen={() => setOpen(!open)}
    >
      {renderValueEditor(value, onChange)}
      <RuleEditorModal
        title={value.title}
        open={ruleOpen}
        setOpen={setRuleOpen}
        expression={value.condition}
        onSave={(condition, title) =>
          onChange({ ...value, condition, title: title ? title : value.title })
        }
      />
    </CollapseList>
  );
};
