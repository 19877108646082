import { api, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useToast } from "@introist/react-foundation/v2";

export type DatasetEntry = RouterOutput["datasets"]["data"]["find"];

export const useDataset = (datasetId: string, options?: any) => {
  const { data: entries } = api.datasets.data.list.useQuery({ datasetId }, options);

  return {
    entries
  };
};

export const useDatasetEntry = (id: string, options?: any) => {
  const { data: entry } = api.datasets.data.find.useQuery({ id }, options);

  return {
    entry
  };
};

export const useDatasetEntryActions = () => {
  const toast = useToast();

  const createOp = api.datasets.data.create.useMutation();
  const updateOp = api.datasets.data.update.useMutation();
  const deleteOp = api.datasets.data.delete.useMutation();

  const apiUtils = api.useContext();

  const listOp = apiUtils.datasets.data.list;
  const findOp = apiUtils.datasets.data.find;

  const create = async (datasetId: string, data: any) => {
    return createOp
      .mutateAsync({ datasetId, data })
      .then(async created => {
        await listOp.refetch({ datasetId });
        return created;
      })
      .catch(e => {
        toast.error("Failed to save");
        throw e;
      });
  };

  const update = async (id: string, data: any) => {
    return updateOp
      .mutateAsync({ id, data })
      .then(async updated => {
        await listOp.refetch({ datasetId: updated.datasetId });
        await findOp.refetch({ id });
        toast.success("Changes saved");
        return updated;
      })
      .catch(e => {
        toast.error("Failed to save");
        throw e;
      });
  };

  const remove = async (datasetId: string, id: string) => {
    return deleteOp
      .mutateAsync({ id })
      .then(async () => {
        await listOp.refetch({ datasetId });
        toast.success("Deleted");
      })
      .catch(e => {
        toast.error("Failed to delete");
        throw e;
      });
  };

  return { create, update, remove };
};
