import { Icon, useTheme } from "@introist/react-foundation/v2";
import { FreshServiceIcon, SlackIcon, TrelloIcon, WebhookActionIcon } from "../../../assets";
import { WorkflowStepTypes } from "../stepTypes";

export const StepTypeIcon = ({ stepType }: { stepType: string }) => {
  const { theme } = useTheme();

  const wfStepType = WorkflowStepTypes.find(wfStepType => wfStepType.stepType === stepType);

  if (wfStepType && wfStepType.icon) return wfStepType.icon;

  switch (stepType) {
    case "meeting":
    case "individual-event":
      return <Icon color={theme.palette.success.default} name="calendarAdd" />;
    case "webhook":
      return <WebhookActionIcon />;
    case "slack":
      return <SlackIcon />;
    case "enroll":
      return <Icon name="trigger" color={theme.palette.primary.default} />;
    case "email":
      return <Icon name="email" color={theme.palette.primary.default} />;
    case "invite":
      return <Icon name="calendarCheck" color={theme.palette.success.default} />;
    case "freshservice":
    case "freshserviceonboarding":
      return <FreshServiceIcon />;
    case "trelloboard":
      return <TrelloIcon />;
    case "creategoogleaccount":
      return <Icon name="google" color={theme.palette.primary.default} />;
    case "creategoogle":
      return <Icon name="google" color={theme.palette.primary.default} />;
    case "emailform":
      return <Icon name="stepType" color={theme.palette.primary.default} />;
    case "emailtask":
      return <Icon name="checkRectangle" color={theme.palette.primary.default} />;
    case "slacktask":
      return <SlackIcon />;
    case "eventattend":
      return <Icon color={theme.palette.purple.default} name="events" />;
    case "slackquestion":
      return <SlackIcon />;
    case "employeeselect":
      return <Icon name="personSync" color={theme.palette.primary.default} />;
    case "workflowtrigger":
      return <Icon name="trigger" color={theme.palette.primary.default} />;
    case "createaccount":
      return <Icon name="personAdd" color={theme.palette.primary.default} />;
    case "employeeassign":
      return <Icon name="personSync" color={theme.palette.primary.default} />;
    default:
      return <Icon name="stepType" color={theme.palette.border.default} />;
  }
};
