import styled from "styled-components";
import { DataForm, Layout } from "@introist/react-foundation/v2";
import { ConditionalStepSetting } from "../../../../blocks/StepSettingsBlock/settings/ConditionalStepSetting";
import { useFeatureFlag } from "hooks";
import { PreconditionStepsEditor } from "../ScheduleBlock/components/PreconditionStepsEditor";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { RecordCompletionToAttributeSetting } from "./RecordCompletionToAttributeSetting";
import { PreconditionAttributesSetting } from "./PreconditionAttributesSetting";
import { useAutomation } from "../../../../../automations/hooks/useAutomations";

type AdvancedSettingsProps = {
  form: DataForm<any>;
  readOnly?: boolean;
  workflowId: string;
};

const AdvancedSettingsContainer = styled(Layout.Group)`
  padding: var(--spacing-xLarge);
  padding-top: 1rem;
`;

export const AdvancedSettings = ({ form, readOnly, workflowId }: AdvancedSettingsProps) => {
  const preconditionFlag = useFeatureFlag("step-preconditions");
  const { isEnabled: hasCompletionAttributeFlag } = useFeatureFlag(
    "record-completion-to-attribute"
  );

  return (
    <AdvancedSettingsContainer vertical>
      {preconditionFlag.isEnabled && (
        <PreconditionStepsEditor
          workflowId={workflowId}
          readOnly={readOnly}
          preconditionSteps={form.get("preconditionSteps") ?? []}
          stepId={form.get("id")}
          onChange={form.set("preconditionSteps")}
        />
      )}
      <PreconditionAttributesSetting
        onChange={form.set("preconditionAttributes")}
        value={form.get("preconditionAttributes") ?? []}
      />
      <ConditionalStepSetting
        readOnly={readOnly}
        condition={form.get("condition")}
        conditionMode={form.get("conditionMode")}
        onDelete={() => form.set("condition")(null)}
        onConditionChanged={(condition, conditionMode) =>
          form.setMany({ condition, conditionMode })
        }
        workflowId={workflowId}
      />
      {hasCompletionAttributeFlag && form.data.stepType === "slacktask" && (
        <RecordCompletionToAttributeSetting
          value={form.get("stepData.recordCompletionToAttribute")}
          onChange={form.set("stepData.recordCompletionToAttribute")}
        />
      )}
    </AdvancedSettingsContainer>
  );
};

type AdvancedJourneySettingsProps = {
  form: DataForm<JourneyStepV2>;
  readOnly?: boolean;
  workflowId: string;
};

export const AdvancedJourneySettings = ({
  form,
  readOnly,
  workflowId
}: AdvancedJourneySettingsProps) => {
  const preconditionFlag = useFeatureFlag("step-preconditions");
  const { isEnabled: hasConditionalStepFlag } = useFeatureFlag("conditional-step");
  const { isEnabled: hasCompletionAttributeFlag } = useFeatureFlag(
    "record-completion-to-attribute"
  );

  const { automation } = useAutomation({ id: form.get("journeyId") });

  if (!automation) return null;

  return (
    <AdvancedSettingsContainer vertical>
      {preconditionFlag.isEnabled && (
        <PreconditionStepsEditor
          workflowId={automation.workflowId}
          readOnly={readOnly}
          preconditionSteps={form.get("preconditionSteps") ?? []}
          stepId={form.get("workflowStepId")}
          onChange={form.set("preconditionSteps")}
        />
      )}
      <PreconditionAttributesSetting
        onChange={form.set("preconditionAttributes")}
        value={form.get("preconditionAttributes") ?? []}
        readOnly={true}
      />
      {hasConditionalStepFlag && (
        <ConditionalStepSetting
          readOnly
          condition={form.get("condition")}
          conditionMode={form.get("conditionMode")}
          onDelete={() => form.set("condition")(null)}
          onConditionChanged={(condition, conditionMode) => {
            form.setMany({ condition, conditionMode });
          }}
          workflowId={workflowId}
        />
      )}
      {hasCompletionAttributeFlag && form.data.stepType === "slacktask" && (
        <RecordCompletionToAttributeSetting
          value={form.get("previewData.recordCompletionToAttribute")}
          onChange={form.set("previewData.recordCompletionToAttribute")}
          readOnly={readOnly}
        />
      )}
    </AdvancedSettingsContainer>
  );
};
